import React, { useContext } from 'react'
import { Select } from 'antd'
import { ViewOrdersContext } from '../../ViewOrders.context'
import SelectPublicStatus from '../../../../common/SelectPublicStatus'
import SelectPrivateStatus from '../../../../common/SelectPrivateStatus'
const { Option } = Select

function StatusFilter() {
  const { filters, handleChangeFilter } = useContext(ViewOrdersContext)

  return (
    <div className="row mt-3">
      <div className="col-md-6">
        <div>
          <p className="mb-0">Public Status Code</p>
          <SelectPublicStatus
            value={filters.public_status}
            onChange={value => handleChangeFilter('public_status', value)} />
        </div>
        <div className="mt-2">
          <p className="mb-0">Private Status Code</p>
          <SelectPrivateStatus
            value={filters.private_status}
            onChange={value => handleChangeFilter('private_status', value)} />
        </div>
      </div>

      <div className="col-md-6">
        <div>
          <p className="mb-0">Is Processing</p>
          <Select style={{ width: '6rem' }}
            onChange={v => handleChangeFilter('is_processing', v)}
            value={filters.is_processing}>
            <Option value="both">Both</Option>
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        </div>
        <div className="mt-2">
          <p className="mb-0">Order Type</p>
          <Select style={{ width: '6rem' }}
            onChange={v => handleChangeFilter('order_type', v)}
            value={filters.order_type}>
            <Option value="both">Both</Option>
            <Option value="forward">Forward</Option>
            <Option value="returns">Returns</Option>
          </Select>
        </div>
      </div>
    </div>
  )
}

export default StatusFilter
