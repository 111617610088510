export const DEFAULT_PAGE_SIZE = 50;
export const PAGE_SIZE_OPTIONS = ["30", "50", "100", "500"];
export const DEFAULT_SELECTED_COLUMNS = [
  "tracking_no",
  "external_tracking_nos",
  "public_status",
  "private_status",
  "agent_application_name",
  "created_on",
  "scheduled_pickup_date",
  "forward_return",
  "partner_submission",
];

export const CANCELABLE_ORDER_STATUS = "ORDER_INFO_RECEIVED";

export const defaultSelectedColumns = [
  "tracking_no",
  "external_tracking_nos",
  "agent_application_name",
  "tracker_status_code",
  "private_tracker_status_code",
  "pickup_date",
  "forward_order_id",
  //'partner_submission_status',
  "created_on",
];
export const columnsOrdering = [
  "tracking_no",
  "shipper_order_id",
  "escalation_status",
  "external_tracking_nos",
  "agent_application_name",
  "tracker_status_code",
  "private_tracker_status_code",
  "order_type",
  "pickup_date",
  "forward_order_id",
  "upload_batch_no",
  "consignee_name",
  "consignee_email",
  "pickup_country",
  "consignee_country",
  "consignee_address",
  "consignee_number",
  "shipping_batch_nos",
  // "customs_documents_status",
  // 'partner_submission_status',
  "order_exceptions",
  "created_on",
  // "identification_document_name",
  // "consignee_identifcation_number",
];

export const MPS_ORDER_TYPES = ["MPS", "PACKAGE"];
