import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { MAWB_CLIENTS } from "./midMileAwb/constants";

const changeNavBarTitle = (additionalText = "") => {
  let title = "Janio Ops";
  const pathname = window.location.pathname;
  if (pathname === "/agent") {
    title = "Janio Ops";
  } else if (pathname.includes("/agent/user")) {
    title = "User";
  } else if (pathname.includes("/agent/change-password")) {
    title = "Change password";
  } else if (pathname.includes("/agent/agent-apps")) {
    title = "Agent apps";
  } else if (pathname.includes("/agent/add-agent-apps")) {
    title = "Add agent apps";
  } else if (pathname.includes("/agent/services")) {
    title = "Services";
  } else if (pathname.includes("/agent/network-partners/add")) {
    title = "Add Partner";
  } else if (pathname.includes("/agent/network-partners/")) {
    title = "Edit Partner";
  } else if (pathname.includes("/agent/network-partners")) {
    title = "Network Partners";
  } else if (pathname.includes("/agent/change-tracker-status-csv")) {
    title = "Change tracker status";
  } else if (pathname.includes("/agent/view-orders")) {
    title = "View orders";
  } else if (pathname.includes("/agent/view-awb")) {
    // title = this.props?.mawb_no ? "Airway Bill No. " + this.props?.mawb_no : "";
    title = "Airway Bill No. ";
  } else if (pathname.includes("/agent/view-order-details")) {
    title = "View order details";
  } else if (pathname.includes("/agent/view-tracker-updates")) {
    title = "View tracker updates";
  } else if (pathname.includes("/agent/view-tracker-update-details")) {
    title = "View tracker update details";
  } else if (pathname.includes("/agent/create-tracker-update")) {
    title = "Create tracker update";
  } else if (pathname.includes("/agent/view-note-details")) {
    title = "View note details";
  } else if (pathname.includes("/agent/view-notes")) {
    title = "View notes";
  } else if (pathname.includes("/agent/create-single-note")) {
    title = "Create single note";
  } else if (pathname.includes("/agent/create-notes-csv")) {
    title = "Create notes by csv";
  }
  // else if (pathname.includes("/agent/view-bill-details")) {
  //   title = "View order bill details";
  // } else if (pathname.includes("/agent/view-bills")) {
  //   title = "View order bills";
  // }
  else if (pathname.includes("/agent/create-bills")) {
    title = "Create order bills";
  } else if (pathname.includes("/agent/view-bill-status-details")) {
    title = "View order billing status details";
  } else if (pathname.includes("/agent/view-bill-status")) {
    title = "View order billing statuses";
  } else if (pathname.includes("/agent/view-bill-record-details")) {
    title = "View order billing record details";
  } else if (pathname.includes("/agent/view-bill-record")) {
    title = "View order billing record";
  } else if (pathname.includes("/agent/delete-order-by-upload-batch-no")) {
    title = "Delete order by upload batch no";
  } else if (pathname.includes("/agent/reports")) {
    title = "Reports";
  } else if (pathname.includes("/agent/notes-report")) {
    title = "Notes report";
  } else if (pathname.includes("/agent/scan-to-print")) {
    title = "Scan to print";
  } else if (pathname.includes("/agent/pricing")) {
    title = "Pricing";
  } else if (pathname.includes("/agent/weight-reconciliation")) {
    title = "Change Weight by CSV";
  } else if (pathname.includes("/agent/upload-adhoc-csv")) {
    title = "Upload adhoc csv";
  } else if (pathname.includes("/agent/view-pricing-details")) {
    title = "View pricing details";
  } else if (pathname.includes("/agent/upload-pricing-csv")) {
    title = "Upload pricing csv";
  } else if (pathname.includes("/agent/create-custom-batch")) {
    title = "Create Shipping Batch";
  } else if (pathname.includes("/agent/request-consignee-documents")) {
    title = "Request Consignee for Documents";
  }
  // else if (pathname.includes("/agent/merchant-dues")) {
  //   title = "Merchant Dues";
  // }
  else if (pathname.includes("/agent/merchant-remittance")) {
    title = "Remittances made to Merchants";
  }
  // else if (pathname.includes("/agent/partner-dues")) {
  //   title = "Partner Dues";
  // }
  else if (pathname.includes("/agent/partner-remittance")) {
    title = "Remittances received from Partners";
  } else if (pathname.includes("/agent/correct-order-address")) {
    title = "Change Order Address by CSV";
    // } else if (pathname.includes('/agent/view-mawb')) {
    //   title = 'View Master Airway Bill Tracker'
    // } else if (pathname.includes('/agent/create-mawb')) {
    //   title = 'Create Master Airway Bill Tracker'
  } else if (pathname.includes("/agent/download-pods")) {
    title = "Download/Send Proofs of Delivery (PODs)";
  } else if (pathname.includes("/agent/view-missed-slas-last-mile")) {
    title = "Missed SLAs for Last Mile Partners";
  } else if (pathname.includes("/agent/create-np-delay-reason")) {
    title = "Create NP Delay Reason";
  } else if (pathname.includes("/agent/submit-return-orders")) {
    title = "Submit Return Orders";
  } else if (pathname.includes("/agent/submit-return-order")) {
    title = "Submit Return Order (New)";
  } else if (pathname.includes("/agent/partner-delays")) {
    title = "Partner Delays";
  } else if (pathname.includes("/agent/manage-awb")) {
    title = "Mid-mile Tracker";
  } else if (pathname.includes("/agent/create-awb")) {
    title = "Create Airway Bill";
  } else if (pathname.includes("/agent/rts-pickup-capacity")) {
    title = "RTS Pickup Capacity Management";
  } else if (
    pathname.includes("/agent/lsp-users") ||
    pathname.includes("/agent/create-lsp-user") ||
    pathname.includes("/agent/edit-lsp-user")
  ) {
    title = "LSP User Creation";
  } else if (pathname.includes("/agent/network-editor/view-sla-journey")) {
    title = "View SLA Details";
  } else if (pathname.includes("/agent/network-editor/edit-sla-journey")) {
    title = "Edit Service Level Agreement (SLA) Journey";
  } else if (pathname.includes("/agent/network-editor/create-sla-journey")) {
    title = "Create SLA";
  } else if (pathname.includes("/agent/network-editor")) {
    title = "List of Service Level Agreement (SLA) Journeys";
  } else if (pathname.includes("/agent/shopee-cartons")) {
    title = "Shopee Cartons";
  }
  // else if (pathname.includes("/agent/manage-exceptions/resolve/")) {
  // title = "Resolve Exception";
  // } else if (pathname.includes("/agent/manage-exceptions/")) {
  // title = "Resolve Exception";
  // }
  return `${title}${additionalText}`;
};

export default function NavbarTitle({ handleDrawerToggle }) {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const mawbClient = urlParams.get("mawb_client");
  const midmileAwb = useSelector((state) => state.midmileAwb);
  const mawb_no = midmileAwb?.awbDetails?.mawb_no;

  // title = this.props?.mawb_no ? "Airway Bill No. " + this.props?.mawb_no : "";
  let navTitle = "";
  if (mawbClient === MAWB_CLIENTS.shopee_china) {
    navTitle = " for Shopee China Orders";
  } else if (mawbClient === MAWB_CLIENTS.shopee_asia) {
    navTitle = " for Shopee Asia Orders"
  } else if (mawb_no) {
    navTitle = mawb_no;
  }

  const navbarTitle = changeNavBarTitle(navTitle);

  if (window.innerWidth > 300 && window.innerWidth < 1000) {
    return (
      <div id="navBarTitle" className="mr-auto">
        <span className="h5">{navbarTitle}</span>
      </div>
    );
  } else if (window.innerWidth > 1000) {
    return (
      <div id="navBarTitle" className="mr-auto">
        <span className="h5">
          <MenuIcon className="mr-3 pointer" onClick={handleDrawerToggle} />
          {navbarTitle}
        </span>
      </div>
    );
  }
  return null;
}
