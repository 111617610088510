import React, { useContext } from "react";
import { Table } from "antd";
import { ViewOrdersContext } from "../ViewOrders.context";
import { getColumns } from "./Columns";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../constants";
import Modals from "./Modals";

const ViewOrdersTable = ({ ordersResponse, ordersFetching }) => {
  const { filters, handleChangeFilter, setSelectedOrders, selectedColumns, onSearchHandler } = useContext(ViewOrdersContext);
  return (
    <ViewOrdersTableInner
      filters={filters}
      handleChangeFilter={handleChangeFilter}
      setSelectedOrders={setSelectedOrders}
      selectedColumns={selectedColumns}
      ordersFetching={ordersFetching}
      ordersResponse={ordersResponse}
      onSearchHandler={onSearchHandler}
    />
  );
};

const ViewOrdersTableInner = React.memo(({ filters, handleChangeFilter, setSelectedOrders, selectedColumns, ordersFetching, ordersResponse, onSearchHandler }) => {
  console.log(ordersResponse, filters);

  const onPaginationChange = (page, pageSize) => {
    handleChangeFilter("page", page);
    onSearchHandler();
  };
  const onShowSizeChange = (currentPage, size) => {
    handleChangeFilter("page_size", size);
    onSearchHandler();
  };

  const locale = {
    emptyText:
      filters.date_from || filters.date_to || filters.pickup_date_from || filters.pickup_date_to ? "No Data found for applied date range" : "No Data found for applied filters",
  };

  return (
    <div className="mt-5">
      <Modals />
      <Table
        locale={locale}
        style={{ width: "100%" }}
        size="small"
        rowKey="order_id"
        scroll={{ x: "max-content" }}
        pagination={{
          current: parseInt(filters.page),
          pageSize: parseInt(filters.page_size) || DEFAULT_PAGE_SIZE,
          total: ordersResponse.count,
          showTotal: (total) => `${total} orders`,
          style: { marginBottom: 0 },
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          size: "small",
          showSizeChanger: true,
          showLessItems: true,
          onChange: onPaginationChange,
          position: "both",
          onShowSizeChange,
        }}
        rowSelection={{
          onChange: (selectedKeys, selectedOrders) => {
            setSelectedOrders(selectedOrders);
          },
        }}
        dataSource={ordersResponse.results}
        loading={ordersFetching}
        columns={getColumns(selectedColumns)}
      />
    </div>
  );
});
export default ViewOrdersTable;
