import axios from 'axios'


export const createFormData = (params, file) => {
  const formData = new FormData();
  formData.append("api_key", "243466314222934");
  formData.append("file", file);
  for (let key in params) {
    formData.append(key, params[key]);
  }
  return formData;
};

export const documentTypeLabels = {
  personal_identification_front: {
    label: "Front Card",
  },
  personal_identification_back: {
    label: "Back Card",
  },
  website_link: {
    label: "Product URL",
    help: 'E.g. https://www.adidas.com.sg/vrct-jacket/EA0372.html'
  },
  commercial_invoice: {
    label: "Proof of Purchase",
    help: 'E.g. Purchase invoice or shopping receipt'
  },
  personal_use_form: {
    label: "Personal Use Form",
  },
}

export const axiosInstance = axios.create()

export const validateUrl = url => {
  if (!url) {
    return false
  }
  return /(?:(?:((?:https?|ftp):)?\/\/)?)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
}

export const bulkDownloadDocuments = async tracking_nos => {
  try {
    const url = '/api/attachments/customs-documents-bulk-cloudinary-url/'
    const response = await axios.post(url, {
      tracking_nos
    })
    const responseUrl = response.data.url
    window.open(responseUrl, "_blank")
    return {
      url: responseUrl,
      isError: false
    }
  } catch(err) {
    return {
      isError: true
    }
  }
}