import {
  MAWB_FETCHED,
  MAWB_FETCHED_ERROR,
  MAWB_FETCH_LOADING,
  MAWB_CREATED,
  MAWB_CREATE_LOADING,
  MAWB_CREATE_ERROR,
  MAWB_CREATE_RESET,
  MAWB_SELECT_ALL,
  MAWB_SELECT_TOGGLE,
  MAWB_FILE_FETCHED,
  MAWB_FILE_LOADING,
  MAWB_FILE_RESET
}  from '../../actions/types'

export default function(state = { create: {}, mawbs: {} }, action) {
  switch (action.type) {
    case MAWB_FILE_RESET:
      state.mawbs.allSelected = false;
      state.mawbs.actionable = false;
      state.mawbs.report = false;
      state.mawbs.raw.results = state.mawbs.raw.results.map(m => {
        m.isSelected = false;
        return m;
      });
      return{ ...state };

    case MAWB_FILE_LOADING:
      state.mawbs.actionable = false;
      return { ...state }

    case MAWB_FILE_FETCHED:
      state.mawbs = {
        ...state.mawbs,
        report: action.payload
      };
      return { ...state }

    case MAWB_SELECT_ALL:
      state.mawbs.allSelected = !state.mawbs.allSelected;
      state.mawbs.actionable = state.mawbs.allSelected;
      state.mawbs.raw.results = state.mawbs.raw.results.map(m => {
        m.isSelected = state.mawbs.allSelected;
        return m;
      });
      return {...state }

    case MAWB_SELECT_TOGGLE:
        const newArr = [...state.mawbs.raw.results];
        newArr[action.payload].isSelected = !newArr[action.payload].isSelected;
        state.mawbs.actionable = false;
        newArr.forEach(r => {
          if (r.isSelected) {
            state.mawbs.actionable = true;
          }
        });
        state.mawbs.raw.results = newArr;
        state.mawbs.allSelected = false;
        return { ...state }

    case MAWB_FETCHED:
      state.mawbs.allSelected = false;
      return {
        ...state,
        mawbs: { ...state.mawbs, raw: action.payload, loading: false, error: false }
      };

    case MAWB_FETCH_LOADING:
      return {
        ...state,
        mawbs: { ...state.mawbs, raw: false, loading: true, error: false }
      }

    case MAWB_FETCHED_ERROR:
      return {
        ...state,
        mawbs: {...state.mawbs, raw: false, loading: false, error: action.payload }
      }

    // create
    case MAWB_CREATE_RESET:
      return {
        ...state,
        create: { loading: false, success: false, error: false }
      }
    case MAWB_CREATE_LOADING:
      return {
        ...state,
        create: { loading: true, success: false, error: false }
      }
    case MAWB_CREATED:
      return {
        ...state,
        create: { loading: false, success: action.payload, error: false }
      }
    case MAWB_CREATE_ERROR:
      return {
        ...state,
        create: { loading: false, success: false, error: action.payload }
      }
    default:
      return state;
  }
}