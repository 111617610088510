import {
  MISSED_SLAS_LOADING,
  MISSED_SLAS_ERROR,
  MISSED_SLAS_SUCCESS,
  MISSED_SLAS_SELECT_TOGGLE,
  MISSED_SLAS_SELECT_ALL,
  MISSED_SLAS_VIEW_RESET_SELECTION,
  MISSED_SLAS_VIEW_FILE_SUCCESS
} from '../../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case MISSED_SLAS_LOADING:
      return {
        ...state, 
        loading: true, 
      } 
    case MISSED_SLAS_ERROR:
      return {
        ...state, 
        loading: false, 
        error: action.payload, 
        slas: false 
      }
    case MISSED_SLAS_SUCCESS:
      return {
        ...state, 
        loading: false, 
        error: false, 
        slas: action.payload 
      }
    case MISSED_SLAS_SELECT_ALL:
      state.slas.allSelected = !state.slas.allSelected;
      state.slas.actionable = state.slas.allSelected;
      state.slas.results = state.slas.results.map((m) => {
        m.isSelected = state.slas.allSelected;
          return m;
      });
      return { 
        ...state,
      }
    case MISSED_SLAS_SELECT_TOGGLE:
      const newArr = [...state.slas.results];
      newArr[action.payload].isSelected = !newArr[action.payload].isSelected;

      state.slas.actionable = false;
      newArr.forEach((r) => {
        if (r.isSelected) {
          state.slas.actionable = true;
        }
      });

      state.slas.results = newArr;
      state.slas.allSelected = false;
      return {
        ...state,
      };
    case MISSED_SLAS_VIEW_RESET_SELECTION:
      const results = [...state.slas.results].map((r) => {
        r.isSelected = false;
        return r;
      });

      state.slas.results = results;
      state.slas.allSelected = false;
      return {
        ...state,
      };

    case MISSED_SLAS_VIEW_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: {
          ...state.slas,
          file: action.payload,
        },
      };
    default:
      return state;
  }
}