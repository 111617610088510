import axios from 'axios'

export const OrderEscalationAPI = {
  getEscalationStatuses: async () => {
    const response = await axios.get("/api/data/escalation-statuses/")
    return response.data
  },
  escalateOrders: async ({
    order_ids, reason, escalation_type
  }) => {
    const url = "/api/order/escalate-exceptions/"
    const data = {
      order_ids,
      escalation_type,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
  fetchEscalationTypes: async () => {
    try {
      const url = '/api/data/escalation-types/'
      const { data } = await axios.get(url)
      return {
        isError: false,
        data
      }
    } catch(err) {
      const data = err.response?.data
      return {
        isError: true,
        error: data
      }
    }
  }
}
