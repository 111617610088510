export const SelectFields = ["origin_port", "destination_port"];
export const DateFields = [
  "date_from", "date_to",
  "latest_ETD_from", "latest_ETD_to",
  "latest_ETA_from", "latest_ETA_to"
];
export const AWB_TYPES = [
  {
    label: 'Direct Airway Bill (DAWB)',
    value: 'DAWB'
  },
  {
    label: 'Master Airway Bill (MAWB)',
    value: 'MAWB'
  }
];
export const FREIGHT_ARRANGE_BY = [
  {
    label: 'Janio',
    value: 'Janio'
  },
  {
    label: 'Client',
    value: 'Client'
  }
];
export const HAWB_TYPES = [
  {
    label: 'Pallet',
    value: 'Pallet'
  },
  {
    label: 'Roll',
    value: 'Roll'
  },
  {
    label: 'Box',
    value: 'Box'
  },
  {
    label: 'Package',
    value: 'Package'
  },
  {
    label: 'Drum',
    value: 'Drum'
  },
  {
    label: 'Carton',
    value: 'Carton'
  },
  {
    label: 'Bag',
    value: 'Bag'
  },
  {
    label: 'Bale',
    value: 'Bale'
  },
  {
    label: 'Barrel',
    value: 'Barrel'
  },
  {
    label: 'Crate',
    value: 'Crate'
  },
  {
    label: 'Bundle',
    value: 'Bundle'
  }
];
export const MAWB_STATUS = [
  {
    label: 'Order Received By Airline Terminal',
    value: 'ORDER_RECEIVED_BY_AIRLINE_TERMINAL'
  },
  {
    label: 'Freight Processing at Freight Forwarder',
    value: 'FREIGHT_PROCESSING_AT_FREIGHT_FORWARDER'
  },
  {
    label: 'Freight Uplift Completed',
    value: 'FREIGHT_UPLIFT_COMPLETE'
  },
  {
    label: 'Freight Partially Offloaded',
    value: 'FREIGHT_PARTIAL_OFFLOAD'
  },
  {
    label: 'Freight Completely Offloaded',
    value: 'FREIGHT_FULL_OFFLOAD'
  },
  {
    label: 'Flight Departed',
    value: 'FLIGHT_DEPARTED'
  },
  {
    label: 'Flight Arrived',
    value: 'FLIGHT_ARRIVED'
  },
  {
    label: 'Flight Rescheduled',
    value: 'FLIGHT_RESCHEDULED'
  },
  {
    label: 'Flight Cancelled',
    value: 'FLIGHT_CANCELLED'
  },
  {
    label: 'Freight Pendings Survey At Airline Terminal',
    value: 'FREIGHT_PENDING_SURVEY_AT_AIRLINE_TERMINAL'
  },
  {
    label: 'Freight Arrived Partially',
    value: 'FREIGHT_ARRIVED_PARTIAL'
  },
  {
    label: 'Freight Arrived Completely',
    value: 'FREIGHT_ARRIVED_COMPLETE'
  },
  {
    label: 'Processing At Gateway Hub',
    value: 'PROCESSING_AT_GATEWAY_HUB'
  },
  {
    label: 'Inbound In Transit To Customs Clearance',
    value: 'INBOUND_IN_TRANSIT_TO_CUSTOMS_CLEARANCE'
  },
  {
    label: 'Order Processing at Customs',
    value: 'ORDER_PROCESSING_AT_CUSTOMS'
  },
  {
    label: 'Customs Cleared',
    value: 'CUSTOMS_CLEARED'
  },
  {
    label: 'Departed from Customs',
    value: 'PARCEL_DEPARTED_FROM_CUSTOMS_CLEARANCE'
  },
  {
    label: 'Order Delivered',
    value: 'ORDER_DELIVERED'
  }  ,
  {
    label: 'Freight Unlocated',
    value: 'FREIGHT_UNLOCATED'
  }
]
export const MAWB_STATUS_MAPPING = { 
  "FLIGHT_SCHEDULED": "The parcel has been scheduled for a flight.",
  "FLIGHT_CANCELLED": "The parcel is awaiting a flight to be rescheduled due to a flight cancellation.",
  "FREIGHT_FULL_OFFLOAD": "The parcel has been rescheduled for a subsequent flight.",
  "FREIGHT_PARTIAL_OFFLOAD": "The parcel may have been rescheduled for a subsequent flight.",
  "ORDER_RECEIVED_BY_AIRLINE_TERMINAL": "The parcel order has been received by the airline.",
  "FREIGHT_UPLIFT_COMPLETE": "The parcel has been uplifted.",
  "FLIGHT_RESCHEDULED": "The parcel has been rescheduled for a subsequent flight.",
  "FREIGHT_PENDING_SURVEY_AT_AIRLINE_TERMINAL": "The parcel is currently on hold. Please contact our Customer Support if you require assistance.",
  "FREIGHT_ARRIVED_COMPLETE": "The parcel has arrived in the destination country.",
  "FREIGHT_ARRIVED_PARTIAL": "The parcel has arrived in the destination country.",
  "PROCESSING_AT_GATEWAY_HUB": "The parcel is currently being processed at a warehouse.",
  "INBOUND_IN_TRANSIT_TO_CUSTOMS_CLEARANCE": "The parcel is about to depart a warehouse for customs clearance.",
  "FREIGHT_UNLOCATED": "Unable to locate parcel. Please contact our Customer Support if you require assistance.",
  "FREIGHT_PROCESSING_AT_FREIGHT_FORWARDER": "The shipment has been handed over to a freight forwarder.",
  "ORDER_DELIVERED": "The order has been delivered.",
  "FLIGHT_DEPARTED": "The parcel has departed on a flight.",
  "FLIGHT_ARRIVED": "The parcel has arrived on a flight.",
  "ORDER_PROCESSING_AT_CUSTOMS": "The parcel is being processed at the Customs.",
  "CUSTOMS_CLEARED": "The parcel has cleared Customs.",
  "PARCEL_DEPARTED_FROM_CUSTOMS_CLEARANCE": "The parcel has been released from Customs.",
}
export const HAWB_STATUS = [
  {
    label: 'Processing At Gateway Hub',
    value: 'PROCESSING_AT_GATEWAY_HUB'
  },
  {
    label: 'Inbound In Transit To Customs Clearance',
    value: 'INBOUND_IN_TRANSIT_TO_CUSTOMS_CLEARANCE'
  }
]
export const HAWB_STATUS_MAPPING = {
  'PROCESSING_AT_GATEWAY_HUB': 'Processing At Gateway Hub',
  'INBOUND_IN_TRANSIT_TO_CUSTOMS_CLEARANCE': 'Inbound In Transit To Customs Clearance',
}
export const MAWB_LEVELS = [
  {
    label: 'MAWB',
    value: 'MAWB'
  },
  {
    label: 'HAWB',
    value: 'HAWB'
  }
];
export const SERVICE_LEVELS = [
  {
    label: 'General Cargo',
    value: 'General Cargo'
  },
  {
    label: 'Express',
    value: 'Express'
  },
  {
    label: 'Dangerous goods',
    value: 'Dangerous goods'
  },
  {
    label: 'ID 8000',
    value: 'ID 8000'
  },
];
export const RESCHEDULE_DATE_OPTIONAL_STATUSES = [
  "ORDER_RECEIVED_BY_AIRLINE_TERMINAL",
  "FREIGHT_PROCESSING_AT_FREIGHT_FORWARDER",
  "FREIGHT_UPLIFT_COMPLETE",
  "FREIGHT_PENDING_SURVEY_AT_AIRLINE_TERMINAL",
  "FREIGHT_ARRIVED_COMPLETE",
  "PROCESSING_AT_GATEWAY_HUB",
  "INBOUND_IN_TRANSIT_TO_CUSTOMS_CLEARANCE",
  "FREIGHT_UNLOCATED",
];
export const ALLOW_TRACKING_NO_FOR_STATUS = [
  "ORDER_RECEIVED_BY_AIRLINE_TERMINAL",
  "FREIGHT_PARTIAL_OFFLOAD",
  "FREIGHT_FULL_OFFLOAD",
  "FLIGHT_RESCHEDULED",
  "FLIGHT_CANCELLED"
];
export const FEE_NAMES = [
  'Freight Rate',
  'Fuel Surcharge',
  'Security Surcharge',
  'AWB Fee',
  'X-Ray/Screening Fee',
  'Terminal Transfer Fee',
  'Weight Verification Fee',
  'Airline DG Fee',
  'DG Fee Handling',
  'DG Labeling',
  'Strapping Fee',
  'Export Customs Declaration',
  'Import Customs Declaration',
  'Terminal Clearance Fee',
  'Origin Storage',
  'Destination Storage',
  'Survey Fee',
  'Attendance to Survey',
  'First Mile',
  'Last Mile',
  'Transportation',
  'Packing Fee',
  'GST Outlay',
  'Duty Tax Outlay',
  'Others'
];

export const FEE_TYPES = ['Flat fee', 'Per Unit'];

export const INVOICE_CURRENCIES = [
  'IDR',
  'JPY',
  'MYR',
  'SGD',
  'USD',
  'AUD',
  'CNY',
  'PHP',
  'GBP',
  'KRW',
  'TWD',
  'HKD',
  'THB'
];
export const COST_TYPES = [
  {
    label: 'Actual',
    value: 'actual'
  },
  {
    label: 'Accrual',
    value: 'accrual'
  },
]
export const COST_TYPES_MAPPING = {
  'actual': 'Actual',
  'accrual': 'Accrual',
}

export const DateTimeFormat = {
  Date: 'DD/MM/YY',
  Date2: 'DD MMM YYYY',
  Time: 'HH:mm',
  DateTime: 'DD/MM/YY HH:mm',
  DateTime2: 'DD MMM YYYY hh:mm A',
  APIDate: 'YYYY-MM-DD',
}

export const COST_DETAILS_COLUMS = [
  {
    title: 'Cost type',
    dataIndex: 'cost_type',
    key: 'cost_type'
  },
  {
    title: 'N/P name',
    dataIndex: 'network_partner_name',
    key: 'network_partner_name'
  },
  {
    title: 'Invoice no.',
    dataIndex: 'invoice_no',
    key: 'invoice_no'
  },
  {
    title: 'Fee name',
    dataIndex: 'fee_name',
    key: 'fee_name'
  },
  {
    title: 'Billing description',
    dataIndex: 'billing_description',
    key: 'billing_description',
  },
  {
    title: 'Tracking Number',
    dataIndex: 'tracking_no',
    key: 'tracking_no',
  },
  {
    title: 'Fee type',
    dataIndex: 'fee_type',
    key: 'fee_type'
  },
  {
    title: 'No. of units',
    dataIndex: 'no_of_units',
    key: 'no_of_units'
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: 'Unit price',
    dataIndex: 'unit_price',
    key: 'unit_price'
  },
  {
    title: 'Total',
    dataIndex: 'total_price',
    key: 'total_price'
  }
];

export const RESCHEDULED_STATUSES_ON = [
  'FREIGHT_PARTIAL_OFFLOAD',
  'FLIGHT_RESCHEDULED',
  'FREIGHT_ARRIVED_PARTIAL'
]


export const MAWB_CLIENTS = {
  shopee_asia: "shopee_asia",
  shopee_china: "shopee_china"
}
