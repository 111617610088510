import axios from 'axios'

export async function cancelOrder(payload) {
  try {
    const url = '/api/order/cancel-orders/'
    const r = await axios.post(url, payload)
    return {
      isError: false,
      data: r.data
    }
  } catch(err) {
    const data = err.response?.data
    return {
      isError: true,
      error: data
    }
  }
}

export async function resubmitToExternalService(orders) {
  const order_ids = orders.map(order => ({
    order_id: order.order_id
  }))
  const payload = {
    order_ids
  }
  try {
    const url = '/api/order/submit-orders-external-service/'
    const r = await axios.post(url, payload)
    return {
      isError: false,
      data: r.data
    }
  } catch(err) {
    const data = err.response?.data
    return {
      isError: true,
      error: data
    }
  }
}

export const printOrdersLabel = async (payload, sorted=false) => {
  try {
    const url = '/api/label/merge-olu/'
    const r = await axios.post(url, payload, {
      responseType: 'arraybuffer',
      headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      }
    })
    const urlResult = window.URL.createObjectURL(new Blob([r.data], {type:"application/pdf"}))
    return {
      isSuccess: true,
      data: r.data,
      pdfUrl: urlResult
    }
  } catch (error) {
    const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
    const obj = JSON.parse(decodedString);
    const msg = 'Unable to generate labels, please check your tracking numbers and date format.'
    return {
      isSuccess: false,
      message: obj.message || msg
    }
  }
}

export const downloadManifset = (params) => {
  const url = '/api/hms/download-delivery-manifest/'
  axios.get(url, {params: params, responseType: 'blob'})
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return {
        isSuccess: true,
        url
      }
    }).catch(err => {
      return {
        isSuccess: false,
        error: err.response?.data
      }
    });
}