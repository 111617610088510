import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { CLOSE_ERROR_POPUP } from "../../actions/types";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function ErrorPopup(props) {
  console.log(props);
  const history = useHistory();

  const handleOkButton = (e) => {
    e.preventDefault();
    props.togglePopup();
    history.goBack();
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.togglePopup}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Hmm, we have encountered an error."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Redirecting you back to the previous page</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleOkButton(e)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.errorPopup.open,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePopup: () => {
      dispatch({ type: CLOSE_ERROR_POPUP });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopup);
