import React, { useState, useCallback, useMemo } from "react"
import { ResolveOrderExceptionAPI } from "./api"
import { RESOLUTION_TYPES, steps } from "./constants"

export const ResolveOrderExceptionContext = React.createContext()
const ResolveOrderExceptionContextProvider = ({ orderData, handleCloseModal, singleException, children }) => {
  const [currentStep, setCurrentStep] = useState(steps.chooseResolutionMethod)
  const [collectedData, setCollectedData] = useState(
    singleException
    ? {exceptions: [singleException.exception]}
    : {}
  )
  const [submitResponse, setSubmitResponse] = useState()
  const orderIds = useCallback(orderData.map(order => order.order_id), [orderData])

  const saveData = (data) => {
    setCollectedData({
      ...collectedData,
      ...data
    })
  }

  const orderDataExceptions = useMemo(() => {
    if (singleException) {
      return [singleException.exception]
    }
    let exceptions = []
    for (let order of orderData) {
      exceptions = [...exceptions, ...order.order_exceptions]
    }
    return [...new Set(exceptions)]
  }, [orderData, singleException])

  const submitData = async (reason=undefined) => {
    const resolutionTypeApi = {
      [RESOLUTION_TYPES.dispose]: {
        api: ResolveOrderExceptionAPI.disposeException,
      },
      [RESOLUTION_TYPES.rtv]: {
        api: ResolveOrderExceptionAPI.rtvException,
      },
      [RESOLUTION_TYPES.recollectExistingTN]: {
        api: ResolveOrderExceptionAPI.recollectExistingTN,
      },
      [RESOLUTION_TYPES.cancellation]: {
        api: ResolveOrderExceptionAPI.cancelRTS,
      },
      [RESOLUTION_TYPES.others]: {
        api: ResolveOrderExceptionAPI.resolveException,
        getParams: () => {
          return {
            order_ids: orderIds,
            exceptions: collectedData.exceptions,
            reason,
          }
        }
      },
      [RESOLUTION_TYPES.redeliver]: {
        api: ResolveOrderExceptionAPI.redeliver,
        getParams: () => {
          let redeliverExceptions
          if (orderData.length === 1) {
            redeliverExceptions = collectedData.redeliver_exceptions
          } else { // bulk redeliver
            redeliverExceptions = orderData.map(order => {
              return {
                order_id: order.order_id,
                consignee_name: order.consignee_name,
                consignee_address: order.consignee_address,
                consignee_postal: order.consignee_postal,
                consignee_state: order.consignee_state,
                consignee_city: order.consignee_city,
                consignee_number: order.consignee_number,
                consignee_email: order.consignee_email,
                payment_type: order.payment_type,
                cod_amt_to_collect: order.cod_amt_to_collect,
              }
            })
          }
          return {
            reason,
            redeliver_exceptions: redeliverExceptions
          }
        }
      },
      [RESOLUTION_TYPES.recollectNewTN]: {
        api: ResolveOrderExceptionAPI.recollectNewTN,
        getParams: () => {
          let recollectionExceptions
          if (orderData.length === 1) {
            recollectionExceptions = collectedData.recollection_exceptions
          } else { // bulk redeliver
            recollectionExceptions = orderData.map(order => {
              return {
                order_id: order.order_id,
                pickup_contact_name: order.pickup_contact_name,
                pickup_address: order.pickup_address,
                pickup_postal: order.pickup_postal,
                pickup_state: order.pickup_state,
                pickup_city: order.pickup_city,
                pickup_contact_number: order.pickup_contact_number,
                pickup_contact_email: order.pickup_contact_email,
                payment_type: order.payment_type,
                cod_amt_to_collect: order.cod_amt_to_collect,
              }
            })
          }
          return {
            reason,
            recollection_exceptions: recollectionExceptions
          }
        }
      },
    }

    const defaultParams = {
      order_ids: orderIds,
      reason
    }

    let response
    const apiFunc = resolutionTypeApi[collectedData.resolutionType].api
    const getParams = resolutionTypeApi[collectedData.resolutionType].getParams
    const params = getParams ? getParams() : defaultParams
    response = await apiFunc(params)
    setSubmitResponse(response)
    return response
  }

  const getNextStep = useCallback((resolutionType) => {
    let nextStep
    if (resolutionType === RESOLUTION_TYPES.redeliver) {
      nextStep = steps.consigneeDetails
    } else if (resolutionType === RESOLUTION_TYPES.recollectNewTN) {
      nextStep = steps.pickupDetails
    } else if (resolutionType === RESOLUTION_TYPES.others) {
      nextStep = steps.chooseExceptions
      if (singleException) {
        nextStep = steps.leaveNote
      }
    } else {
      nextStep = steps.leaveNote
    }
    return nextStep
  }, [singleException])

  const value = {
    orderIds,
    orderData, handleCloseModal,
    currentStep, setCurrentStep,
    collectedData, setCollectedData,
    saveData, submitData,
    getNextStep,
    orderDataExceptions,
    submitResponse,
    singleException
  }
  return (
    <ResolveOrderExceptionContext.Provider value={value}>
      {children}
    </ResolveOrderExceptionContext.Provider>
  )
}

export default ResolveOrderExceptionContextProvider
