import {
  GET_ORDER_PRICING_PARAMS,
  GET_ORDER_PRICING,
  ADD_ORDER_PRICING,
  GET_ORDER_PRICING_DETAILS,
  CLEAR_PRICING_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch(action.type) {
    case GET_ORDER_PRICING_PARAMS:
      return { ...state, orderPricingParams: action.payload };
    case GET_ORDER_PRICING:
      return {
        ...state,
        orderPricing: action.payload.results,
        totalPricing: action.payload.count,
        totalPages: Math.ceil(action.payload.count/50),
        lastUpdated: moment()
      };
    case ADD_ORDER_PRICING:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        lastUpdated: moment()
      };
    case GET_ORDER_PRICING_DETAILS:
      return { ...state, orderPricingDetails: action.payload };
    case CLEAR_PRICING_ERRORS:
      return { ...state, error: null, errorData: null };
    default:
      return state;
  }
}
