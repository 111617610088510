import React, { useContext } from "react"
import { Button } from "antd"
// import { useFormik } from "formik"
// import { useQuery } from "react-query"
// import * as yup from "yup"

// import { ResolveOrderExceptionAPI } from "../api"
import { OrderEscalationContext } from "../OrderEscalation.context"

// const { Option } = Select

const UnableEscalate = () => {
  const {
    handleCloseModal,
  } = useContext(OrderEscalationContext)

  return (
    <div>
      <h6 className="font-weight bold">Unable to escalate the orders</h6>
      <div className="mt-4">
        <p>
        The selected orders don't have exception types.
        </p>
      </div>
      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()}>Close</Button>
        </div>
      </div>
    </div>
  )
}

export default UnableEscalate