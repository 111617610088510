import React from 'react'
import { Select } from 'antd'
import { capitalizeUnderscore } from '../../utils'
import usePrivateStatuses from '../../hooks/usePrivateStatuses'
const { Option, OptGroup } = Select

const SelectPrivateStatus = React.memo((props) => {
  const [privateStatuses, isLoading] = usePrivateStatuses()

  const getPrivateStatusCodeOptions = () => {
    const {
      grouped_by_public_status_v2: groupedStatuses
    } = privateStatuses || {}

    const options = [
      <Option value="" key="private-status-all">All Statuses</Option>
    ]

    if (!!groupedStatuses && !!groupedStatuses.length) {
      groupedStatuses.forEach(obj => {
        const groupedByPublicStatus = (
          <OptGroup key={`public-status-group-${obj.public_status_v2}`} label={
            <div style={{ backgroundColor: "#E5E5E5" }} className="my-1 px-2">
              <p className="mb-0 mt-2"><strong>{obj.public_status_v2
                ? `Public Status: ${capitalizeUnderscore(obj.public_status_v2)}`
                : "Unmapped Status"}</strong>
              </p>
            </div>
          }>
            {obj.private_statuses.map(s => (
              <Option value={s} key={`private-status-v2-${obj.public_status_v2}-${s}`}>
                {s}
              </Option>
            ))}
          </OptGroup>
        )
        options.push(groupedByPublicStatus)
      })
    }

    return options
  }

  return (
    <Select
      id="statusCode"
      name="statusCode"
      style={{ width: '100%' }}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {getPrivateStatusCodeOptions()}
    </Select>
  )
})

export default SelectPrivateStatus
