import {
  LSP_USER_FETCH_LOADING,
  LSP_USER_FETCHED,
  LSP_USER_FETCHED_ERROR
} from '../../actions/types';

export default function (state = { lspUsers: [], lspUsersLoading: false, lspUserError: null }, action) {
  switch (action.type) {
    case LSP_USER_FETCHED:
      return {
        ...state,
        lspUsers: action.payload,
        lspUsersLoading: false,
        lspUserError: false
      };

    case LSP_USER_FETCH_LOADING:
      return {
        ...state,
        lspUsers: [],
        lspUsersLoading: true,
        lspUserError: false
      }
    case LSP_USER_FETCHED_ERROR:
      return {
        ...state,
        lspUsers: [],
        lspUsersLoading: false,
        lspUserError: action.payload
      }
    default:
      return state;
  }
}