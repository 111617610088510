import {
  EMAIL_CONSIGNEE,
  FETCH_CONSIGNEE_DOC_TYPES
} from '../../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case EMAIL_CONSIGNEE:
      return { ...state, result: action.payload, errorData: action.errorData};
    case FETCH_CONSIGNEE_DOC_TYPES:
      return { ...state, documentTypes: action.payload };
    default:
      return state;
  }
}
