// common types
export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const AUTH_ERROR = "auth_error";
export const FETCH_SIGNED_IN_USER = "fetch_signed_in_user";
export const EDIT_SIGNED_IN_USER = "edit_signed_in_user";
export const CHANGE_PASSWORD = "change_password";
export const RESET_PASSWORD = "reset_password";
export const PASSWORD_SENT = "password_sent";
export const CLEAR_AUTH_ERRORS = "clear_auth_errors";
export const FETCH_ALL_COUNTRIES = "fetch_all_countries";
export const FETCH_ALL_CURRENCIES = "fetch_all_currencies";
export const FETCH_AGENT_STATUSES = "fetch_agent_statuses";
export const FETCH_TRACKER_STATUSES = "fetch_tracker_statuses";
export const FETCH_PRIVATE_TRACKER_STATUSES = "fetch_private_tracker_statuses";
export const FETCH_FIRSTMILE_STATUSES = "fetch_firstmile_statuses";
export const FETCH_WAREHOUSE_STATUSES = "fetch_warehouse_statuses";
export const FETCH_USER_FEATURES = "fetch_user_features";

// agent types
export const ADD_AGENT_APPLICATIONS = "add_agent_applications";
export const EDIT_AGENT_APPLICATIONS_SECRET_KEY = "edit_agent_applications_secret_key";
export const EDIT_AGENT_APPLICATIONS = "edit_agent_applications";
export const FETCH_AGENT_APPLICATIONS = "fetch_agent_applications";
export const FETCH_AGENT_APPLICATION_BY_ID = "fetch_agent_application_by_id";
export const CLEAR_AGENT_APPLICATIONS_ERRORS = "clear_agent_applications_errors";
export const FETCH_AGENT_SERVICES = "fetch_agent_services";
export const FETCH_AGENT_SERVICE_BY_ID = "fetch_agent_service_by_id";
export const EDIT_AGENT_SERVICE = "edit_agent_service";
export const CHANGE_AGENT_ORDERS_STATUSES = "change_agent_orders_statuses";
export const CHANGE_TRACKER_STATUSES = "change_tracker_statuses";
export const CLEAR_CHANGE_TRACKER_STATUSES_ACTION_ERROR = "change_tracker_statuses_action_error";
export const ORDERS_ACTION_ERROR = "view_orders_action_error";
export const CLEAR_ORDERS_ACTION_ERROR = "clear_orders_action_error";
export const FETCH_TRACKER_UPDATE = "fetch_tracker_update";
export const FETCH_TRACKER_UPDATE_BY_IDS = "fetch_tracker_update_by_ids";
export const FETCH_TRACKER_UPDATE_BY_FILTER = "fetch_tracker_update_by_filter";
export const FETCH_TRACKER_UPDATE_BY_ID = "fetch_tracker_update_by_id";
export const CREATE_TRACKER_UPDATE = "create_tracker_update";
export const EDIT_TRACKER_UPDATE = "edit_tracker_update";
export const DELETE_TRACKER_UPDATE_BY_ID = "delete_tracker_update_by_id";
export const DELETE_MULTIPLE_TRACKER_UPDATE = "delete_multiple_tracker_update";
export const TRACKER_UPDATE_ERROR = "tracker_update_error";
export const CLEAR_TRACKER_UPDATE_ERRORS = "clear_tracker_update_errors";
export const SUBMIT_ORDERS = "submit_orders";
export const RE_SUBMIT_TO_EXTERNAL_SERVICE = "re_submit_to_external_services";
export const RE_SUBMIT_TO_EXTERNAL_SERVICE_ERROR = "re_submit_to_external_services_error";
export const EDIT_ORDER = "edit_order";
export const FETCH_ORDER_DETAILS = "fetch_order_details";
export const FETCH_ORDER_PARTNER_UPDATES = "fetch_order_partner_updates";
export const FETCH_SERVICE_DETAILS = "fetch_service_details";
export const FETCH_ORDERS_BY_FILTER = "fetch_order_by_filter";
export const FETCH_ORDERS_BY_FILTER_LOADING = "fetch_order_by_filter_loading";
export const GET_NOTES_HEADER = "get_notes_header";
export const ADD_NOTES = "add_notes";
export const FETCH_NOTES_BY_FILTER = "fetch_notes_by_filter";
export const FETCH_NOTE_BY_TRACKING_NUMBER = "fetch_note_by_tracking_number";
export const FETCH_NOTES_REPORT = "fetch_notes_report";
export const DELETE_NOTES = "delete_notes";
export const CLEAR_NOTES_ERRORS = "clear_notes_errors";
export const DELETE_ORDER = "delete_order";
export const DELETE_ORDER_BY_UPLOAD_BATCH_NO = "delete_order_by_upload_batch_no";
export const CLEAR_DELETE_ORDER_BY_UPLOAD_BATCH_NO_ERROR = "clear_delete_order_by_upload_batch_no_error";
export const FETCH_ORDERS_IN_CSV = "fetch_orders_in_csv";
export const FETCH_TRACKER_UPDATES_IN_CSV = "fetch_tracker_updates_in_csv";
export const CLEAR_REPORT_ERRORS = "clear_report_errors";
export const GET_ORDER_PRICING_PARAMS = "get_order_pricing_params";
export const GET_ORDER_PRICING = "get_order_pricing";
export const ADD_ORDER_PRICING = "add_order_pricing";
export const GET_ORDER_PRICING_DETAILS = "get_order_pricing_details";
export const CLEAR_PRICING_ERRORS = "clear_pricing_errors";
export const ADD_ORDER_WEIGHT_RECONCILIATION = "add_order_weight_reconciliation";
export const FETCH_AGENT_STATS = "fetch_agent_stats";
export const ADD_BILLS = "add_bills";
export const UPDATE_BILLS = "update_bills";
export const FETCH_BILLS_PARAMS = "fetch_bills_params";
export const FETCH_BILLS_BY_FILTER = "fetch_bills_by_filter";
export const FETCH_BILL_BY_ID = "fetch_bill_by_id";
export const CLEAR_BILLS_ERRORS = "clear_bills_errors";
export const CREATE_BULK_BILL_RECORD = "create_bulk_bill_record";
export const CREATE_BULK_BILL_RECORD_RESOLVE = "create_bulk_bill_record_resolve";
export const FETCH_BILL_RECORD_PARAMS = "fetch_bill_record_params";
export const FETCH_BILL_RECORD_BY_FILTER = "fetch_bill_record_by_filter";
export const FETCH_BILL_RECORD_BY_ID = "fetch_bill_record_by_id";
export const FETCH_BILL_RECORD_BY_BILL_ID = "fetch_bill_record_by_bill_id";
export const FETCH_BILL_RECORD_BY_TRACKING_NO = "fetch_bill_record_by_tracking_no";
export const CLEAR_BILL_RECORD_ERRORS = "clear_bill_record_errors";
export const UPLOAD_ADHOC_CSV = "upload_adhoc_csv";
export const CLEAR_UPLOAD_ADHOC_CSV_ERRORS = "clear_upload_adhoc_csv_errors";
export const FETCH_ORDER_REMITTANCE_DATA = "fetch_order_remittance_data";
export const CLEAR_COD_MANAGEMENT_ERRORS = "clear_cod_management_errors";
export const FETCH_PARTNER_REMITTANCE_BY_FILTER = "fetch_partner_remittance_by_filter";
export const FETCH_MERCHANT_REMITTANCE_BY_FILTER = "fetch_merchant_remittance_by_filter";
export const UPLOAD_COD_REMITTANCE = "upload_cod_remittance";
export const FETCH_ALL_PARTNERS = "fetch_all_partners";
export const FETCH_PARTNER_DUES_BY_FILTER = "fetch_partner_dues_by_filter";
export const FETCH_MERCHANT_DUES_BY_FILTER = "fetch_merchant_dues_by_filter";
export const CLEAR_ORDERS_DATA = "clear_orders_data";
export const CLEAR_PARTNER_REMITTANCE = "clear_partner_remittance";
export const CLEAR_MERCHANT_REMITTANCE = "clear_merchant_remittance";
export const CLEAR_PARTNER_DUES = "clear_partner_dues";
export const CLEAR_MERCHANT_DUES = "clear_merchant_dues";
export const ADD_SUB_ACCOUNTS_AGENT_APPLICATION = "add_sub_accounts_agent_application";
export const ADD_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR = "add_sub_accounts_agent_application_error";
export const CLEAR_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR = "clear_sub_accounts_agent_application_error";

export const FETCH_NETWORK_PARTNER_LOADING = "FETCH_NETWORK_PARTNER_LOADING";
export const FETCH_NETWORK_PARTNER_SUCCESS = "FETCH_NETWORK_PARTNER_SUCCESS";
export const FETCH_NETWORK_PARTNER_FAIL = "FETCH_NETWORK_PARTNER_FAIL";
export const OPEN_ERROR_POPUP = "OPEN_ERROR_POPUP";
export const CLOSE_ERROR_POPUP = "CLOSE_ERROR_POPUP";
export const FETCH_CONSIGNEE_DOC_TYPES = "FETCH_CONSIGNEE_DOC_TYPES";
export const EMAIL_CONSIGNEE = "EMAIL_CONSIGNEE";
export const ADD_CORRECT_ORDER_ADDRESS = "ADD_CORRECT_ORDER_ADDRESS";

export const MAWB_FETCH_LOADING = "mawb_fetch_loading";
export const MAWB_FETCHED = "mawb_fetched";
export const MAWB_FETCHED_ERROR = "mawb_fetched_error";
export const MAWB_SELECT_TOGGLE = "mawb_select_toggle";
export const MAWB_SELECT_ALL = "mawb_select_all";

export const MAWB_FILE_ERROR = "mawb_file_error";
export const MAWB_FILE_FETCHED = "mawb_file_fetched";
export const MAWB_FILE_LOADING = "mawb_file_loading";
export const MAWB_FILE_RESET = "mawb_file_reset";

export const MAWB_CREATE_RESET = "mawb_create_reset";
export const MAWB_CREATE_LOADING = "mawb_create_loading";
export const MAWB_CREATED = "mawb_created";
export const MAWB_CREATE_ERROR = "mawb_create_error";
export const FETCH_PORTS = "fetch_ports";

export const NP_DELAY_REASON_CREATED = "np_delay_reason_created";
export const NP_DELAY_REASON_CREATE_LOADING = "np_delay_reason_create_loading";
export const NP_DELAY_REASON_CREATE_ERROR = "np_delay_reason_create_error";

export const MISSED_SLAS_SUCCESS = "missed_slas_success";
export const MISSED_SLAS_LOADING = "missed_slas_loading";
export const MISSED_SLAS_ERROR = "missed_slas_error";

export const MISSED_SLAS_SELECT_ALL = "missed_slas_select_all";
export const MISSED_SLAS_SELECT_TOGGLE = "missed_slas_select_toggle";
export const MISSED_SLAS_VIEW_RESET_SELECTION = "missed_slas_view_reset_selection";

export const MISSED_SLAS_VIEW_FILE_SUCCESS = "missed_slas_view_file_success";

export const FETCH_SLA_DESTINATIONS = "fetch_sla_destinations";
export const FETCH_SLA_NPS = "fetch_sla_nps";

export const FETCH_PAGINATED_AGENT_APPLICATIONS = "fetch_paginated_agent_applications";
export const FETCH_PAGINATED_AGENT_APPLICATION_BY_ID = "fetch_paginated_agent_application_by_id";
export const EDIT_PAGINATED_AGENT_APPLICATION_ERROR = "edit_paginated_agent_application_error";
export const CLEAR_PAGINATED_AGENT_APPLICATION_DATA = "clear_paginated_agent_application";

// HMS actions
export const FETCH_ALL_HUBS = "fetch_all_hubs";

export const FETCH_ORDER_EXCEPTIONS = "FETCH_ORDER_EXCEPTIONS";

// mid-mile
export const MIDMILE_AWB_FETCH_LOADING = "midmile_awb_fetch_loading";
export const MIDMILE_AWB_FETCHED = "midmile_awb_fetched";
export const MIDMILE_AWB_FETCHED_ERROR = "midmile_awb_fetched_error";
export const MIDMILE_AWB_SELECT_TOGGLE = "midmile_awb_select_toggle";
export const MIDMILE_AWB_SELECT_ALL = "midmile_awb_select_all";
export const MIDMILE_AWB_FETCH_DETAILS_LOADING = "midmile_awb_fetch_detals_loading";
export const MIDMILE_AWB_FETCH_DETAILS = "midmile_awb_fetch_details";
export const MIDMILE_AWB_DOCUMENT_UPLOAD = "midmile_awb_document_upload";
export const MIDMILE_AWB_DOCUMENT_DELETE = "midmile_awb_document_delete";
export const MIDMILE_AWB_UPDATE_TRACKING_STATUS = "midmile_awb_update_tracking_status";
export const MIDMILE_AWB_FETCH_ACTIVITY_LOGS = "midmile_awb_fetch_activity_logs";
export const FETCH_EXCHANGE_RATES = "fetch_exchange_rates";
export const MIDMILE_AWB_FETCH_COST = "midmile_awb_fetch_cost";

// lSP-users
export const LSP_USER_FETCH_LOADING = "LSP_USER_FETCH_LOADING";
export const LSP_USER_FETCHED = "LSP_USER_FETCHED";
export const LSP_USER_FETCHED_ERROR = "LSP_USER_FETCHED_ERROR";
