import {
  FETCH_TRACKER_UPDATE,
  FETCH_TRACKER_UPDATE_BY_IDS,
  FETCH_TRACKER_UPDATE_BY_FILTER,
  FETCH_TRACKER_UPDATE_BY_ID,
  CREATE_TRACKER_UPDATE,
  EDIT_TRACKER_UPDATE,
  DELETE_TRACKER_UPDATE_BY_ID,
  DELETE_MULTIPLE_TRACKER_UPDATE,
  TRACKER_UPDATE_ERROR,
  CLEAR_TRACKER_UPDATE_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_TRACKER_UPDATE:
      return {
        ...state,
        trackerUpdates: action.payload.results,
        totalTrackerUpdates: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_TRACKER_UPDATE_BY_IDS:
      return {
        ...state,
        trackerUpdates: action.payload,
        totalTrackerUpdates: action.payload.length,
        totalPages: Math.ceil(action.payload.length / 50),
        lastUpdated: moment()
      };
    case FETCH_TRACKER_UPDATE_BY_FILTER:
      return {
        ...state,
        trackerUpdates: action.payload.results,
        totalTrackerUpdates: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_TRACKER_UPDATE_BY_ID:
      return {
        ...state,
        trackerUpdate: action.payload
      };
    case CREATE_TRACKER_UPDATE:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        isSuccess: !!action.isSuccess,
        successMessage: action.successMessage
      };
    case EDIT_TRACKER_UPDATE:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        message: 'Edit tracker update success!'
      };
    case DELETE_TRACKER_UPDATE_BY_ID:
      let updatedState = {
        ...state,
        trackerUpdates: state.trackerUpdates.filter(update => update.update_id !== action.updateId)
      }
      return {
        ...updatedState,
        error: action.payload,
        message: 'Delete tracker update by id success!'
      };
    case DELETE_MULTIPLE_TRACKER_UPDATE:
      let newState = {
        ...state,
        trackerUpdates: state.trackerUpdates.filter(update => !action.deletedIds.includes(update.update_id))
      }
      return {
        ...newState,
        error: action.payload,
        deleteSuccessStatus: action.deleteSuccessStatus,
        message: 'Delete multiple tracker update success!'
      };
    case TRACKER_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
        message: 'Error occured!'
      };
    case CLEAR_TRACKER_UPDATE_ERRORS:
      return { ...state, error: null, errorData: null, message: null };
    default:
      return state;
  }
}
