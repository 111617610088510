import React from 'react'
import { Select } from 'antd'
import { capitalizeUnderscore } from '../../utils'
import usePublicStatuses from '../../hooks/usePublicStatuses'
const { Option, OptGroup } = Select

const SelectPublicStatus = React.memo((props) => {
  const [publicStatuses, isLoading] = usePublicStatuses()

  const getPubliStatusCodeOptions = () => {
    const { public_statuses, public_statuses_v2 } = publicStatuses
    const options = [
      <Option value="" key="public-status-all">All Statuses</Option>
    ]
    if (!!public_statuses_v2) {
      const statusGroup = []
      public_statuses_v2.forEach(s => {
        statusGroup.push(
          <Option value={`${s}-v2`} key={`public-status-v2-${s}`}>{capitalizeUnderscore(s)}</Option>
        )
      })
      options.push(
        <OptGroup key="public-status-label" label={
          <div style={{ backgroundColor: "#E5E5E5" }} className="mb-1 mt-2 px-2">
            <strong>Public Statuses</strong>
          </div>
        }>
          {statusGroup}
        </OptGroup>
      )
    }
    if (!!public_statuses) {
      const statusGroup = []
      public_statuses.forEach(s => {
        statusGroup.push(
          <Option value={`${s}-v1`} key={`public-status-old-${s}`}>{s}</Option>
        )
      })
      options.push(
        <OptGroup key="public-status-old-label" label={
          <div style={{ backgroundColor: "#E5E5E5" }} className="mb-1 mt-2 px-2">
            <strong>Old Public Statuses</strong>
          </div>
        }>
          {statusGroup}
        </OptGroup>
      )
    }
    return options
  }

  return (
    <Select
      id="statusCode"
      name="publicStatusCode"
      style={{ width: '100%' }}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {getPubliStatusCodeOptions()}
    </Select>
  )
})

export default SelectPublicStatus
