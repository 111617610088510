import React, { useContext } from "react"
import { ResolveOrderExceptionContext } from "../ResolveOrderException.context"
import { Form, Input, Button, Select } from "antd"
import { useFormik } from "formik"
import * as yup from "yup"
import { steps } from "../constants"
import SelectStates from "../../../common/SelectStates"
import SelectCities from "../../../common/SelectCities"

const { Option } = Select


const PickupDetails = () => {
  const {
    orderData
  } = useContext(ResolveOrderExceptionContext)
  if (orderData.length > 1) {
    return <BulkCheckPickup />
  }
  return <PickupDetailsForms order={orderData[0]} />
}


const PickupDetailsForms = ({ order }) => {
  const {
    handleCloseModal,
    saveData, setCurrentStep,
    collectedData, submitResponse,
    orderData
  } = useContext(ResolveOrderExceptionContext)

  const { recollection_exceptions } = collectedData
  const pickupDetails = (recollection_exceptions && recollection_exceptions[0]) || {}

  const initialValues = {
    order_id: order.order_id,
    pickup_contact_name: pickupDetails.pickup_contact_name || order.pickup_contact_name || undefined,
    pickup_address: pickupDetails.pickup_address || order.pickup_address || undefined,
    pickup_postal: pickupDetails.pickup_postal || order.pickup_postal || undefined,
    pickup_state: pickupDetails.pickup_state || order.pickup_state || undefined,
    pickup_city: pickupDetails.pickup_city || order.pickup_city || undefined,
    pickup_country: pickupDetails.pickup_country || order.pickup_country || undefined,
    pickup_contact_number: pickupDetails.pickup_contact_number || order.pickup_contact_number || undefined,
    pickup_contact_email: pickupDetails.pickup_contact_email || order.pickup_contact_email || undefined,
    payment_type: pickupDetails.payment_type || order.payment_type || undefined,
    cod_amt_to_collect: pickupDetails.cod_amt_to_collect || order.cod_amt_to_collect || undefined,
  }
  const validationSchema = yup.object().shape({
    pickup_contact_name: yup.string().nullable(),
    pickup_address: yup.string().nullable(),
    pickup_postal: yup.string().nullable(),
    pickup_state: yup.string().nullable(),
    pickup_city: yup.string().nullable(),
    pickup_country: yup.string().nullable(),
    pickup_contact_number: yup.string().nullable(),
    pickup_contact_email: yup.string().nullable(),
    payment_type: yup.string().nullable(),
    cod_amt_to_collect: yup.number("Enter a valid number").nullable(),
  })

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    saveData({
      recollection_exceptions: [values]
    })
    setCurrentStep(steps.leaveNote)
  }

  let initialErrors = {}
  if (orderData.length === 1 && submitResponse?.isError && submitResponse?.error?.recollection_exceptions) {
    const errorData = submitResponse.error?.recollection_exceptions[0]
    initialErrors = {...errorData}
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    initialErrors,
    initialTouched: initialErrors,
    enableReinitialize: true
  })

  const getErrorsProps = fieldName => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return {
        validateStatus: "error",
        help: formik.errors[fieldName],
        hasFeedback: true
      };
    }
    return {};
  };
  const getFieldProps = (fieldName, forceDisabled=false) => ({
    name: fieldName,
    autoComplete: "off",
    value: formik.values[fieldName],
    onChange: formik.handleChange,
    disabled: formik.isSubmitting || forceDisabled
  });
  const disabledCountry = true

  return (
    <form onSubmit={formik.handleSubmit}>
      <h6>Check order details</h6>
      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <Form.Item
              label="Pickup Contact Name"
              colon={false}
              {...getErrorsProps('pickup_contact_name')}
              >
                <Input
                  placeholder="Pickup Contact Name"
                  {...getFieldProps('pickup_contact_name')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Contact Number"
              colon={false}
              {...getErrorsProps('pickup_contact_number')}
              >
                <Input
                  placeholder="Pickup Contact Number"
                  {...getFieldProps('pickup_contact_number')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Contact Email"
              colon={false}
              {...getErrorsProps('pickup_contact_email')}
              >
                <Input
                  placeholder="Pickup Contact Email"
                  {...getFieldProps('pickup_contact_email')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Address"
              colon={false}
              {...getErrorsProps('pickup_address')}
              >
                <Input
                  placeholder="Pickup Address"
                  {...getFieldProps('pickup_address')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Country"
              colon={false}
              {...getErrorsProps('pickup_country')}
              >
                <Input
                  placeholder="Pickup Country"
                  {...getFieldProps('pickup_country', disabledCountry)} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup State"
              colon={false}
              {...getErrorsProps('pickup_state')}
              >
                <SelectStates
                  country={formik.values.consignee_country}
                  onChange={value => {
                    formik.setFieldValue("pickup_state", value)
                    formik.setFieldValue("pickup_city", null)
                  }}
                  value={formik.values.pickup_state}
                />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup City"
              colon={false}
              {...getErrorsProps('pickup_city')}
              >
                <SelectCities
                  country={formik.values.pickup_country}
                  state={formik.values.pickup_state}
                  value={formik.values.pickup_city}
                  onChange={value => {
                    formik.setFieldValue("pickup_city", value)
                  }}
                />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Province"
              colon={false}
              {...getErrorsProps('pickup_province')}
              >
                <Input
                  placeholder="Pickup Province"
                  {...getFieldProps('pickup_province')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Pickup Postal Code"
              colon={false}
              {...getErrorsProps('pickup_postal')}
              >
                <Input
                  placeholder="Pickup Postal Code"
                  {...getFieldProps('pickup_postal')} />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Payment Type"
              colon={false}
              {...getErrorsProps('payment_type')}
              >
                <Select style={{ width: "100%" }} placeholder="Select payment type"
                  onChange={value => formik.setFieldValue("payment_type", value)}
                  value={formik.values.payment_type}>
                  <Option value="prepaid">Prepaid</Option>
                  <Option value="cod">COD</Option>
                </Select>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="COD Amount to collect"
              colon={false}
              {...getErrorsProps('cod_amt_to_collect')}
              >
                <Input
                  placeholder="COD Amount to collect"
                  {...getFieldProps(
                    'cod_amt_to_collect',
                    ["Prepaid", "prepaid"].includes(formik.values.payment_type)
                    )} />
            </Form.Item>
          </div>
        </div>
      </div>

      {formik.errors.order_id &&
      <div className="mt-3">
        <p className="mb-0 text-danger">{formik.errors.order_id}</p>
      </div>
      }
      {formik.errors.non_field_errors &&
      <div className="mt-3">
        {formik.errors.non_field_errors.map((err, i) => (
          <p className="mb-1 text-danger" key={i}>{err}</p>
        ))}
      </div>
      }

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()} disabled={formik.isSubmitting}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting? "Processing...":"Proceed"}
          </Button>
        </div>
      </div>
    </form>
  )
}

const BulkCheckPickup = () => {
  const {
    setCurrentStep
  } = useContext(ResolveOrderExceptionContext)
  return (
    <div>
      <h6>Check order details</h6>
      <div className="mt-4">
        <p className="mb-0">Are there any order details to be corrected? If so, please select them for recollection individually. Recollection for selected orders will be resubmitted with their existing data.</p>
      </div>

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button type="primary" htmlType="button" onClick={() => setCurrentStep(steps.leaveNote)}>OK</Button>
        </div>
      </div>
    </div>
  )
}

export default PickupDetails