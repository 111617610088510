import axios from "axios";
import { useQuery } from "react-query";

export const cityAPI = (countries, states, additionalParams) => ({
  getCities: async () => {
    if (!countries || !states) {
      return [];
    }

    let params = {
      countries,
      states,
      ...additionalParams,
    };
    const response = await axios.get("/api/location/cities/", {
      params,
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  },
});

const useCities = (countries, states, additionalParams = {}) => {
  const { data: cities, isLoading } = useQuery(["cities", countries, states], cityAPI(countries, states, additionalParams).getCities, {
    staleTime: Infinity,
    refetchInterval: false,
  });
  return {
    cities: cities || [],
    isLoading,
  };
};

export default useCities;
