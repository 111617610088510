import React, { useRef } from "react";
import Select from "react-select";
import { List } from "react-virtualized";

const MenuList = (props) => {
  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    if (Array.isArray(rows)) {
      return (
        <div key={key} style={style}>
          {rows[index]}
        </div>
      );
    }
  };

  const noRowsRenderer = () => {
    return (
      <div
        style={{
          textAlign: "center",
          fontSize: "16px",
          marginTop: "10px",
        }}
      >
        [No Options]
      </div>
    );
  };

  return (
    <List
      width={1}
      containerStyle={{
        width: "100%",
        maxWidth: "100%",
      }}
      style={{
        width: "100%",
      }}
      height={200}
      rowHeight={30}
      rowCount={Array.isArray(rows) ? rows.length : 0}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowsRenderer}
    />
  );
};

const FastSelect = (props) => {
  const containerRef = useRef(null);
  return (
    <div id="scrollContainer" ref={containerRef}>
      <Select
        components={{ MenuList }}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        closeMenuOnScroll={(e) => {
          return e.target.contains(containerRef.current);
        }}
        {...props}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default FastSelect;
