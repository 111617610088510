import {
  ADD_ORDER_WEIGHT_RECONCILIATION
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch(action.type) {
    case ADD_ORDER_WEIGHT_RECONCILIATION:
      return {
        ...state,
        errorData: action.errorData,
        lastSubmitted: moment()
      };
    default:
      return state;
  }
}
