import React, { useState, useCallback } from "react"
import { OrderEscalationAPI } from "./api"
import { steps } from "./constants"

export const OrderEscalationContext = React.createContext()
const OrderEscalationProvider = ({
  orderData, handleCloseModal, singleException,
  handleEscalateSuccess, escalationTypes,
  children
}) => {
  const [collectedData, setCollectedData] = useState(
    singleException
    ? {escalation: [singleException.exception]}
    : {}
  )
  const [submitResponse, setSubmitResponse] = useState()
  const orderIds = useCallback(orderData.map(order => order.order_id), [orderData])

  const getOrderEscalationTypes = useCallback(() => {
    let resultTypes = []
    if (orderData.length === 1) {  // single order
      const order = orderData[0]
      Object.entries(escalationTypes).forEach(([type, exceptions]) => {
        if (order.order_exceptions.some(orderException => exceptions.includes(orderException))) {
          resultTypes.push(type)
        }
       })
    } else { // multiple orders
      Object.entries(escalationTypes).forEach(([type, exceptions]) => {
        orderData.forEach(({ order_exceptions }) => {
          if (order_exceptions.some(orderException => exceptions.includes(orderException))) {
            resultTypes.push(type)
          }
        })
      })
      resultTypes = [...new Set(resultTypes)]
    }
    return resultTypes
  }, [orderData])

  let escalationType
  const getInitialStep = () => {
    const resultTypes = getOrderEscalationTypes()
    if (!resultTypes.length) {
      return steps.unableEscalate
    }

    if (orderData.length === 1) {
      if (resultTypes.length > 1) {
        return steps.chooseEscalationMethod
      } else {
        escalationType = resultTypes[0]
        return steps.confirmation
      }
    } else { // multiple selected orders
      if (resultTypes.length > 1) {
        return steps.unableEscalate
      }
      escalationType = resultTypes[0]
    }
    return steps.confirmation
  }

  const initialStep = getInitialStep()
  const [currentStep, setCurrentStep] = useState(initialStep)

  const [selectedEscalationType, setSelectedEscalationType] = useState(escalationType)

  const submitData = async (reason=undefined) => {
    const response = await OrderEscalationAPI.escalateOrders({
      order_ids: orderIds,
      reason,
      escalation_type: selectedEscalationType
    })
    setSubmitResponse(response)
    return response
  }

  const value = {
    orderIds,
    orderData, handleCloseModal,
    currentStep, setCurrentStep,
    collectedData, setCollectedData,
    submitData,
    submitResponse,
    singleException,
    handleEscalateSuccess,
    selectedEscalationType, setSelectedEscalationType
  }
  return (
    <OrderEscalationContext.Provider value={value}>
      {children}
    </OrderEscalationContext.Provider>
  )
}

export default OrderEscalationProvider
