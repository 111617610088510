import {
  MIDMILE_AWB_FETCHED,
  MIDMILE_AWB_FETCHED_ERROR,
  MIDMILE_AWB_FETCH_LOADING,
  MIDMILE_AWB_SELECT_ALL,
  MIDMILE_AWB_SELECT_TOGGLE,
  MIDMILE_AWB_FETCH_DETAILS,
  MIDMILE_AWB_FETCH_DETAILS_LOADING,
  MIDMILE_AWB_DOCUMENT_UPLOAD,
  MIDMILE_AWB_DOCUMENT_DELETE,
  MIDMILE_AWB_UPDATE_TRACKING_STATUS,
  MIDMILE_AWB_FETCH_ACTIVITY_LOGS,
  FETCH_EXCHANGE_RATES,
  MIDMILE_AWB_FETCH_COST
} from '../../actions/types'

export default function (state = { create: {}, awbs: {} }, action) {
  switch (action.type) {
    case MIDMILE_AWB_SELECT_ALL:
      state.awbs.allSelected = !state.awbs.allSelected;
      state.awbs.actionable = state.awbs.allSelected;
      state.awbs.raw.results = state.awbs.raw.results.map(m => {
        m.isSelected = state.awbs.allSelected;
        return m;
      });
      return { ...state }

    case MIDMILE_AWB_SELECT_TOGGLE:
      const newArr = [...state.awbs.raw.results];
      newArr[action.payload].isSelected = !newArr[action.payload].isSelected;
      state.awbs.actionable = false;
      newArr.forEach(r => {
        if (r.isSelected) {
          state.awbs.actionable = true;
        }
      });
      state.awbs.raw.results = newArr;
      state.awbs.allSelected = false;
      return { ...state }

    case MIDMILE_AWB_FETCHED:
      state.awbs.allSelected = false;
      return {
        ...state,
        awbs: { ...state.awbs, raw: action.payload, loading: false, error: false }
      };

    case MIDMILE_AWB_FETCH_LOADING:
      return {
        ...state,
        awbs: { ...state.awbs, raw: false, loading: true, error: false }
      }
    case MIDMILE_AWB_FETCHED_ERROR:
      return {
        ...state,
        awbs: { ...state.awbs, raw: false, loading: false, error: action.payload }
      }
    case MIDMILE_AWB_FETCH_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        awbDetails: null
      }
    case MIDMILE_AWB_FETCH_DETAILS:
      return {
        ...state,
        isLoading: false,
        awbDetails: action.payload
      }
    case MIDMILE_AWB_DOCUMENT_UPLOAD:
      let document_urls = state.awbDetails?.document_urls ? state.awbDetails?.document_urls : [];
      document_urls.push(action.payload);
      return {
        ...state,
        awbDetails: { ...state.awbDetails, document_urls }
      }
    case MIDMILE_AWB_DOCUMENT_DELETE:
      let docs = state.awbDetails?.document_urls.filter(doc => doc.id !== action.payload)
      return {
        ...state,
        awbDetails: { ...state.awbDetails, document_urls: docs }
      }
    case MIDMILE_AWB_UPDATE_TRACKING_STATUS:
      let details = { ...state.awbDetails, latest_status_airway_bill_level: action?.payload?.latest_status_airway_bill_level, remarks: action?.payload?.remarks || "" };
      if (action?.payload?.latest_status_airway_bill_level === "MAWB") {
        details = { ...details, ...action?.payload?.mawb }
      } else {
        let hawbs = [...state.awbDetails?.hawb];
        let hawbUpdateData = action?.payload?.hawb;
        hawbUpdateData.forEach(data => {
          hawbs = hawbs.map((hawbObj => {
            if (hawbObj.hawb_id === data.hawb_id) {
              hawbObj = { ...hawbObj, ...data };
            }
            return hawbObj;
          }));

        });
        details = { ...details, hawb: hawbs }
      }
      return {
        ...state,
        awbDetails: details
      }
    case MIDMILE_AWB_FETCH_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogs: action.payload
      }
    case FETCH_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload
      }
    case MIDMILE_AWB_FETCH_COST:
      return {
        ...state,
        costDetails: action.payload
      }
    default:
      return state;
  }
}