import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { ViewOrdersContext } from "../ViewOrders.context";
import Filters from "./filters";
import SelectedOrdersActions from "./SelectedOrdersActions";
import ViewOrdersTable from "./ViewOrdersTable";

function ViewOrders() {
  const { filters, ordersResponse, ordersFetching, resetPageSearch, showErrorMessage } = useContext(ViewOrdersContext);

  return (
    <div>
      <div className="container">
        <Filters filters={filters} onResetPage={resetPageSearch} ordersFetching={ordersFetching} showErrorMessage={showErrorMessage} />
      </div>
      {!!Object.entries(filters).length && !isEmpty(ordersResponse) ? (
        <>
          <div>
            <ViewOrdersTable ordersResponse={ordersResponse} ordersFetching={ordersFetching} />
          </div>
          <div className="container">
            <SelectedOrdersActions />
          </div>
        </>
      ) : (
        <div className="text-center mt-5">
          <p>Please apply some filters.</p>
        </div>
      )}
    </div>
  );
}

export default ViewOrders;
