import {
  CREATE_BULK_BILL_RECORD,
  CREATE_BULK_BILL_RECORD_RESOLVE,
  FETCH_BILL_RECORD_PARAMS,
  FETCH_BILL_RECORD_BY_FILTER,
  FETCH_BILL_RECORD_BY_ID,
  FETCH_BILL_RECORD_BY_BILL_ID,
  FETCH_BILL_RECORD_BY_TRACKING_NO,
  CLEAR_BILL_RECORD_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case CREATE_BULK_BILL_RECORD:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        message: 'Success!',
        lastUpdated: moment()
      };
    case CREATE_BULK_BILL_RECORD_RESOLVE:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        message: 'Resolve errors success!',
        lastUpdated: moment()
      };
    case FETCH_BILL_RECORD_PARAMS:
      return {
        ...state,
        billRecordParams: action.payload,
      };
    case FETCH_BILL_RECORD_BY_FILTER:
      return {
        ...state,
        billRecord: action.payload.results,
        totalBillRecord: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_BILL_RECORD_BY_ID:
      return {
        ...state,
        billRecordDetails: action.payload,
      };
    case FETCH_BILL_RECORD_BY_BILL_ID:
      return {
        ...state,
        billRecord: action.payload.results,
        totalBillRecord: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_BILL_RECORD_BY_TRACKING_NO:
      return {
        ...state,
        billRecord: action.payload.results,
        totalBillRecord: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case CLEAR_BILL_RECORD_ERRORS:
      return { ...state, error: null, errorData: null, message: null };
    default:
      return state;
  }
}
