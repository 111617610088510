import {
  ADD_BILLS,
  UPDATE_BILLS,
  FETCH_BILLS_PARAMS,
  FETCH_BILLS_BY_FILTER,
  FETCH_BILL_BY_ID,
  CLEAR_BILLS_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case ADD_BILLS:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        addBillsData: action.addBillsData,
        lastUpdated: moment()
      };
    case UPDATE_BILLS:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        lastUpdated: moment()
      };
    case FETCH_BILLS_PARAMS:
      return {
        ...state,
        billsParams: action.payload,
      };
    case FETCH_BILLS_BY_FILTER:
      return {
        ...state,
        bills: action.payload.results,
        totalBills: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_BILL_BY_ID:
      return {
        ...state,
        billDetails: action.payload,
      };
    case CLEAR_BILLS_ERRORS:
      return { ...state, error: null, errorData: null, message: null };
    default:
      return state;
  }
}
