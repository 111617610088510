import {
  FETCH_AGENT_SERVICES,
  FETCH_AGENT_SERVICE_BY_ID,
  EDIT_AGENT_SERVICE
} from '../../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_AGENT_SERVICES:
      return { ...state, services: action.payload };
    case FETCH_AGENT_SERVICE_BY_ID:
      return { ...state, service: action.payload };
    case EDIT_AGENT_SERVICE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
