import React, { useContext, useState } from "react";
import { DatePicker, Input } from "antd";
import moment from "moment";
import { ViewOrdersContext } from "../../ViewOrders.context";
const { RangePicker } = DatePicker;

const DateFilter = () => {
  const { filters, handleChangeFilter, handleChangeFiltersValues } = useContext(ViewOrdersContext);

  const [values, setValues] = useState({
    days_since_last_update: "",
  });
  // const updateFilters = React.useCallback(debounceFunc(handleChangeFiltersValues, 500), [])
  const handleTextChange = (field, value) => {
    const newValues = {
      ...values,
      [field]: value,
    };
    setValues(newValues);
    handleChangeFilter(newValues);
  };

  const handleDateChange = (e, fieldName) => {
    let date;
    if (fieldName === "date_from") {
      date = e ? moment(e).startOf("day").format("YYYY-MM-DD") : e;
    } else if (fieldName === "date_to") {
      date = e ? moment(e).endOf("day").format("YYYY-MM-DD") : e;
    }
    handleChangeFilter(fieldName, date);
  };

  const handleDateRangeChange = (dates) => {
    const pickup_date_from = dates[0]?.format("YYYY-MM-DD");
    const pickup_date_to = dates[1]?.format("YYYY-MM-DD");
    handleChangeFiltersValues({
      pickup_date_from,
      pickup_date_to,
    });
  };

  const dateFields = [
    {
      name: "date_from",
      title: "Date From",
    },
    {
      name: "date_to",
      title: "Date To",
    },
  ];

  return (
    <div className="mt-3">
      {/* <h5>Dates</h5> */}
      <div className="row">
        {dateFields.map((dateField, index) => {
          return (
            <div className="col-md-3" key={`date-field-${index}`}>
              <div className="w-100">
                <p className="mb-1">{dateField.title}</p>
                <DatePicker
                  className="w-100"
                  name={dateField.name}
                  format="DD/MM/YY"
                  onKeyDown={(e) => e.preventDefault()}
                  locale="en"
                  disabledDate={(current) => current && current > moment().endOf("day")}
                  value={filters[dateField.name] ? moment(filters[dateField.name]) : null}
                  // defaultValue={filters[dateField.name] ? moment(dateField.name).format() : null}
                  onChange={(e) => handleDateChange(e, dateField.name)}
                  todayButton={"Today"}
                />
              </div>
            </div>
          );
        })}

        <div className="col-md-4">
          <div className="w-100">
            <p className="mb-1">Pickup Date</p>
            <RangePicker
              name="pickupDate"
              className="w-100"
              onChange={(dates) => handleDateRangeChange(dates, "pickup_date")}
              format="DD/MM/YY"
              ranges={{
                Today: [moment(), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              value={[filters.pickup_date_from ? moment(filters.pickup_date_from) : null, filters.pickup_date_to ? moment(filters.pickup_date_to) : null]}
            />
          </div>
        </div>

        <div className="col-md-2">
          <div className="w-100">
            <p className="mb-1">Days Since Last Update</p>
            <Input value={values.days_since_last_update} onChange={(e) => handleTextChange("days_since_last_update", e.target.value)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
