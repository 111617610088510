import React, { useContext } from 'react'
import { Select } from 'antd'
import { ViewOrdersContext } from '../../ViewOrders.context'
import useOrderExceptions from '../../hooks/useOrderExceptions'
import EscaltionStatusOptions from '../../../orderEscalation/components/EscalationStatusOptions'
const { Option } = Select

function ExceptionFilter() {
  const { filters, handleChangeFilter } = useContext(ViewOrdersContext)

  const [orderExceptions, orderExceptionsLoading] = useOrderExceptions()
  const getExceptionOptions = () => {
    return orderExceptions.map(
      e => <Option key={e} value={e}>{e}</Option>
    )
  }

  return (
    <div className="row mt-3">
      <div className="col-md-8">
        <div>
          <p className="mb-0">Exceptions</p>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            onChange={v => handleChangeFilter('order_exceptions', v)}
            value={filters.order_exceptions}
            disabled={orderExceptionsLoading}
            loading={orderExceptionsLoading}
            allowClear
          >
              {getExceptionOptions()}
          </Select>
        </div>
      </div>

      <div className="col-md-4">
        <div>
          <p className="mb-0">Escalation Status</p>
          <EscaltionStatusOptions
            style={{ width: '100%' }}
            onChange={v => handleChangeFilter("escalation_status", v)}
            value={filters.escalation_status}
            allowClear />
        </div>
      </div>
    </div>
  )
}

export default ExceptionFilter
