import React, { useContext } from "react";
import moment from "moment";
import ReturnIcon from "./ReturnIcon";
import SingleOrderAction from "./SingleOrderAction";
import { columnsOrdering, MPS_ORDER_TYPES } from "../constants";
import { Checkbox } from "antd";
import { ViewOrdersContext } from "../ViewOrders.context";
import { escalationStatuses } from "../../orderEscalation/constants";
import { capitalizeUnderscore } from "../../../../utils";
import MPSIcon from "./MPSIcon";

export const ColumnSelection = () => {
  const { selectColumn, unselectColumn, selectedColumns } =
    useContext(ViewOrdersContext);
  return (
    <div className="mt-3">
      <h4>Show/Hide Columns</h4>
      <div className="mt-3">
        {columnsOrdering.map((column, i) => {
          return (
            <Checkbox
              key={`column-show-${i}`}
              onChange={(e) =>
                e.target.checked ? selectColumn(column) : unselectColumn(column)
              }
              checked={selectedColumns.includes(column)}
            >
              {capitalizeUnderscore(column)}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

const columnDefintions = {
  tracking_no: {
    title: "Tracking No",
    dataIndex: "tracking_no",
    width: "auto",
    fixed: "left",
    render: (text, record) => {
      if (!!record.is_return_order) {
        return (
          <p className="mb-0">
            <ReturnIcon /> {text}
          </p>
        );
      } else if (MPS_ORDER_TYPES.includes(record.order_type)) {
        return (
          <p className="mb-0">
            <MPSIcon orderType={record.order_type} /> {text}
          </p>
        );
      }
      return text;
    },
  },
  external_tracking_nos: {
    title: "External Tracking No",
    dataIndex: "external_tracking_nos",
    render: (value) => (!!value?.length ? value.join(",") : "N/A"),
    width: 100,
  },
  agent_application_name: {
    title: "Agent Application",
    dataIndex: "agent_application_name",
  },
  tracker_status_code: {
    title: "Public Status Code",
    dataIndex: "public_tracker_status_v2",
    render: (value) => capitalizeUnderscore(value),
  },
  private_tracker_status_code: {
    title: "Private Status Code",
    dataIndex: "private_tracker_status_v2",
    render: (value) => capitalizeUnderscore(value),
  },
  order_type: {
    title: "Order Type",
    dataIndex: "is_return_order",
    render: (value) => (value ? "Returns" : "Forward"),
  },
  pickup_date: {
    title: "Scheduled Pickup Date",
    dataIndex: "pickup_date",
    render: (value) => (!!value ? moment(value).format("DD/MM/YY") : "N/A"),
  },
  forward_order_id: {
    title: "Forward/Return Link",
    dataIndex: "forward_order_id",
    render: (_, record) => {
      if (record.forward_tracking_no) {
        return (
          <p className="mb-0">
            <a
              href={`/agent/view-orders/${record.forward_order_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.forward_tracking_no}
            </a>
          </p>
        );
      } else if (record.return_tracking_no) {
        return (
          <p className="mb-0">
            <ReturnIcon />
            <a
              href={`/agent/view-orders/${record.return_order_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.return_tracking_no}
            </a>
          </p>
        );
      } else {
        return "N/A";
      }
    },
  },
  // partner_submission_status: {
  //   title: "partner submission status",
  //   dataindex: "partner_submission_status",
  //   render: (value) => {
  //     const { error_count, success_count, total_count } = value;
  //     if (error_count) {
  //       return <span classname="badge badge-danger">error</span>;
  //     } else if (success_count === total_count) {
  //       return <span classname="badge badge-success">success</span>;
  //     } else {
  //       return (
  //         <span classname="badge badge-warning">
  //           {success_count}/{total_count} success
  //         </span>
  //       );
  //     }
  //   },
  // },
  created_on: {
    title: "Created On",
    dataIndex: "created_on",
    render: (value) => moment(value).format("DD/MM/YY HH:mm"),
  },

  shipper_order_id: {
    title: "Shipper Order ID",
    dataIndex: "shipper_order_id",
    render: (value) => value || "N/A",
  },
  escalation_status: {
    title: "Escalation Status",
    dataIndex: "escalation_status",
    render: (value) => {
      if (value) {
        const escalationStatus = escalationStatuses[value];
        return (
          <span
            className="badge"
            style={{
              backgroundColor: escalationStatus.bgColor,
            }}
          >
            {escalationStatus.title}
          </span>
        );
      }
      return "N/A";
    },
  },

  upload_batch_no: {
    title: "Upload Batch No",
    dataIndex: "upload_batch_no",
    render: (value) => value || "N/A",
  },
  consignee_name: {
    title: "Consignee Name",
    dataIndex: "consignee_name",
  },
  consignee_email: {
    title: "Consignee Email",
    dataIndex: "consignee_email",
  },
  pickup_country: {
    title: "Pickup Country",
    dataIndex: "pickup_country",
  },
  consignee_country: {
    title: "Consignee Country",
    dataIndex: "consignee_country",
  },
  consignee_address: {
    title: "Consignee Address",
    dataIndex: "consignee_address",
  },
  consignee_number: {
    title: "Consignee Number",
    dataIndex: "consignee_number",
  },
  // identification_document_name: {
  //   title: "Identification Document Name",
  //   dataIndex: "identification_document_name",
  //   render: (value) => value || "N/A",
  // },
  // consignee_identifcation_number: {
  //   title: "Identification Number",
  //   dataIndex: "consignee_identifcation_number",
  //   render: (value) => value || "N/A",
  // },
  shipping_batch_nos: {
    title: "Shipping Batch Nos",
    dataIndex: "shipping_batch_nos",
    render: (value) => (!!value.length ? value.join(",") : "N/A"),
  },
  // customs_documents_status: {
  //   title: "Consignee Customs Document",
  //   dataIndex: "customs_documents_status",
  //   render: (value) => {
  //     let fontColor;
  //     switch (value) {
  //       case "Pending Documents":
  //         fontColor = "#FF8900";
  //         break;
  //       case "Documents Uploaded":
  //         fontColor = "#27AE60";
  //         break;
  //       default:
  //         fontColor = "#000000";
  //     }

  //     let label = <label style={{ color: fontColor }}>{value}</label>;
  //     return value ? label : "N/A";
  //   },
  // },
  order_exceptions: {
    title: "Order Exceptions",
    dataIndex: "order_exceptions",
    render: (value) => (!!value.length ? value.join(",") : "N/A"),
  },
};

export const getColumns = (selectedColumns = []) => {
  const columns = [];
  for (let column of columnsOrdering) {
    if (selectedColumns.includes(column)) {
      columns.push(columnDefintions[column]);
    }
  }
  const actionColumn = {
    title: "Actions",
    dataIndex: "order_id",
    render: (orderId, record) => <SingleOrderAction order={record} />,
    fixed: "right",
  };
  columns.push(actionColumn);
  return columns;
};
