import React, { useMemo } from "react"
import { Select, AutoComplete } from "antd"
import useCities from "./hooks/useCities"

const { Option } = Select

const SelectCities = ({ country, state, ...rest }) => {
  const {
    placeholder="Select city",
    value,
    onChange,
    disabled,
    additionalParams,
    ...otherProps
  } = rest
  const { cities, isLoading } = useCities(country, state, additionalParams)

  const cityOptions = cities.length
    ? cities.map(city => {
      return (
      <Option
        key={`city-${city.city_id}`}
        value={city.city_name}
        disabled={city.is_available === false}
        >
          {city.city_name}
      </Option>
      )
    })
    : []

  return useMemo(() =>
    // <Select
    //   optionFilterProp="children"
    //   filterOption={(input, option) =>
    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   }
    //   placeholder={placeholder}
    //   value={value}
    //   onSelect={onSelect}
    //   loading={isLoading}
    //   disabled={!country || !state || isLoading || disabled}
    //   >
    //     {cityOptions}
    // </Select>,
    <AutoComplete
      placeholder={placeholder}
      disabled={!country || !state || isLoading || disabled}
      onChange={onChange}
      value={value}
      dataSource={cityOptions}
      filterOption={true}
      {...otherProps}
    />,
    [value, isLoading, cityOptions, country, state]
  )
}

export default SelectCities
