import React, { useMemo } from "react"
import { Select } from "antd"
import useStates from "./hooks/useStates"

const { Option } = Select

const SelectStates = ({ country, ...rest }) => {
  const {
    placeholder="Select state",
    value,
    onChange,
    additionalParams,
    ...otherProps
  } = rest
  const { states, isLoading } = useStates(country, additionalParams)

  const statesOptions = states.length
    ? states.map(state => {
      return <Option
        key={`state-${state.state_id}`}
        value={state.state_name}
        disabled={state.is_available === false}
        >{state.state_name}</Option>
    })
    : []

  return useMemo(() =>
    <Select
      showSearch
      loading={isLoading}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={{
        width: "100%"
      }}
      {...otherProps}
      >
      {statesOptions}
    </Select>,
    [value, isLoading, statesOptions]
  )
}

export default SelectStates
