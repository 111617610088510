import React from 'react'
import { Select } from 'antd'
import useHubs from '../../hooks/useHubs'
const { Option } = Select

const SelectHubs = React.memo((props) => {
  const [hubs, isLoading] = useHubs()

  const getHubOptions = () => {
    const options = []
    hubs.forEach(hub => {
      options.push(<Option key={hub.id} value={hub.id}>{hub.hub_name}</Option>)
    })
    return options
  }

  return (
    <Select
      id="hubs"
      name="hubs"
      style={{ width: '100%' }}
      disabled={isLoading || props.disabled}
      showSearch
      allowClear
      {...props}
    >
      {getHubOptions()}
    </Select>
  )
})

export default SelectHubs
