import React from "react";
import { ViewOrdersContext } from "../ViewOrders.context";
import classNames from "classnames";
import { message } from "antd";
import axios from "axios";

export default function RegenerateLabels() {
  const [loading, setLoading] = React.useState(false);
  const { selectedOrders } = React.useContext(ViewOrdersContext);

  const handleClick = async () => {
    setLoading(true);
    message.info("Regenerating labels....");

    try {
      const { data } = await axios.post(
        "/api/api/label/generate-label-with-orderid/",
        {
          order_ids: selectedOrders.map((order) => order.order_id),
        }
      );
      message.success(data?.message || "Labels regenerated successfully.");
    } catch (error) {
      console.log(error);
      message.error("Unable to regenerate labels, please try again later.");
    }

    setLoading(false);
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length || loading,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!selectedOrders.length || loading}
    >
      Regenerate Labels
    </button>
  );
}
