import React from 'react'
import { Select } from 'antd'
import useServices from '../../hooks/useServices'
const { Option } = Select

const SelectService = ({ onChange, mode = 'single', ...props }) => {
  const [services, servicesLoading] = useServices()
  const renderOptions = () => {
    let options = [
      <Option key="all-services" value="">
        All Services
      </Option>
    ]
    services.forEach(service => {
      options.push(
        <Option
          key={service.service_id}
          value={service.service_id}>
            {service.service_name}
        </Option>
      )
    })
    return options
  }

  return (
    <Select
      size="large"
      allowClear
      showSearch
      style={{ width: "100%" }}
      placeholder="Select service"
      loading={servicesLoading}
      disabled={servicesLoading || props.isDisabled}
      onChange={onChange}
      mode={mode}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
      >
        {renderOptions()}
    </Select>
  )
}

export default SelectService