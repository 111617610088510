import moment from "moment";
import queryString from "query-string";
import React, { createContext, useEffect, useState } from "react";
import { defaultSelectedColumns } from "./constants";
import useOrders from "./hooks/useOrders";
import { queryToFilters } from "./utils";
import useAgentApplications from "../../../hooks/useAgentApplications";

export const ViewOrdersContext = createContext();
export function ViewOrdersContextProvider({ children }) {
  const queryParams = queryString.parse(window.location.search);
  const initialFilters = queryToFilters(queryParams);
  const [filters, setFilters] = useState({
    ...initialFilters,
    date_from: moment().subtract(1, "Y").format("YYYY-MM-DD"),
  });
  const [agentApplications, agentApplicationsLoading] = useAgentApplications();
  const [orderToCancel, setOrderToCancel] = useState();
  const [ordersToResolve, setOrdersToResolve] = useState([]);
  const [ordersToEscalate, setOrdersToEscalate] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);

  const [mutate, ordersResponse, ordersFetching] = useOrders(filters);

  const onSearchHandler = () => {
    mutate();
  };

  const resetPageSearch = () => {
    const filterObj = { ...filters };
    delete filterObj.page;
    setFilters(filterObj);

    if (
      filterObj.agent_application_id?.length ||
      filterObj.service_id ||
      filterObj.tracking_no ||
      filterObj.external_tracking_no ||
      filterObj.shipper_order_id ||
      filterObj.upload_batch_no ||
      filterObj.shipping_batch_no ||
      filterObj.mawb_no
    ) {
      setShowErrorMessage(false);
      mutate();
    } else {
      setShowErrorMessage(true);
    }
  };

  const handleChangeFilter = (f, v) => {
    if (!!v) {
      setFilters({
        ...filters,
        [f]: v,
      });
    } else {
      const { [f]: fieldname, ...rest } = filters;
      setFilters({ ...rest });
    }
  };

  const handleChangeFiltersValues = (values) => {
    const [key] = Object.entries(values)[0];
    if (!!key) {
      setFilters({ ...filters, ...values });
    } else {
      const { [key]: f, ...rest } = filters;
      setFilters({ ...rest });
    }
  };
  const removeFilterField = (fieldName) => {
    const { [fieldName]: f, ...rest } = filters;
    setFilters({ ...rest });
  };

  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns
  );
  const selectColumn = (columnName) => {
    setSelectedColumns([...selectedColumns, columnName]);
  };
  const unselectColumn = (columnName) => {
    setSelectedColumns(selectedColumns.filter((c) => c !== columnName));
  };

  useEffect(
    function syncBrowserUrl() {
      const _url = `${window.location.pathname}?${queryString.stringify(
        filters
      )}`;
      if (_url.length >= 2000) {
        return;
      }

      const parsedFilter = {
        ...filters,
      };
      if (
        parsedFilter.agent_application_id &&
        Array.isArray(parsedFilter.agent_application_id) &&
        parsedFilter.agent_application_id.length > 0
      ) {
        parsedFilter.agent_application_id =
          parsedFilter.agent_application_id.map(
            (app) => app.agent_application_id
          );
      }

      const stringified = queryString.stringify(parsedFilter);
      const url = `${window.location.pathname}?${stringified}`;
      window.history.replaceState({}, "", url);
    },
    [filters]
  );

  useEffect(() => {
    if (
      filters.agent_application_id &&
      agentApplications &&
      agentApplications.length > 0
    ) {
      if (Array.isArray(filters.agent_application_id)) {
        filters.agent_application_id = agentApplications.filter((app) =>
          filters.agent_application_id.includes(
            app.agent_application_id.toString()
          )
        );
      } else {
        filters.agent_application_id = agentApplications.filter(
          (app) => filters.agent_application_id === app.agent_application_id
        );
      }
      setFilters({
        ...filters,
        date_from: moment().subtract(1, "Y").format("YYYY-MM-DD"),
      });
    }
  }, [agentApplications]);

  const value = {
    filters,
    handleChangeFilter,
    handleChangeFiltersValues,
    orderToCancel,
    setOrderToCancel,
    ordersToResolve,
    setOrdersToResolve,
    ordersToEscalate,
    setOrdersToEscalate,
    selectedOrders,
    setSelectedOrders,
    selectedColumns,
    selectColumn,
    unselectColumn,
    removeFilterField,
    onSearchHandler,
    ordersResponse,
    ordersFetching,
    agentApplications,
    agentApplicationsLoading,
    resetPageSearch,
    showErrorMessage,
  };
  return (
    <ViewOrdersContext.Provider value={value}>
      {children}
    </ViewOrdersContext.Provider>
  );
}
