import React, { useContext } from 'react'
import { getTrackerUrl } from '../utils'
import classNames from 'classnames'
import FeatureRequired from '../../../common/auth/FeatureRequired'
import { CANCELABLE_ORDER_STATUS } from '../constants'
import { ViewOrdersContext } from '../ViewOrders.context'

const View = ({ order }) => {
  const url = `/agent/view-orders/${order.order_id}`
  return (
    <a className="btn btn-primary ml-1 mr-1" href={url} target="_blank" rel="noopener noreferrer">View</a>
  )
}

const Track = ({ order }) => {
  const url = getTrackerUrl(order.tracking_no)
  return (
    <a className="btn btn-success mr-1" href={url} target="_blank" rel="noopener noreferrer">Track</a>
  )
}

const Print = ({ order }) => {
  const url = order.order_label_url
  return (
    <a className={
      classNames('btn mr-1', {
        'btn-info': !!url,
        'btn-secondary': !url
      })
      }
      href={url}
      target="_blank" rel="noopener noreferrer"
      disabled={!url}>Print</a>
  )
}

const Cancel = ({ order }) => {
  const { setOrderToCancel } = useContext(ViewOrdersContext)
  const { tracker_status_code } = order

  const cancelable = tracker_status_code === CANCELABLE_ORDER_STATUS
  const handleClick = () => {
    if (cancelable) {
      setOrderToCancel(order)
    }
  }

  return (
    <FeatureRequired feature="order.delete" disable>
      <button type="button"
        className={
          classNames('btn mr-1', {
            'btn-secondary': !cancelable,
            'btn-warning': !!cancelable
        })}
        disabled={!cancelable}
        onClick={handleClick}
        >Cancel</button>
    </FeatureRequired>
  )
}

const Resolve = ({ order }) => {
  const { setOrdersToResolve } = useContext(ViewOrdersContext)
  const isResolveBtnEnabled = !!order.order_exceptions.length
  return (
    <button type="button"
      className={classNames("btn mr-1", {
        "btn-secondary disabled": !isResolveBtnEnabled
      })}
      style={isResolveBtnEnabled
      ? {color: "#fff", backgroundColor: "#F1772D" }: {}}
      disabled={!isResolveBtnEnabled}
      onClick={() => setOrdersToResolve([order])}>Resolve</button>
  )
}

const Escalate = ({ order }) => {
  const { setOrdersToEscalate } = useContext(ViewOrdersContext)
  const isEscalateBtnEnabled = order.is_escalatable
  return (
    <button type="button"
      className={classNames("btn mr-1", {
        "btn-secondary disabled": !isEscalateBtnEnabled
      })}
      style={isEscalateBtnEnabled
      ? {color: "#fff", backgroundColor: "#9E52FE"}: {}}
      disabled={!isEscalateBtnEnabled}
      onClick={() => setOrdersToEscalate([order])}
      >
      Escalate
    </button>
  )
}

const SingleOrderAction = React.memo(({ order }) => {
  return (
    <div>
      {order.is_processing
      ? (
        <>
        <View order={order} />
        <Cancel order={order} />
        <span className="ml-2">Processing, please wait and refresh.</span>
        </>
      )
      : (
        <>
        <View order={order} />
        <Track order={order} />
        <Print order={order} />
        <Resolve order={order} />
        <Escalate order={order} />
        <Cancel order={order} />
        </>
      )
      }
    </div>
  )
})

export default SingleOrderAction
