import React, { useContext } from "react"
import { Button } from "antd"
// import { useFormik } from "formik"
// import { useQuery } from "react-query"
// import * as yup from "yup"

// import { ResolveOrderExceptionAPI } from "../api"
import { OrderEscalationContext } from "../OrderEscalation.context"
import { ESCALATION_TYPES, steps } from "../constants"

// const { Option } = Select

const ChooseResolutionMethod = () => {
  const {
    handleCloseModal,
    setCurrentStep,
    selectedEscalationType
  } = useContext(OrderEscalationContext)

  const handleNext = () => {
    setCurrentStep(steps.leaveNote)
  }

  let title, body
  if (selectedEscalationType === ESCALATION_TYPES.LASTMILE) {
    title = 'Escalate order to merchant for resolution of last mile exception'
    body = `When this order is escalated, an email will be automatically sent to the merchant at the end of the day asking them to provide us with the next instructions for a consolidated list of tracking numbers.
    If these orders are still unresolved after 14 calendar days, a follow up email will be automatically sent.
    If these orders are still unresolved after another 7 calendar days, the system will automatically trigger RTV.
    `
  } else if (selectedEscalationType === ESCALATION_TYPES.RTS_FIRSTMILE) {
    title = 'Escalate order to merchant for resolution to RTS First Mile Exception'
    body = `When this order is escalated, an email will be automatically sent to the merchant at the end of the day asking them to provide us with the next instructions for a consolidated list of tracking numbers. If these orders are still unresolved after 14 calendar days, a follow up email will be automatically sent. If these orders are still unresolved after another 7 calendar days, the system will automatically cancel the order.`
  }

  return (
    <div>
      <h6 className="font-weight bold">{title}</h6>
      <div className="mt-4">
        <p>{body}</p>
        <p>Would you like to continue?</p>
      </div>
      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()}>Cancel</Button>
          <Button type="primary" onClick={handleNext}>Next</Button>
        </div>
      </div>
    </div>
  )
}

export default ChooseResolutionMethod