import React, { useContext, useState } from 'react'
import { Modal, message } from 'antd'
import { ViewOrdersContext } from '../ViewOrders.context'
import ResolveOrderException from '../../resolveOrderException/ResolveOrderException'
import OrderEscalation from '../../orderEscalation/OrderEscalation'
import { cancelOrder } from '../api'

const CancelModal = () => {
  const { orderToCancel, setOrderToCancel } = useContext(ViewOrdersContext)
  const [cancelling, setCancelling] = useState(false)

  const handleCancel = async () => {
    const { tracking_no } = orderToCancel
    const payload = {
      tracking_nos: [tracking_no]
    }
    setCancelling(true)
    const result = await cancelOrder(payload)
    if (!result.isError) {
      const defaultMsg = `Order: ${tracking_no} cancelled`
      message.success(result.data?.detail || defaultMsg)
      // TODO: remove order from table
    } else {
      message.error(JSON.stringify(result.error).slice(1,-1), 4)
    }
    setCancelling(false)
    setOrderToCancel(null)
  }

  return (
    <Modal
      visible={!!orderToCancel}
      maskClosable
      onCancel={() => setOrderToCancel(null)}
      onOk={() => {
        handleCancel()
      }}
      okButtonProps={{
        disabled: cancelling,
        loading: cancelling
      }}
      cancelButtonProps={{
        disabled: cancelling
      }}
      >
      <p>Do you want to cancel this order ?</p>
    </Modal>
  )
}

const ResolveOrderModal = () => {
  const { ordersToResolve, setOrdersToResolve } = useContext(ViewOrdersContext)
  return (
    <Modal
      visible={!!ordersToResolve && !!ordersToResolve.length}
      afterClose={() => setOrdersToResolve([])}
      footer={null} centered={true} maskClosable={false}
      onCancel={() => setOrdersToResolve([])}
      destroyOnClose={true}>
      <ResolveOrderException
        orderData={ordersToResolve}
        handleCloseModal={() => setOrdersToResolve([])} />
    </Modal>
  )
}

const EscalateOrderModal = () => {
  const { ordersToEscalate, setOrdersToEscalate } = useContext(ViewOrdersContext)
  return (
    <Modal
      visible={!!ordersToEscalate && !!ordersToEscalate.length}
      afterClose={() => setOrdersToEscalate([])}
      footer={null} centered={true} maskClosable={false}
      onCancel={() => setOrdersToEscalate([])}
      destroyOnClose={true}>
      <OrderEscalation
        orderData={ordersToEscalate}
        handleCloseModal={() => setOrdersToEscalate([])} />
    </Modal>
  )
}

function Modals() {
  return (
    <div>
      <CancelModal />
      <ResolveOrderModal />
      <EscalateOrderModal />
    </div>
  )
}

export default Modals
