import React from 'react'

const MPSIcon = ({ orderType }) => {
  const src = orderType === 'MPS'
  ? 'https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/mps_parent.png'
  : 'https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/mps_child.png'

  return (
    <img style={{
      height: 24,
      width: 24,
      verticalAlign: 'middle',
      marginRight: 2,
    }} src={src} alt={orderType} />
  )
}

export default MPSIcon
