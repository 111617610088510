import {
  ADD_NEW_POSTAL_CODE,
  ADD_NEW_POSTAL_CODE_ERROR,
  ADD_NEW_POSTAL_CODE_LOADING,
  FETCH_NETWORk_PARTNER_POSTAL_CODES,
  FETCH_NETWORk_PARTNER_POSTAL_CODES_ERROR,
  FETCH_NETWORk_PARTNER_POSTAL_CODES_LOADING,
  FETCH_NETWORk_PARTNER_SERVICES,
  FETCH_NETWORk_PARTNER_SERVICES_ERROR,
  FETCH_NETWORk_PARTNER_SERVICES_LOADING,
  NETWORK_PARTNER_REASSIGN_ERROR,
  NETWORK_PARTNER_REASSIGN_SUCCESS,
  RESET_NETWORK_PARTNER_REASSIGN_STATE,
  RESET_ADD_POSTAL_CODE_STATE,
} from "./postalCodeAllocations.action";

const initialState = {
  networkPartnerServicesLoading: false,
  networkPartnerServices: null,
  networkPartnerPostalCodes: null,
  networkPartnerPostalCodesLoading: false,
  networkPartnerPostalCodesError: false,
  networkPartnerReassignError: false,
  networkPartnerReassignSuccess: false,
  addingPostalCodeSuccess: false,
  addingPostalCode: false,
  addingPostalCodeError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NETWORk_PARTNER_SERVICES:
      return { ...state, networkPartnerServices: action.payload, networkPartnerServicesLoading: false };
    case FETCH_NETWORk_PARTNER_SERVICES_LOADING:
      return { ...state, networkPartnerServices: null, networkPartnerServicesLoading: true };
    case FETCH_NETWORk_PARTNER_SERVICES_ERROR:
      return { ...state, networkPartnerServices: null, networkPartnerServicesLoading: false };
    case FETCH_NETWORk_PARTNER_POSTAL_CODES:
      return { ...state, networkPartnerPostalCodes: action.payload, networkPartnerPostalCodesLoading: false, networkPartnerPostalCodesError: false };
    case FETCH_NETWORk_PARTNER_POSTAL_CODES_ERROR:
      return { ...state, networkPartnerPostalCodes: null, networkPartnerPostalCodesLoading: false, networkPartnerPostalCodesError: true };
    case FETCH_NETWORk_PARTNER_POSTAL_CODES_LOADING:
      return { ...state, networkPartnerPostalCodes: null, networkPartnerPostalCodesLoading: true, networkPartnerPostalCodesError: false };
    case ADD_NEW_POSTAL_CODE_LOADING:
      return { ...state, addingPostalCode: true, addingPostalCodeError: null, addingPostalCodeSuccess: false };
    case ADD_NEW_POSTAL_CODE:
      return { ...state, addingPostalCode: false, addingPostalCodeError: null, addingPostalCodeSuccess: true };
    case ADD_NEW_POSTAL_CODE_ERROR:
      return { ...state, addingPostalCode: false, addingPostalCodeError: action.payload, addingPostalCodeSuccess: false };
    case NETWORK_PARTNER_REASSIGN_ERROR:
      return { ...state, networkPartnerReassignError: true, networkPartnerReassignSuccess: false };
    case NETWORK_PARTNER_REASSIGN_SUCCESS:
      return { ...state, networkPartnerReassignError: false, networkPartnerReassignSuccess: true };
    case RESET_NETWORK_PARTNER_REASSIGN_STATE:
      return { ...state, networkPartnerReassignError: false, networkPartnerReassignSuccess: false };
    case RESET_ADD_POSTAL_CODE_STATE:
      return { ...state, addingPostalCode: false, addingPostalCodeError: null, addingPostalCodeSuccess: false };
    default:
      return state;
  }
}
