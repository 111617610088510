import axios from "axios";

export const FETCH_ZONE_LIST = "fetch_zone_list";
export const FETCH_ZONE_LOCATIONS = "fetch_zone_locations";
export const FETCH_ZONE_LIST_LOADING = "fetch_zone_list_loading";
export const FETCH_ZONE_LOCATIONS_LOADING = "fetch_zone_locations_loading";
export const FETCH_ZONE_LIST_ERROR = "fetch_zone_list_error";
export const FETCH_ZONE_LOCATIONS_ERROR = "fetch_zone_locations_error";

export function fetchZoneList(params) {
  return async function (dispatch) {
    try {
      dispatch({
        type: FETCH_ZONE_LIST_LOADING,
      });
      const res = await axios.get("/api/location/zones/", { params });
      dispatch({
        type: FETCH_ZONE_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ZONE_LIST_ERROR,
      });
      console.log(err);
    }
  };
}

export function fetchZoneLocations(params) {
  return async function (dispatch) {
    try {
      dispatch({
        type: FETCH_ZONE_LOCATIONS_LOADING,
      });
      const res = await axios.get("/api/location/zone-locations/", { params });
      dispatch({
        type: FETCH_ZONE_LOCATIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ZONE_LOCATIONS_ERROR,
      });
      console.log(err);
    }
  };
}
