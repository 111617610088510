import axios from "axios";
import { useQuery } from "react-query";

export const statesAPI = (countries, additionalParams) => ({
  getstates: async () => {
    let params = {
      countries,
      ...additionalParams,
    };
    const response = await axios.get("/api/location/states/", {
      params,
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  },
});

const useStates = (country, additionalParams = {}) => {
  const { data: states, isLoading } = useQuery(["states", country], statesAPI(country, additionalParams).getstates, {
    staleTime: Infinity,
    refetchInterval: false,
  });
  return {
    states: states || [],
    isLoading,
  };
};

export default useStates;
