import { queryCache } from 'react-query'

export const invalidateQueryException = (orderId, exceptionId) => {
  const key = ['order-exceptions', orderId]
  const previousExceptions = queryCache.getQueryData(key)
  const updatedExceptions = previousExceptions.filter(
    exception => parseInt(exception.id) !== parseInt(exceptionId)
  )
  queryCache.setQueryData(key, updatedExceptions)
}
