import {
    FETCH_PARTNER_REMITTANCE_BY_FILTER,
    FETCH_MERCHANT_REMITTANCE_BY_FILTER,
    CLEAR_COD_MANAGEMENT_ERRORS,
    UPLOAD_COD_REMITTANCE,
    FETCH_ALL_PARTNERS,
    FETCH_PARTNER_DUES_BY_FILTER,
    FETCH_MERCHANT_DUES_BY_FILTER,
    CLEAR_PARTNER_REMITTANCE,
    CLEAR_MERCHANT_REMITTANCE,
    CLEAR_PARTNER_DUES,
    CLEAR_MERCHANT_DUES
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
    switch (action.type) {
        case FETCH_PARTNER_DUES_BY_FILTER:
            return {
                ...state,
                partnerDuesData: action.payload.results,
                totalPartnerDuesData: action.payload.count,
                totalPages: Math.ceil(action.payload.count / 50),
                lastUpdated: moment()
            }
        case FETCH_MERCHANT_DUES_BY_FILTER:
            return {
                ...state,
                merchantDuesData: action.payload.results,
                totalMerchantDuesData: action.payload.count,
                totalPages: Math.ceil(action.payload.count / 50),
                lastUpdated: moment()
            }
        case FETCH_PARTNER_REMITTANCE_BY_FILTER:
            return {
                ...state,
                partnerRemittanceData: action.payload.results,
                totalPartnerRemittanceData: action.payload.count,
                totalPages: Math.ceil(action.payload.count / 50),
                lastUpdated: moment()
            }
        case FETCH_MERCHANT_REMITTANCE_BY_FILTER:
            return {
                ...state,
                merchantRemittanceData: action.payload.results,
                totalMerchantRemittanceData: action.payload.count,
                totalPages: Math.ceil(action.payload.count / 50),
                lastUpdated: moment()
            }
        case UPLOAD_COD_REMITTANCE:
            return {
                ...state,
                error: action.payload,
                errorData: action.errorData,
                lastUpdated: moment()
            };
        case FETCH_ALL_PARTNERS:
            return { ...state, partners: action.payload };
        case CLEAR_COD_MANAGEMENT_ERRORS:
            return { ...state, error: null, errorData: null, message: null };
        case CLEAR_PARTNER_DUES:
            return {...state, partnerDuesData: undefined}
        case CLEAR_MERCHANT_DUES:
            return {...state, merchantDuesData: undefined}
        case CLEAR_PARTNER_REMITTANCE:
            return {...state, partnerRemittanceData: undefined}
        case CLEAR_MERCHANT_REMITTANCE:
            return {...state, merchantRemittanceData: undefined}
        default:
            return state;
    }
}
