import { useState } from 'react'
import axios from 'axios'
import { useQuery } from "react-query"

const URL = '/api/order/service/'

const useServices = () => {
  const [services, setServices] = useState([])
  const token = localStorage.getItem('token')

  const {isLoading} = useQuery(
    "services",
    async () => {
      const r = await axios.get(URL, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      return r.data
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      onSuccess: (data) => {
        setServices(data)
      },
      onError: (err) => {
        console.log(err)
      }
    }
  )

  return [services, isLoading]
}

export default useServices