import {
  FETCH_ORDER_EXCEPTIONS,
} from '../../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_ORDER_EXCEPTIONS:
      return { ...state, exceptions: action.payload };
    default:
      return state;
  }
}
