import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_SIGNED_IN_USER,
  EDIT_SIGNED_IN_USER,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  PASSWORD_SENT,
  CLEAR_AUTH_ERRORS,
  FETCH_USER_FEATURES
} from '../../actions/types';
import axios from 'axios';
import { createObjPath } from '../../utils/ObjectUtils';

export default function(state = {}, action) {
  switch(action.type) {
    case AUTH_USER:
        axios.interceptors.request.use(
          config => {
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['Content-Type'] = 'application/json';
            const defaultToken = localStorage.getItem('token');
            if (defaultToken) {
              config.headers['Authorization'] = 'Token ' + defaultToken;
            }
            return config;
          },
          error => Promise.reject(error)
        )
      return { ...state, error: null, authenticated: true };
    case UNAUTH_USER:
      return { authenticated: false };
    case AUTH_ERROR:
      return { ...state, error: true };
    case FETCH_SIGNED_IN_USER:
      return { ...state, user: action.payload };
    case EDIT_SIGNED_IN_USER:
      return { ...state, error: action.payload };
    case CHANGE_PASSWORD:
      return { ...state, error: action.payload, errorData: action.errorData };
    case RESET_PASSWORD:
      return { ...state, error: action.payload };
    case PASSWORD_SENT:
      return { ...state, error: action.payload };
    case CLEAR_AUTH_ERRORS:
      return { ...state, error: null };
    case FETCH_USER_FEATURES:
      const featuresObj = {}
      if (action.payload && action.payload.length) {
        for (let p of action.payload) {
          createObjPath(p.name, 1, featuresObj)
        }
      }
      return { ...state, features: action.payload, featuresObj };
    default:
      return state;
  }
}
