import moment from 'moment'
import { cleanTrackingNumbers } from '../../../utils'
import { DEFAULT_PAGE_SIZE } from './constants'

// field name: default value
const fieldsValueToRemove = {
  page: 1,
  page_size: DEFAULT_PAGE_SIZE,
  date_to: moment().endOf('day').format('YYYY-MM-DD'),
  order_type: "both",
  is_processing: "both",
}

const transformValueToQuery = {
  agent_application_id: values => {
    return values.join(',')
  }
}

export function filtersToQuery(filters) {
  const f = {}
  for (let [k, v] of Object.entries(filters)) {
    if (!!v) {
      if (Object.keys(fieldsValueToRemove).includes(k) && v === fieldsValueToRemove[k] ) {
        delete f[k]
      } if (transformValueToQuery[k]) {
        f[k] = transformValueToQuery[k](v)
      } else {
        f[k] = v
      }
    }
  }
  return f
}

const parseQueryFunctions = {
  date_from: value => value
    ? moment(value).startOf('day').format('YYYY-MM-DD')
    : null,

  date_to: value => value
  ? moment(value).endOf('day').format('YYYY-MM-DD')
  : moment().endOf('day').format('YYYY-MM-DD'),
  
  pickup_date_from: value => value
    ? moment(value).startOf('day').format('YYYY-MM-DD')
    : null,
  
  pickup_date_to: value => value
    ? moment(value).endOf('day').format('YYYY-MM-DD')
    : null,

  order_exceptions: value => {
    if (!!value) {
      if (value instanceof Array ) {
        return value
      }
      return [value]
    }
  },

  escalation_status: value => {
    if (!!value) {
      if (value instanceof Array ) {
        return value
      }
      return [value]
    }
  },
  service_id: value => value || "",
  agent_application_id: value => value || "",
  public_status: value => value || "",
  private_status: v => v || "",
  page: v => v || 1,
  page_size: v => v || DEFAULT_PAGE_SIZE,
  order_type: v => v || "both",
  is_processing: v => v || "both",
}
export function queryToFilters(query) {
  const f = {}
  for (let [k, v] of Object.entries(query)) {
    if (parseQueryFunctions.hasOwnProperty(k)) {
      f[k] = parseQueryFunctions[k](v)
    } else if (!!v) {
      f[k] = v
    }
  }
  return f
}

// const dateTimeFormat = 'YYYY-MM-DDThh:mmZ'
const dateTimeFormat = 'YYYY-MM-DD'
const requestParamFunctions = {
  date_to: v => moment(v).endOf('day').format(dateTimeFormat),
  date_from: v => moment(v).startOf('day').format(dateTimeFormat),
  pickup_date_from: v => moment(v).startOf('day').format(dateTimeFormat),
  pickup_date_to: v => moment(v).endOf('day').format(dateTimeFormat),
  agent_application_id: v => Array.isArray(v) ? v.join(',') : v
}
export function filtersToParams(filters) {
  const q = {}
  const fieldsToClean = [
    "tracking_no", "external_tracking_no", "shipper_order_id",
    "upload_batch_no", "shipping_batch_no", "mawb_no"
  ]
  const arrayFields = [
    'order_exceptions', 'escalation_status'
  ]
  for (let [k, v] of Object.entries(filters)) {
    if (!!v) {
      if (Object.keys(fieldsValueToRemove).includes(k) && v === fieldsValueToRemove[k] ) {
        delete q[k]
      } else if (k === "public_status") {
        const [status, version] = v.split('-')
        if (version === "v2") {
          q['public_status_v2_shipper'] = status
        } else {
          q[k] = status
        }
      } else if (fieldsToClean.includes(k)) {
        const cleanedValue = cleanTrackingNumbers(v).join(',')
        q[k] = cleanedValue
      } else if (arrayFields.includes(k)) {
        q[k] = v.join(',')
      } else {
        q[k] = v
      }

      if (requestParamFunctions[k] && q[k]) {
        const f = requestParamFunctions[k]
        q[k] = f(v)
      }
    }
  }
  
  if (!!q.hub_id && !!q.hub_group_id) {
    q['hms_hub_group_id'] = `${q.hub_id}#${q.hub_group_id}`
  }

  if (q.public_status_v2_shipper) {
    delete q.public_status
  }

  if (q.agent_application_id && q.agent_application_id_filter === 'filter_away') {
    q['exclude_agent_application_id'] = q.agent_application_id
    delete q.agent_application_id
  }

  return q
}

export function getTrackerUrl(trackingNo) {
  return process.env.REACT_APP_IS_PRODUCTION
   ? `https://tracker.janio.asia/${trackingNo}`
   : `http://tracker.staging.janio.asia/${trackingNo}`
}
