import { FETCH_ZONE_LIST, FETCH_ZONE_LIST_ERROR, FETCH_ZONE_LIST_LOADING, FETCH_ZONE_LOCATIONS, FETCH_ZONE_LOCATIONS_ERROR, FETCH_ZONE_LOCATIONS_LOADING } from "./zone.action";

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ZONE_LIST_LOADING:
      return { ...state, zonesLoading: true, zones: null };
    case FETCH_ZONE_LIST:
      return { ...state, zones: action.payload, zonesLoading: false };
    case FETCH_ZONE_LIST_ERROR:
      return { ...state, zonesLoading: false, zones: null };
    case FETCH_ZONE_LOCATIONS_LOADING:
      return { ...state, zoneLocationsLoading: true, zoneLocations: null };
    case FETCH_ZONE_LOCATIONS:
      return { ...state, zoneLocations: action.payload, zoneLocationsLoading: false };
    case FETCH_ZONE_LOCATIONS_ERROR:
      return { ...state, zoneLocationsLoading: false, zoneLocations: null };
    default:
      return state;
  }
}
