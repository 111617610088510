import { FETCH_NETWORk_PARTNERS, FETCH_SUBMISSION_METHOD } from "../actions/index";

const initialState = {
  networkPartners: null,
  submissionMethods: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NETWORk_PARTNERS:
      return { ...state, networkPartners: action.payload };
    case FETCH_SUBMISSION_METHOD:
      return { ...state, submissionMethods: action.payload };
    default:
      return { ...state };
  }
}
