import React, { useContext } from "react"
import { ResolveOrderExceptionContext } from "../ResolveOrderException.context"
import { Button } from "antd"
import { invalidateQueryException } from "../utils"

const resolution = {
  Redeliver: "redelivery",
  Dispose: "disposal"
}

const ResolutionSuccess = () => {
  const {
    collectedData,
    handleCloseModal,
    submitResponse,
    singleException
  } = useContext(ResolveOrderExceptionContext)

  const handleOK = () => {
    if (singleException) {
      setTimeout(() => {
        invalidateQueryException(singleException.order_id, singleException.id)
      }, 600)
    }
    handleCloseModal()
  }
  const resolvedCount = submitResponse?.resolved_orders || 0

  return (
    <div>
      <h6>Resolution is successful</h6>
      <div className="mt-4">
        {
          collectedData.resolutionType === "Others"
          ? <p className="mb-0">Exception for {resolvedCount} orders has been resolved.</p>
          : <p className="mb-0">A {resolution[collectedData.resolutionType] || collectedData.resolutionType} for {resolvedCount} orders has been arranged.</p>
        }
      </div>

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" type="primary" onClick={handleOK}>OK</Button>
        </div>
      </div>
    </div>
  )
}

export default ResolutionSuccess
