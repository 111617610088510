import React, { useContext } from "react"
import { Input, Button } from "antd"
import { OrderEscalationContext } from "../OrderEscalation.context"
import { useFormik } from "formik"
import * as yup from "yup"
import { steps } from "../constants"

const LeaveNote = () => {
  const {
    handleCloseModal,
    submitData,
    setCurrentStep,
  } = useContext(OrderEscalationContext)

  const initialValues = {
    reason: undefined
  }
  const validationSchema = yup.object().shape({
    reason: yup.string().nullable()
  })
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    const submitResponse = await submitData(values.reason)
    setSubmitting(false)

    if (!submitResponse.isError) {
      setCurrentStep(steps.success)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  
  return (
    <div>
      <h6 className="font-weight bold">Leave a note</h6>

      <form onSubmit={formik.handleSubmit}>
      <div className="mt-4">
        <Input.TextArea
          placeholder="(Optional) Leave a note to add more context" name="reason" onChange={formik.handleChange}
          disabled={formik.isSubmitting} />
        {
          formik.errors.reason &&
          <span className="text-danger">{formik.errors.reason}</span>
        }
      </div>

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()} disabled={formik.isSubmitting}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting? "Updating...":"Update"}
          </Button>
        </div>
      </div>
      </form>
    </div>
  )
}

export default LeaveNote