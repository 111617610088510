import {
  FETCH_NETWORK_PARTNER_LOADING,
  FETCH_NETWORK_PARTNER_SUCCESS,
  FETCH_NETWORK_PARTNER_FAIL,
} from '../../actions/types'

export default function(state={
  partners: [],
  isLoading: false,
  isError: false,
  error: null
}, action) {
  switch (action.type) {
    case FETCH_NETWORK_PARTNER_LOADING:
      return {
        ...state,
        isLoading: true, isError: false
      }
    case FETCH_NETWORK_PARTNER_SUCCESS:
      return {
        ...state, isLoading: false, isError: false,
        partners: action.payload
      }
    case FETCH_NETWORK_PARTNER_FAIL:
      return {
        ...state, isLoading: false, isError: true,
        error: action.payload
      }
    default:
      return state
  }
}