import React, { useContext } from "react"
import ResolveOrderExceptionContextProvider, { ResolveOrderExceptionContext } from './ResolveOrderException.context'
import { componentStep } from "./constants"


const ResolveOrderException = ({ orderData, handleCloseModal, singleException }) => {
  return (
    <ResolveOrderExceptionContextProvider
      orderData={orderData}
      handleCloseModal={handleCloseModal}
      singleException={singleException}>
      <ResolveStep />
    </ResolveOrderExceptionContextProvider>
  )
}

const ResolveStep = () => {
  const {
    currentStep
  } = useContext(ResolveOrderExceptionContext)
  const Component = componentStep[currentStep]
  return <Component />
}

export default ResolveOrderException
