import React, { useContext, useState } from "react";
import { Card, message } from "antd";
import FeatureRequired from "../../../common/auth/FeatureRequired";
import classNames from "classnames";
import { ViewOrdersContext } from "../ViewOrders.context";
import {
  downloadManifset,
  printOrdersLabel,
  resubmitToExternalService,
} from "../api";
import { getTrackerUrl } from "../utils";
import { bulkDownloadDocuments } from "../../customsDocuments/utils";
import RegenerateLabels from "../actionButtons/RegenerateLabels";

const ResubmitExternalService = ({ handleResult }) => {
  const { selectedOrders } = useContext(ViewOrdersContext);
  const [submitting, setSubmitting] = useState(false);

  const handleResubmitToExternalService = async () => {
    setSubmitting(true);
    handleResult({});

    const result = await resubmitToExternalService(selectedOrders);
    if (!result.isError) {
      handleResult({
        status: "success",
        message: "Order(s) successfully submitted to external services.",
      });
    } else {
      handleResult({
        status: "danger",
        message: "Something went wrong",
      });
    }
    setSubmitting(false);
  };

  return (
    <FeatureRequired feature="order.submit_to_external" disable>
      <button
        onClick={() => handleResubmitToExternalService()}
        type="button"
        className={classNames("mb-1 w-100 btn", {
          "btn-success": !!selectedOrders.length,
          "btn-secondary": !selectedOrders.length,
        })}
        disabled={!selectedOrders.length || submitting}
      >
        Re-submit to External Service
      </button>
    </FeatureRequired>
  );
};

const Print = ({ handleResult }) => {
  const { selectedOrders } = useContext(ViewOrdersContext);
  const [loading, setLoading] = useState(false);

  const handlePrint = async () => {
    setLoading(true);
    handleResult({});

    const result = { has_olu: true, olus: [], tracking_nos: [] };
    selectedOrders.forEach((order) => {
      if (!order.order_label_url) {
        result.has_olu = false;
      }
      result.olus.push(order.order_label_url);
      result.tracking_nos.push(order.tracking_no);
    });
    if (!result.has_olu) {
      setLoading(false);
      handleResult({});
    }

    const r = await printOrdersLabel({ olus: result.olus });
    if (r.isSuccess) {
      const { pdfUrl } = r;
      window.open(pdfUrl);
    } else {
      handleResult({
        status: "danger",
        message: r.message,
      });
    }

    setLoading(false);
  };

  return (
    <button
      onClick={() => handlePrint()}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-success": !!selectedOrders.length,
        "btn-secondary": !selectedOrders.length,
      })}
      disabled={!selectedOrders.length || loading}
    >
      {loading
        ? "printing selected orders..."
        : "Print selected orders stickers and commercial invoice"}
    </button>
  );
};

const PrintSorted = ({ handleResult }) => {
  const { selectedOrders } = useContext(ViewOrdersContext);
  const [loading, setLoading] = useState(false);

  const handlePrint = async () => {
    setLoading(true);
    handleResult({});

    const result = { has_olu: true, olus: [], tracking_nos: [] };
    selectedOrders.forEach((order) => {
      if (!order.order_label_url) {
        result.has_olu = false;
      }
      result.olus.push(order.order_label_url);
      result.tracking_nos.push(order.tracking_no);
    });
    if (!result.has_olu) {
      setLoading(false);
      handleResult({});
    }

    const r = await printOrdersLabel({ olus: result.olus }, true);
    if (r.isSuccess) {
      const { pdfUrl } = r;
      window.open(pdfUrl);
    } else {
      handleResult({
        status: "danger",
        message: r.message,
      });
    }

    setLoading(false);
  };

  return (
    <button
      onClick={() => handlePrint()}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-success": !!selectedOrders.length,
        "btn-secondary": !selectedOrders.length,
      })}
      disabled={!selectedOrders.length || loading}
    >
      {loading
        ? "printing selected orders..."
        : "Print labels sorted by consignee"}
    </button>
  );
};

const Track = () => {
  const { selectedOrders } = useContext(ViewOrdersContext);

  const handleClick = () => {
    const trackingNos = selectedOrders
      .map((order) => order.tracking_no)
      .join(",");
    const trackerUrl = getTrackerUrl(trackingNos);
    window.open(trackerUrl);
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!selectedOrders.length}
    >
      Track Selected Orders
    </button>
  );
};

const DownloadCustomsDocument = ({ handleResult }) => {
  const { selectedOrders } = useContext(ViewOrdersContext);
  const [loading, setLoading] = useState(false);

  const handleBulkDownload = async () => {
    setLoading(true);
    handleResult({});

    const trackingNumbers = selectedOrders.map((order) => {
      return order.tracking_no;
    });
    const response = await bulkDownloadDocuments(trackingNumbers);
    setLoading(false);

    if (response.isError) {
      handleResult({
        status: "danger",
        message: "Download customs document error",
      });
    }
  };

  return (
    <button
      onClick={handleBulkDownload}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!selectedOrders.length || loading}
    >
      {loading
        ? "Downloading customs documents..."
        : "Download Consignee Customs Document"}
    </button>
  );
};

const DownloadDeliveryManifset = ({ handleResult }) => {
  const { selectedOrders, filters } = useContext(ViewOrdersContext);

  const enabled =
    !!filters.hub_id &&
    !!filters.hub_group_id &&
    !!filters.hms_scan_from &&
    !!filters.hms_scan_to &&
    !!selectedOrders.length;

  const handleDownloadManifest = async () => {
    message.info("Generating manifest, please wait...");
    const params = {
      hms_scan_from: filters.hms_scan_from,
      hms_scan_to: filters.hms_scan_to,
      hms_hub_group_id: filters.hms_hub_group_id,
    };
    const result = await downloadManifset(params);
    if (result.isSuccess) {
      const link = document.createElement("a");
      link.href = result.url;
      link.setAttribute("download", "manifest.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      message.error("Error downloading manifest");
    }
  };

  return (
    <button
      onClick={handleDownloadManifest}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!enabled || !selectedOrders.length}
    >
      Download Delivery Manifest
    </button>
  );
};

const EscalateOrders = () => {
  const { selectedOrders, setOrdersToEscalate } = useContext(ViewOrdersContext);
  return (
    <button
      onClick={() => {
        setOrdersToEscalate(selectedOrders);
      }}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!selectedOrders.length}
    >
      Escalate Orders
    </button>
  );
};

const ResolveExceptions = () => {
  const { selectedOrders, setOrdersToResolve } = useContext(ViewOrdersContext);
  return (
    <button
      onClick={() => {
        setOrdersToResolve(selectedOrders);
      }}
      type="button"
      className={classNames("mb-1 w-100 btn", {
        "btn-secondary": !selectedOrders.length,
        "btn-success": !!selectedOrders.length,
      })}
      disabled={!selectedOrders.length}
    >
      Resolve Exceptions
    </button>
  );
};

function SelectedOrdersActions() {
  const [message, setMessage] = useState();
  const [status, setStatus] = useState(false);

  const handleResult = ({ status, message }) => {
    setMessage(message);
    setStatus(status);
  };

  return (
    <div className="mt-5">
      {message && status && (
        <div className={`alert alert-${status}`} role="alert">
          {message}
        </div>
      )}
      <Card className="mt-2">
        <h5>Other Actions</h5>
        <div className="mt-3">
          <ResubmitExternalService handleResult={handleResult} />
          <Print handleResult={handleResult} />
          <Track />
          <PrintSorted handleResult={handleResult} />
          <DownloadCustomsDocument handleResult={handleResult} />
          <DownloadDeliveryManifset />
          <RegenerateLabels selectedOrders={[]} />
          <EscalateOrders />
          <ResolveExceptions />
        </div>
      </Card>
    </div>
  );
}

export default SelectedOrdersActions;
