import React, { useContext } from "react";
import { Input } from "antd";
import { ViewOrdersContext } from "../../ViewOrders.context";

function OrderNumberFilter() {
  const { filters, handleChangeFiltersValues, onSearchHandler } = useContext(ViewOrdersContext);

  const handleTextChange = (field, value) => {
    handleChangeFiltersValues({ [field]: value });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      onSearchHandler();
    }
  };

  const inputFields1 = [
    {
      title: "Tracking No",
      name: "tracking_no",
      placeholder: "e.g. ABC123,DEF456",
    },
    {
      title: "External Tracking No",
      name: "external_tracking_no",
    },
  ];
  const inputField2 = [
    {
      title: "Shipper Order Id",
      name: "shipper_order_id",
    },
    {
      title: "Upload Batch No",
      name: "upload_batch_no",
    },
    {
      title: "Shipping Batch No",
      name: "shipping_batch_no",
    },
    {
      title: "MAWB No(s)",
      name: "mawb_no",
      placeholder: "e.g. ABC123,DEF456",
    },
  ];

  return (
    <div className="mt-3">
      <div className="row">
        {inputFields1.map((inputField, i) => {
          return (
            <div className="col-md-6" key={`inputfield1-${i}`}>
              <div>
                <p className="mb-0">{inputField.title}</p>
                <Input
                  placeholder={inputField.placeholder}
                  defaultValue={filters[inputField.name]}
                  onChange={(e) => handleTextChange(inputField.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                  allowClear
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="row mt-2">
        {inputField2.map((inputField, i) => {
          return (
            <div className="col-md-3" key={`inputfield2-${i}`}>
              <div>
                <p className="mb-0">{inputField.title}</p>
                <Input placeholder={inputField.placeholder} defaultValue={filters[inputField.name]} onChange={(e) => handleTextChange(inputField.name, e.target.value)} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OrderNumberFilter;
