import { useState } from "react";
import { filtersToParams } from "../utils";
import axios from "axios";
import { useMutation } from "react-query";
import queryString from "query-string";

const URL = "/api/order/order-ops/";

const useOrders = (filters = {}) => {
  const paramsObj = { ...filters };
  if (
    paramsObj.agent_application_id &&
    paramsObj.agent_application_id.length > 0 &&
    Array.isArray(paramsObj.agent_application_id)
  ) {
    paramsObj.agent_application_id = paramsObj.agent_application_id.map(
      (app) => app.agent_application_id
    );
  }
  const params = filtersToParams(paramsObj);
  const token = localStorage.getItem("token");
  const [data, setData] = useState({});
  // const [loading, setLoading] = useState(false);

  const [mutate, { isLoading }] = useMutation(
    () => {
      const _url = `${window.location.pathname}?${queryString.stringify(
        filters
      )}`;

      const headers = {
        Authorization: `Token ${token}`,
      }
      if (_url.length >= 2000) {
        return axios.post(URL, {
          ...filters,
        }, {
          headers
        })
      }
      return axios.get(URL, {
        headers,
        params,
      });
    },
    {
      onSuccess: (response) => {
        setData(response.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // const fetchOrders = async () => {
  //   setLoading(true);
  //   try {
  //     const r = await axios.get(URL, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //       params,
  //     });
  //     setData(r.data);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setLoading(false);
  // };

  return [mutate, data, isLoading];
};

export default useOrders;
