import { useState, useEffect } from 'react'
import { useQuery } from "react-query"
import axios from 'axios'

export const useCountries = () => {
  const url = `/api/location/countries/`
  const { data, isLoading: loading } = useQuery("countries", async () => {
    const response = await axios.get(url)
    return response.data
  }, {
    refetchInterval: false,
    staleTime: Infinity
  })
  return { loading, data }
}

export const useCitiesFileterd = (countries) => {
  const url = `/api/location/states/`
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const r = await axios.get(url, { params: {countries: countries, include_cities: true}})
      setLoading(false)
      setData(r.data)
    }
    if (countries) {
      fetchData()
    }
  }, [countries, url])

  return {loading, data}
}