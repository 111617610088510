import {
  FETCH_ALL_COUNTRIES,
  FETCH_ALL_CURRENCIES,
  FETCH_AGENT_STATUSES,
  FETCH_TRACKER_STATUSES,
  FETCH_PRIVATE_TRACKER_STATUSES,
  FETCH_FIRSTMILE_STATUSES,
  FETCH_WAREHOUSE_STATUSES,
  FETCH_PORTS,
  FETCH_SLA_DESTINATIONS,
  FETCH_SLA_NPS
} from '../../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_COUNTRIES:
      return { ...state, countries: action.payload };
    case FETCH_ALL_CURRENCIES:
      return { ...state, currencies: action.payload };
    case FETCH_AGENT_STATUSES:
      return { ...state, agentStatuses: action.payload };
    case FETCH_TRACKER_STATUSES:
      return { ...state, publicStatuses: action.payload };
      case FETCH_PRIVATE_TRACKER_STATUSES:
        return { ...state, privateStatuses: action.payload };
    case FETCH_FIRSTMILE_STATUSES:
      return { ...state, firstmileStatuses: action.payload };
    case FETCH_WAREHOUSE_STATUSES:
      return { ...state, warehouseStatuses: action.payload };
    case FETCH_PORTS:
      return { ...state, ports: action.payload };
    case FETCH_SLA_DESTINATIONS:
      return { ...state,slaDestinations: action.payload };
    case FETCH_SLA_NPS:
      return { ...state,slaNPs: action.payload };
    default:
      return state;
  }
}
