import axios from "axios";

export const FETCH_NETWORk_PARTNERS = "fetch_networK_partners";
export const FETCH_SUBMISSION_METHOD = "fetch_submission_method";

export function fetchNetworkPartners(params) {
  return async function (dispatch) {
    try {
      const res = await axios.get("/api/partner/partner/", { params });
      dispatch({
        type: FETCH_NETWORk_PARTNERS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
}

export function fetchSubmissionMethod(params) {
  return async function (dispatch) {
    try {
      const res = await axios.get("/api/order/submission-methods/", { params });
      dispatch({
        type: FETCH_SUBMISSION_METHOD,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
}
