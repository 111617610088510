import React from 'react'
import { ClipLoader } from 'react-spinners'

const Loading = () => (
  <div className="mt-5" style={{ textAlign: 'center' }}>
    <div style={{ display: 'inline-block' }}>
      <ClipLoader
        color={'#000028'}
        loading={true}
      />
    </div>
  </div>
)

export default Loading