import axios from "axios";

export const FETCH_NETWORk_PARTNER_SERVICES_LOADING = "fetch_networK_partner_services_loading";
export const FETCH_NETWORk_PARTNER_SERVICES = "fetch_networK_partner_services";
export const FETCH_NETWORk_PARTNER_SERVICES_ERROR = "fetch_networK_partner_services_error";
export const FETCH_NETWORk_PARTNER_POSTAL_CODES_LOADING = "fetch_networK_partner_postal_codes_loading";
export const FETCH_NETWORk_PARTNER_POSTAL_CODES = "fetch_networK_partner_postal_codes";
export const FETCH_NETWORk_PARTNER_POSTAL_CODES_ERROR = "fetch_networK_partner_postal_codes_error";
export const ADD_NEW_POSTAL_CODE_LOADING = "add_new_postal_code_loading";
export const ADD_NEW_POSTAL_CODE = "add_new_postal_code";
export const ADD_NEW_POSTAL_CODE_ERROR = "add_new_postal_code_error";
export const NETWORK_PARTNER_REASSIGN_SUCCESS = "network_partner_reassign_success";
export const NETWORK_PARTNER_REASSIGN_ERROR = "network_partner_reassign_error";
export const RESET_NETWORK_PARTNER_REASSIGN_STATE = "reset_network_partner_reassign_state";
export const RESET_ADD_POSTAL_CODE_STATE = "reset_add_postal_code_state";

// *Include when services are introduced in the network partner allocation
// export function fetchNetworkPartnerServices(params) {
//   return async function (dispatch) {
//     try {
//       dispatch({ type: FETCH_NETWORk_PARTNER_SERVICES_LOADING });
//       // todo: remove setTimeout
//       setTimeout(2000);
//       // todo: add api call
//       const res = {
//         data: [
//           {
//             id: 123,
//             pickup_country: "Singapore",
//             destination_country: "Indonesia",
//             service: "First Mile",
//           },
//         ],
//       };
//       dispatch({ type: FETCH_NETWORk_PARTNER_SERVICES, payload: res.data });
//     } catch (err) {
//       dispatch({ type: FETCH_NETWORk_PARTNER_SERVICES_ERROR });
//       console.log(err);
//     }
//   };
// }

export function fetchNetworkPartnerPostalCodes(params) {
  return async function (dispatch) {
    try {
      dispatch({
        type: FETCH_NETWORk_PARTNER_POSTAL_CODES_LOADING,
      });
      const res = await axios.get("/api/partner/postal-prefix-allocation-mapping-data/", { params });
      dispatch({ type: FETCH_NETWORk_PARTNER_POSTAL_CODES, payload: res.data });
    } catch (err) {
      dispatch({ type: FETCH_NETWORk_PARTNER_POSTAL_CODES_ERROR });
      console.log(err);
    }
  };
}

export function submitNetworkPartnerAssignAction(payload, postalCodePayload) {
  return async function (dispatch) {
    try {
      await axios.post("/api/partner/postal-prefix-allocation-mapping/update_partner/", payload);
      dispatch({
        type: NETWORK_PARTNER_REASSIGN_SUCCESS,
      });
      dispatch(fetchNetworkPartnerPostalCodes(postalCodePayload));
    } catch (err) {
      dispatch({
        type: NETWORK_PARTNER_REASSIGN_ERROR,
      });
      console.log(err);
    }
  };
}

export function addNewPostalCode(payload) {
  return async function (dispatch) {
    try {
      dispatch({
        type: ADD_NEW_POSTAL_CODE_LOADING,
      });
      await axios.post("/api/partner/postal-prefix-allocation-mapping/", payload);
      dispatch({
        type: ADD_NEW_POSTAL_CODE,
        payload: "Error",
      });
    } catch (err) {
      console.log(err.response.data);
      dispatch({
        type: ADD_NEW_POSTAL_CODE_ERROR,
        payload: err.response.data.message ? err.response.data.message : "record already exists with this data combination",
      });
    }
  };
}

export const addNewPostalCodeViaCsv = (payload) => async (dispatch) => {
  dispatch({ type: ADD_NEW_POSTAL_CODE_LOADING });

  const failedData = [];
  for (const p of payload) {
    await axios.post('/api/partner/postal-prefix-allocation-mapping/', p).catch((error) => {
      failedData.push({ ...JSON.parse(error.response.config.data), error_message: error.response.data.message });
    });
  }

  if (failedData.length) {
    const stringifyError = JSON.stringify(failedData, null, '\t');
    const formattedError = stringifyError.replace(/[[\]{}",\t\\]/g, '').trim();
    dispatch({
      type: ADD_NEW_POSTAL_CODE_ERROR,
      payload: formattedError,
    });
  } else {
    dispatch({
      type: ADD_NEW_POSTAL_CODE,
      payload: 'success',
    });
  }
}

export function resetNetworkPartnerReassignState() {
  return function (dispatch) {
    dispatch({
      type: RESET_NETWORK_PARTNER_REASSIGN_STATE,
    });
  };
}

export function resetAddPostalCodeState() {
  return function (dispatch) {
    dispatch({
      type: RESET_ADD_POSTAL_CODE_STATE,
    });
  };
}
