import {
  UPLOAD_ADHOC_CSV,
  CLEAR_UPLOAD_ADHOC_CSV_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case UPLOAD_ADHOC_CSV:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        lastUpdated: moment()
      };
    case CLEAR_UPLOAD_ADHOC_CSV_ERRORS:
      return { ...state, error: null, errorData: null };
    default:
      return state;
  }
}
