import React, { Component } from 'react';
import ErrorPopup from './common/ErrorPopup';
import ConfirmContextProvider from './agent/confirmAction/confirm.context';

class App extends Component {
  
	render() {
		return (
			<ConfirmContextProvider>
				<div>
					{this.props.children}
					<ErrorPopup />
				</div>
			</ConfirmContextProvider>
		);
	}
}

export default App


