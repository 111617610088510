import { combineReducers } from "redux";
import authReducer from "./commonReducers/authReducer";
import dataReducer from "./commonReducers/dataReducer";
import errorPopUpReducer from "./commonReducers/errorPopUpReducer";

// agent
import agentStatsReducer from "./agentReducers/agentStatsReducer";
import agentApplicationsReducer from "./agentReducers/agentApplicationsReducer";
import agentServiceReducer from "./agentReducers/agentServiceReducer";
import changeTrackerStatusesReducer from "./agentReducers/changeTrackerStatusesReducer";
import agentViewOrdersReducer from "./agentReducers/agentViewOrdersReducer";
import trackerUpdateReducer from "./agentReducers/trackerUpdateReducer";
import notesReducer from "./agentReducers/notesReducer";
import billsReducer from "./agentReducers/billsReducer";
import billRecordReducer from "./agentReducers/billRecordReducer";
import deleteOrderByUploadBatchNoReducer from "./agentReducers/deleteOrderByUploadBatchNoReducer";
import reportsReducer from "./agentReducers/reportsReducer";
import pricingReducer from "./agentReducers/pricingReducer";
import weightReconciliationReducer from "./agentReducers/weightReconciliationReducer";
import uploadAdhocCSVReducer from "./agentReducers/uploadAdhocCSVReducer";
import networkPartnerReducer from "./agentReducers/networkPartnerReducer";
import codManagementReducer from "./agentReducers/codManagementReducer";
import consigneeDocumentsReducer from "./agentReducers/consigneeDocumentsReducer";
import correctOrderAddressReducer from "./agentReducers/correctOrderAddressReducer";
import hmsReducer from "./agentReducers/hmsReducer";
import mawbReducer from "./agentReducers/mawbReducer";
import midmileAwbReducer from "./agentReducers/midmileAwbReducer";
import missedSlasLastMileReducer from "./agentReducers/missedSlasLastMileReducer";
import npDelayReasonReducer from "./agentReducers/npDelayReasonReducer";
import lspUsersReducer from "./agentReducers/lspUsersReducer";
import zoneReducer from "../components/agent/zoneLocations/zone.reducer";
import networkPartnerAllocationsReducer from "../components/agent/postalCodeAllocation/postalCodeAllocations.reducer";
import commonReducer from "../components/common/reducers";

import { UNAUTH_USER } from "../actions/types";
import orderExceptionsReducer from "./agentReducers/orderExceptionsReducer";

const appReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,

  // agent
  agentStats: agentStatsReducer,
  agentApplications: agentApplicationsReducer,
  services: agentServiceReducer,
  changeTrackerStatuses: changeTrackerStatusesReducer,
  agentOrders: agentViewOrdersReducer,
  trackerUpdate: trackerUpdateReducer,
  notes: notesReducer,
  bills: billsReducer,
  billRecord: billRecordReducer,
  deleteOrderByUploadBatchNo: deleteOrderByUploadBatchNoReducer,
  reports: reportsReducer,
  pricing: pricingReducer,
  weightReconciliation: weightReconciliationReducer,
  uploadAdhocCSV: uploadAdhocCSVReducer,
  networkPartner: networkPartnerReducer,
  codManagement: codManagementReducer,
  errorPopup: errorPopUpReducer,
  requestConsigneeDocs: consigneeDocumentsReducer,
  CorrectOrderAddress: correctOrderAddressReducer,
  hms: hmsReducer,
  mawb: mawbReducer,
  midmileAwb: midmileAwbReducer,
  missedSlasLastMile: missedSlasLastMileReducer,
  npDelayReason: npDelayReasonReducer,
  orderExceptions: orderExceptionsReducer,
  lspUsers: lspUsersReducer,
  zones: zoneReducer,
  networkPartnerAllocations: networkPartnerAllocationsReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
