import { 
  NP_DELAY_REASON_CREATE_LOADING,
  NP_DELAY_REASON_CREATED,
  NP_DELAY_REASON_CREATE_ERROR,
} from '../../actions/types';


export default function(state = { create: {} }, action) {
  switch (action.type) {
    case NP_DELAY_REASON_CREATE_LOADING:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          success: false,
          error: false
        }
      };
    case NP_DELAY_REASON_CREATED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: action.payload,
          error: false
        }
      };
    case NP_DELAY_REASON_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}
