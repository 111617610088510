import {
  ADD_CORRECT_ORDER_ADDRESS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch(action.type) {
    case ADD_CORRECT_ORDER_ADDRESS:
      return {
        ...state,
        errorData: action.errorData,
        lastSubmitted: moment()
      };
    default:
      return state;
  }
}
