import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { history } from "../../utils/historyUtils";
import { withRouter, Link } from "react-router-dom";
import Avatar from "react-avatar";

import { signoutUser } from "../../actions/commonActions/authActions";
import FeatureRequired from "../common/auth/FeatureRequired";
import NavbarTitle from "./NavbarTitle";
import { MAWB_CLIENTS } from "./midMileAwb/constants";

let drawerWidth = 270;
if (window.innerWidth > 300 && window.innerWidth < 1000) {
  drawerWidth = 280;
} else if (window.innerWidth > 1000) {
  drawerWidth = 270;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    background: "white",
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    background: "linear-gradient(170deg, #000040 0%, #000020 100%)",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
      height: window.innerHeight,
      overflow: "auto",
    },
    background: "white",
  },
  content: {
    flexGrow: 1,
    width: window.innerWidth,
    height: window.innerHeight,
    overflow: "auto",
    backgroundColor: "white",
    padding: theme.spacing.unit * 3,
  },
});

class AgentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.setSideBar();
  }

  componentDidUpdate() {
    this.props.history.listen((location, action) => {
      this.setState({
        mobileOpen: false,
      });
    });
  }

  setSideBar = () => {
    const navBarTitle = document.querySelector("#navBarTitle");
    if (navBarTitle !== null) {
      if (window.innerHeight < 650) {
        const body = document.querySelector("body");
        body.setAttribute("style", "overflow: hidden;");
      }

      const header = navBarTitle.parentElement.parentElement;
      if (!header.getAttribute("class").includes(" w-100")) {
        header.setAttribute("class", header.getAttribute("class") + " w-100");
      }

      const sideBar = navBarTitle.parentElement.parentElement.nextSibling;
      sideBar.style.display = "none";
    }
  };

  isSelected = (path, params = {}) => {
    const { pathname } = window.location;
    const selectedClasses = "pl-2 bg-secondary text-white pointer pl-3";

    const { mawb_client } = params;
    const searchParams = new URLSearchParams(window.location.search);
    const mawbClientParam = searchParams.get("mawb_client");
    if (mawb_client && pathname.includes("manage-awb")) {
      if (mawb_client === mawbClientParam) {
        return selectedClasses;
      }
    }

    if (
      (pathname.endsWith(path) || pathname.endsWith(path + "/")) &&
      !mawbClientParam
    ) {
      return selectedClasses;
    }
    return "pointer pl-3 text-dark";
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleLogout = () => {
    this.props.signoutUser(history);
  };

  state = {
    anchorEl: null,
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  renderSideBar = () => {
    const { anchorEl } = this.state;

    return (
      <div>
        <div>
          <Button
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleMenuClick}
          >
            <Avatar
              name={this.props.user ? this.props.user.name || "User" : "User"}
              round={true}
              size={48}
              className="mr-2"
            />{" "}
            {this.props.user ? this.props.user.name || "User" : "User"}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem component={Link} to="/agent/user">
              User
            </MenuItem>
            <MenuItem component={Link} to="/agent/change-password">
              Change password
            </MenuItem>
          </Menu>
        </div>

        <p className="mb-0 ml-3 text-grey mt-4">ORDERS</p>
        <NavLink to="/agent">
          <List className={this.isSelected("/agent")}>
            <span className="side-bar-font-size">Overview</span>
          </List>
        </NavLink>

        <FeatureRequired feature="order.view" hide>
          <NavLink to="/agent/view-orders">
            <List className={this.isSelected("/agent/view-orders")}>
              <span className="side-bar-font-size">View orders</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="order.delete_order_by_tracking_no" hide>
          <NavLink to="/agent/delete-order-by-upload-batch-no">
            <List
              className={this.isSelected(
                "/agent/delete-order-by-upload-batch-no",
              )}
            >
              <span className="side-bar-font-size">
                {" "}
                Delete order by upload batch no
              </span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="tracker.view" hide>
          <NavLink to="/agent/view-tracker-updates">
            <List className={this.isSelected("/agent/view-tracker-updates")}>
              <span className="side-bar-font-size">View tracker updates</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="order.tracker.change_status_by_csv" hide>
          <NavLink to="/agent/change-tracker-status-csv">
            <List
              className={this.isSelected("/agent/change-tracker-status-csv")}
            >
              <span className="side-bar-font-size">
                {" "}
                Change tracker status by CSV
              </span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="attachments.customs" hide>
          <NavLink to="/agent/request-consignee-documents">
            <List
              className={this.isSelected("/agent/request-consignee-documents")}
            >
              <span className="side-bar-font-size">
                Request Consignee for Documents
              </span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="order.shipping_batch.create" hide>
          <NavLink to="/agent/create-custom-batch">
            <List className={this.isSelected("/agent/create-custom-batch")}>
              <span className="side-bar-font-size">Create shipping batch</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="weight_recon.create" hide>
          <NavLink to="/agent/weight-reconciliation">
            <List className={this.isSelected("/agent/weight-reconciliation")}>
              <span className="side-bar-font-size">Change Weight by CSV</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="order.correct_addresses" hide>
          <NavLink to="/agent/correct-order-address">
            <List className={this.isSelected("/agent/change-order-address")}>
              <span className="side-bar-font-size">
                Change Order Address by CSV
              </span>
            </List>
          </NavLink>
        </FeatureRequired>

        {/* delete all code related to this component */}
        {/* <FeatureRequired feature="note.view" hide>
      <NavLink
        to="/agent/view-notes">
        <List className={this.isSelected('/agent/view-notes')}>
          <span className="side-bar-font-size">View notes</span>
        </List>
      </NavLink>
      </FeatureRequired> */}

        {/* <FeatureRequired feature="order.view" hide>
      <NavLink
        to="/agent/view-mawb">
        <List className={this.isSelected('/agent/view-mawb')}>
          <span className="side-bar-font-size">Master Airway Bill Tracker</span>
        </List>
      </NavLink>
      </FeatureRequired> */}
        <FeatureRequired feature="order.view" hide>
          <NavLink
            to={{
              pathname: "/agent/manage-awb",
            }}
          >
            <List className={this.isSelected("/agent/manage-awb")}>
              <span className="side-bar-font-size">Mid-mile Tracker</span>
            </List>
          </NavLink>
          <NavLink
            to={{
              pathname: "/agent/manage-awb",
              search: `?mawb_client=${MAWB_CLIENTS.shopee_china}`,
            }}
          >
            <List
              className={this.isSelected(
                `/agent/manage-awb?mawb_client=${MAWB_CLIENTS.shopee_china}`,
                { mawb_client: MAWB_CLIENTS.shopee_china },
              )}
            >
              <span className="side-bar-font-size">
                Mid-mile Tracker (Shopee China)
              </span>
            </List>
          </NavLink>
          <NavLink
            to={{
              pathname: "/agent/manage-awb",
              search: `?mawb_client=${MAWB_CLIENTS.shopee_asia}`,
            }}
          >
            <List
              className={this.isSelected(
                `/agent/manage-awb?mawb_client=${MAWB_CLIENTS.shopee_asia}`,
                { mawb_client: MAWB_CLIENTS.shopee_asia },
              )}
            >
              <span className="side-bar-font-size">
                Mid-mile Tracker (Shopee Asia)
              </span>
            </List>
          </NavLink>
        </FeatureRequired>
        <FeatureRequired feature="partner_delay.view" hide>
          <NavLink to="/agent/partner-delays">
            <List className={this.isSelected("/agent/partner-delays")}>
              <span className="side-bar-font-size">Partner Delays</span>
            </List>
          </NavLink>
        </FeatureRequired>

        {/* <FeatureRequired feature="order.view" hide> */}
        <NavLink to="/agent/submit-return-orders">
          <List className={this.isSelected("/agent/submit-return-orders")}>
            <span className="side-bar-font-size">Submit Return Orders</span>
          </List>
        </NavLink>
        {/* </FeatureRequired> */}

        <NavLink to="/agent/submit-return-order">
          <List className={this.isSelected("/agent/submit-return-order")}>
            <span className="side-bar-font-size">
              Submit Return Order (New)
            </span>
          </List>
        </NavLink>

        {/* <NavLink to="/agent/manage-exceptions">
          <List className={this.isSelected("/agent/manage-exceptions")}>
            <span className="side-bar-font-size">Manage Exceptions</span>
          </List>
        </NavLink> */}

        <NavLink to="/agent/rts-pickup-capacity">
          <List className={this.isSelected("/agent/rts-pickup-capacity")}>
            <span className="side-bar-font-size">
              RTS Pickup Capacity Management
            </span>
          </List>
        </NavLink>

        <NavLink to="/agent/lsp-users">
          <List className={this.isSelected("/agent/lsp-users")}>
            <span className="side-bar-font-size">LSP User Creation</span>
          </List>
        </NavLink>
        <NavLink to="/agent/postal-code-allocation">
          <List className={this.isSelected("/agent/postal-code-allocation")}>
            <span className="side-bar-font-size">Postal Code Allocation</span>
          </List>
        </NavLink>

        <FeatureRequired feature="agent_application" hide>
          <p className="mb-0 ml-3 text-grey mt-4">MERCHANT</p>
          <FeatureRequired feature="agent_application.view" hide>
            <NavLink to="/agent/agent-apps">
              <List className={this.isSelected("/agent/agent-apps")}>
                <span className="side-bar-font-size">
                  View Merchant Profiles
                </span>
              </List>
            </NavLink>
          </FeatureRequired>
          <NavLink to="/agent/shopee-cartons">
            <List className={this.isSelected("/agent/shopee-cartons/")}>
              <span className="side-bar-font-size">Shopee Cartons</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <p className="mb-0 ml-3 text-grey mt-4">ZONES & SLAs</p>
        <NavLink to="/agent/zone-locations">
          <List className={this.isSelected("/agent/zone-locations")}>
            <span className="side-bar-font-size">View Zone Locations</span>
          </List>
        </NavLink>
        <NavLink to="/agent/network-editor">
          <List className={this.isSelected("/agent/network-editor")}>
            <span className="side-bar-font-size">SLA Network Editor</span>
          </List>
        </NavLink>

        {/* delete all code related to this component */}
        {/* <FeatureRequired feature="partner" hide>
        <p className="mb-0 ml-3 text-grey mt-4">NETWORK PARTNER</p>

        <FeatureRequired feature="partner.create" hide>
        <NavLink
          to="/agent/network-partners/add">
          <List className={this.isSelected('/agent/network-partners/add')}>
            <span className="side-bar-font-size">Add Network Partner</span>
          </List>
        </NavLink>
        </FeatureRequired>

        <FeatureRequired feature="partner.update" hide>
        <NavLink
          to="/agent/network-partners">
          <List className={this.isSelected('/agent/network-partners')}>
            <span className="side-bar-font-size">Edit Network Partner</span>
          </List>
        </NavLink>
        </FeatureRequired>
      </FeatureRequired> */}

        <FeatureRequired feature={["bill.view", "pricing.view", "cod"]} hide>
          <p className="mb-0 ml-3 text-grey mt-4">BILLING & FINANCE</p>
          {/*<FeatureRequired feature="bill.view" hide>
            <NavLink to="/agent/view-bills">
              <List className={this.isSelected("/agent/view-bills")}>
                <span className="side-bar-font-size">View order bills</span>
              </List>
            </NavLink>
          </FeatureRequired>*/}
          <FeatureRequired feature="pricing.view" hide>
            <NavLink to="/agent/pricing">
              <List className={this.isSelected("/agent/pricing")}>
                <span className="side-bar-font-size"> Pricing</span>
              </List>
            </NavLink>
          </FeatureRequired>

          <FeatureRequired feature="cod.agent_application_remittance.view" hide>
            <NavLink to="/agent/merchant-remittance">
              <List className={this.isSelected("/agent/merchant-remittance")}>
                <span className="side-bar-font-size">
                  {" "}
                  Remittances made to Merchants
                </span>
              </List>
            </NavLink>
          </FeatureRequired>

          <FeatureRequired feature="cod.partner_remittance.view" hide>
            <NavLink to="/agent/partner-remittance">
              <List className={this.isSelected("/agent/partner-remittance")}>
                <span className="side-bar-font-size">
                  {" "}
                  Remittances received from Partners
                </span>
              </List>
            </NavLink>
          </FeatureRequired>

          {/*<FeatureRequired feature="cod.agent_application_dues.view" hide>
            <NavLink to="/agent/merchant-dues">
              <List className={this.isSelected("/agent/merchant-dues")}>
                <span className="side-bar-font-size"> Merchant dues</span>
              </List>
            </NavLink>
          </FeatureRequired>

          <FeatureRequired feature="cod.partner_dues.view" hide>
            <NavLink to="/agent/partner-dues">
              <List className={this.isSelected("/agent/partner-dues")}>
                <span className="side-bar-font-size"> Partner dues</span>
              </List>
            </NavLink>
          </FeatureRequired>
          */}
        </FeatureRequired>

        <p className="mb-0 ml-3 text-grey mt-4">EXPORT DATA</p>
        <FeatureRequired feature="order.download_pods" hide>
          <NavLink to="/agent/download-pods">
            <List className={this.isSelected("/agent/download-pods")}>
              <span className="side-bar-font-size"> Download PODs</span>
            </List>
          </NavLink>
        </FeatureRequired>

        <NavLink to="/agent/download-shopee-orders">
          <List className={this.isSelected("/agent/download-shopee-orders")}>
            <span className="side-bar-font-size">Download Shopee Orders</span>
          </List>
        </NavLink>

        <FeatureRequired feature="adhoc.upload_csv" hide>
          <NavLink to="/agent/upload-adhoc-csv">
            <List className={this.isSelected("/agent/upload-adhoc-csv")}>
              <span className="side-bar-font-size"> Upload adhoc csv</span>
            </List>
          </NavLink>
        </FeatureRequired>
        <FeatureRequired
          feature={["order.report", "order.tracker.report"]}
          hide
        >
          <NavLink to="/agent/reports">
            <List className={this.isSelected("/agent/reports")}>
              <span className="side-bar-font-size"> Reports</span>
            </List>
          </NavLink>
        </FeatureRequired>

        {/* delete all code related to this component */}
        {/* <FeatureRequired feature="note.report" hide>
      <NavLink
        to="/agent/notes-report">
        <List className={this.isSelected('/agent/notes-report')}>
          <span className="side-bar-font-size"> Notes report</span>
        </List>
      </NavLink>
      </FeatureRequired> */}
      </div>
    );
  };

  render() {
    const { classes, theme } = this.props;

    const drawer = <div className="mt-4">{this.renderSideBar()}</div>;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            <NavbarTitle handleDrawerToggle={this.handleDrawerToggle} />
            <div
              className="pointer"
              style={{ color: "lightgray" }}
              onClick={this.handleLogout}
            >
              LOGOUT
            </div>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <SwipeableDrawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            onOpen={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            onOpen={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

function mapStateToProps({ auth, midmileAwb }) {
  return {
    user: auth.user,
    mawb_no: midmileAwb?.awbDetails?.mawb_no || "",
  };
}

export default withRouter(
  compose(
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, {
      signoutUser,
    }),
  )(AgentDashboard),
);
