import {
  CHANGE_TRACKER_STATUSES,
  CLEAR_CHANGE_TRACKER_STATUSES_ACTION_ERROR
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case CHANGE_TRACKER_STATUSES:
      return {
        ...state,
        error: action.payload,
        message: "Tracker(s) status successfully changed.",
        errorData: action.errorData,
        isSuccess: action.isSuccess,
        successMessage: action.successMessage,
        lastUpdated: moment()
      }
    case CLEAR_CHANGE_TRACKER_STATUSES_ACTION_ERROR:
      return { ...state, error: null, errorData: null, message: null }
    default:
      return state;
  }
}
