import { OPEN_ERROR_POPUP, CLOSE_ERROR_POPUP } from '../../actions/types';

export default function(state = { open: false }, action) {
	switch (action.type) {
		case OPEN_ERROR_POPUP:
      return { ...state, open: true };
    case CLOSE_ERROR_POPUP:
			return { ...state, open: false };

		default:
			return state;
	}
}
