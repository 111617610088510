import axios from 'axios';
import store from '../store';
import { UNAUTH_USER, OPEN_ERROR_POPUP } from '../actions/types';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message === "Cancelled") {
      console.log("Cancelled by user");
    } else if (error.response.status === 401) {
      store.dispatch({ type: UNAUTH_USER });
    } else if (error.response.status !== 400) {
      store.dispatch({ type: OPEN_ERROR_POPUP });
    }
    return Promise.reject(error);
  }
);
