import { Alert, Card, Collapse } from "antd";
import React from "react";
import { ColumnSelection } from "../Columns";
import ConsigneeFilter from "./ConsigneeFilter";
import CountryFilter from "./CountryFilter";
import DateFilter from "./DateFilter";
import ExceptionFilter from "./ExceptionFilter";
import HMSFilter from "./HMSFilter";
import OrderNumberFilter from "./OrderNumberFilter";
import ServiceFilter from "./ServiceFilter";
import StatusFilter from "./StatusFilter";

const { Panel } = Collapse;

function Filters({ onResetPage, ordersFetching, showErrorMessage }) {
  return (
    <Card>
      <h4>Filters</h4>
      <div className="mt-3">
        <div className="mb-3">
          <ServiceFilter />
          <DateFilter />
          <OrderNumberFilter />
        </div>
        <Collapse className="mb-3">
          <Panel header={`More Filters`} key="1">
            <ConsigneeFilter />
            <CountryFilter />
            <StatusFilter />
            <ExceptionFilter />
            <HMSFilter />
            <ColumnSelection />
          </Panel>
        </Collapse>
        {showErrorMessage && <Alert className="mb-3" type="error" message="Please apply some filters." />}
        <button
          disabled={ordersFetching}
          className="w-100 btn btn-lg"
          style={{ color: "#fff", backgroundColor: "#1C3393" }}
          onClick={onResetPage}
        >
          Search
        </button>
      </div>
    </Card>
  );
}

export default Filters;
