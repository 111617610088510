import ChooseExceptions from "./components/ChooseExceptions"
import ChooseResolutionMethod from "./components/ChooseResolutionMethod"
import ConsigneeDetails from "./components/ConsigneeDetailsForm"
import LeaveNote from "./components/LeaveNote"
import PickupDetails from "./components/PickupDetailsForm"
import ResolutionSuccess from "./components/ResolutionSuccess"

export const steps = {
  chooseResolutionMethod: "chooseResolutionMethod",
  chooseExceptions: "chooseExceptions",
  consigneeDetails: "consigneeDetails",
  pickupDetails: "pickupDetails",
  leaveNote: "leaveNote",
  success: "success",
}

export const componentStep = {
  chooseResolutionMethod: ChooseResolutionMethod,
  chooseExceptions: ChooseExceptions,
  consigneeDetails: ConsigneeDetails,
  pickupDetails: PickupDetails,
  leaveNote: LeaveNote,
  success: ResolutionSuccess
}


export const RESOLUTION_TYPES = { // types from backend
  cancellation: 'Cancellation',
  dispose: 'Dispose',
  others: 'Others',
  rtv: 'RTV',
  recollectExistingTN: 'Recollection (Existing TN)',
  recollectNewTN: 'Recollection (New TN)',
  redeliver: 'Redeliver'
}
