import React, { useContext } from "react";
import AgentApplicationDropdown from "../../../../AgentApplicationsDropdown";
import SelectService from "../../../../common/SelectService";
import { ViewOrdersContext } from "../../ViewOrders.context";
import { Select } from "antd";

const { Option } = Select;

function ServiceFilter() {
  const { filters, handleChangeFilter, agentApplications, agentApplicationsLoading } = useContext(ViewOrdersContext);

  return (
    <div>
      <div className="d-flex align-items-center">
        <div style={{ width: "100%" }}>
          <AgentApplicationDropdown
            agentApplications={agentApplications}
            agentApplicationsLoading={agentApplicationsLoading}
            value={filters.agent_application_id}
            onChange={(v) => handleChangeFilter("agent_application_id", v)}
            mode="multiple"
          />
        </div>
        <div className="ml-2">
          <Select
            style={{ width: 150 }}
            size="large"
            value={filters.agent_application_id_filter || "filter_for"}
            onChange={(v) => handleChangeFilter("agent_application_id_filter", v)}
          >
            <Option value="filter_for">Filter For</Option>
            <Option value="filter_away">Filter Away</Option>
          </Select>
        </div>
      </div>
      <SelectService className="mt-2" value={filters.service_id} onChange={(v) => handleChangeFilter("service_id", v)} />
    </div>
  );
}

export default ServiceFilter;
