import axios from "axios";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { AgentApplicationsAtom } from "../recoil/atoms/agentApplicationListAtom";

const URL = "/api/order/app/";

const useAgentApplications = () => {
  const [agentApplications, setAgentApplications] = useRecoilState(
    AgentApplicationsAtom
  );
  const token = localStorage.getItem("token");

  const { isLoading } = useQuery(
    "agent-applications",
    async () => {
      const r = await axios.get(URL, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return r.data;
    },
    {
      refetchOnWindowFocus: false, // staleTime: Infinity,
      refetchInterval: false,
      onSuccess: (data) => {
        setAgentApplications(data);
      },
      onError: (err) => {},
    }
  );

  return [agentApplications, isLoading];
};

export default useAgentApplications;
