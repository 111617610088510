import React, { useContext } from "react"
import { Select, Button } from "antd"
import { useFormik } from "formik"
import { useQuery } from "react-query"
import * as yup from "yup"

import { ResolveOrderExceptionAPI } from "../api"
import { ResolveOrderExceptionContext } from "../ResolveOrderException.context"

const { Option } = Select

const ChooseResolutionMethod = () => {
  const {
    handleCloseModal,
    orderIds,
    setCurrentStep,
    saveData,
    getNextStep,
    orderDataExceptions
  } = useContext(ResolveOrderExceptionContext)
  
  const { data: resolutionTypes, isLoading: resolutionTypeLoading } = useQuery(
    "resolution-type",
    ResolveOrderExceptionAPI.getReolutionTypes,
    {
      staleTime: Infinity,
      refetchInterval: false
    }
  )

  const resolutionTypeOptions = !resolutionTypeLoading
    ? Object.entries(resolutionTypes).map(([type, resolutionExceptions]) => {
      let enabled = type === "Others"
      for (let e of orderDataExceptions) {
        if (resolutionExceptions.includes(e)) {
          enabled = true
          break
        }
      }
      return <Option key={type} value={type} disabled={!enabled}>{type}</Option>
    })
    : []

  const initialValues = {
    resolutionType: undefined
  }
  const validationSchema = yup.object().shape({
    resolutionType: yup.string().nullable().required("This field is required.")
  })
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    const validationResponse = await ResolveOrderExceptionAPI.validateResolutionType({
      order_ids: orderIds,
      resolution_type: values.resolutionType
    })
    setSubmitting(false)
    if (validationResponse.isError) {
      setErrors({
        resolutionType: validationResponse.error?.errors
      })
    } else {
      saveData(values)
      const nextStep = getNextStep(values.resolutionType)
      setCurrentStep(nextStep)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div>
      <h6 className="font-weight bold">Choose Resolution Method</h6>

      <form onSubmit={formik.handleSubmit}>
      <div className="mt-4">
        <div className="w-100">
          <Select
            placeholder={resolutionTypeLoading? "Loading...":"Select resolution method"}
            style={{ width: "100%" }}
            loading={resolutionTypeLoading}
            disabled={resolutionTypeLoading}
            value={formik.values.resolutionType}
            onChange={value => {
              formik.setFieldValue("resolutionType", value)
            }}>
              {resolutionTypeOptions}
          </Select>
          {
            formik.errors.resolutionType &&
            <span className="text-danger">{formik.errors.resolutionType}</span>
          }
        </div>
      </div>
      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()} disabled={formik.isSubmitting}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting? "Processing...":"Proceed"}
          </Button>
        </div>
      </div>
      </form>

    </div>
  )
}

export default ChooseResolutionMethod