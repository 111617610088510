import React, { useContext } from "react";
import { Input } from "antd";
import { ViewOrdersContext } from "../../ViewOrders.context";

function ConsigneeFilter() {
  const { filters, handleChangeFiltersValues } = useContext(ViewOrdersContext);
  // const [values, setValues] = useState({
  //   consignee_name: "",
  //   consignee_number: "",
  //   consignee_email: "",
  // })
  const handleTextChange = (field, value) => {
    // const newValues = {
    //   ...values,
    //   [field]: value
    // }
    // setValues(newValues)
    handleChangeFiltersValues({ [field]: value });
  };

  const inputFields = [
    {
      title: "Consignee Name",
      name: "consignee_name",
    },
    {
      title: "Consignee Number",
      name: "consignee_number",
    },
    {
      title: "Consignee Email",
      name: "consignee_email",
    },
  ];

  return (
    <div className="row mt-3">
      {inputFields.map((f, i) => {
        return (
          <div className="col-md-4" key={`inputfield-${f.name}`}>
            <div>
              <p className="mb-0">{f.title}</p>
              <Input placeholder={f.placeholder} defaultValue={filters[f.name]} onChange={(e) => handleTextChange(f.name, e.target.value)} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ConsigneeFilter;
