import {
  FETCH_ALL_HUBS,
} from '../../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_ALL_HUBS:
      return { ...state, hubs: action.payload };
    default:
      return state;
  }
}
