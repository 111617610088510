import axios from 'axios';
import {
  AUTH_USER,
  AUTH_ERROR,
  FETCH_SIGNED_IN_USER,
  EDIT_SIGNED_IN_USER,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  PASSWORD_SENT,
  CLEAR_AUTH_ERRORS,
  FETCH_USER_FEATURES
} from '../types';


const fetchUserDataAndFeatures = async (type) => {
  const userId = localStorage.getItem('_id')
  const urls = {
    feature: `/api/auth/feature/`,
    profile: `/api/auth/profile/${userId}/`
  }
  const response = await axios.get(urls[type])
  return response
}

export function signinUser(username, password, history) {
  console.log('signing in...');
  return function(dispatch) {
    axios.post(`/api/auth/login/`,
      {
        "username": username,
        "password": password
      }
    )
      .then((response) => {
        const { token, user_id } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('_id', user_id);
        
        // Move dispatch of AUTH_USER, since token is already retrieved
        dispatch({
          type: AUTH_USER,
        });
        Promise.all([
          fetchUserDataAndFeatures('profile'),
          fetchUserDataAndFeatures('feature')
        ]).then(result => {
          const signedInUserResponse = result[0]
          const userFeatureResponse = result[1]
          dispatch(fetchSignedInUser(signedInUserResponse.data))
          dispatch(fetchUserFeatures(userFeatureResponse.data))

          // Redirect after getting `profile` and `feature`
          history.push('/agent/view-orders');
          // if (userFeatureResponse.data.some(f => f.name === 'order.view')) {
          //   history.push('/agent/view-orders');
          // } else {
          //   history.push('/');
          // }
        })
      })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR
        });
      });
  }
}

export function signoutUser(history) {
  console.log('signing out...');
  localStorage.clear();

  return function(dispatch) {
    history.push('/signin');
  }
}

export function fetchSignedInUser(data) {
  console.log('fetching currently signin user...');

  return function(dispatch) {
    if (data) {
      dispatch({
        type: FETCH_SIGNED_IN_USER,
        payload: data
      });
    } else {
      const userId = localStorage.getItem('_id')
      axios.get(`/api/auth/profile/${userId}/`)
        .then((response) => {
          dispatch({
            type: FETCH_SIGNED_IN_USER,
            payload: response.data
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

export function fetchUserFeatures(data) {
  console.log('fetching currently user\'s features...');

  return function(dispatch) {
    if (data) {
      dispatch({
        type: FETCH_USER_FEATURES,
        payload: data
      });
    } else {
      axios.get(`/api/auth/feature/`)
        .then((response) => {
          dispatch({
            type: FETCH_USER_FEATURES,
            payload: response.data
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

export function editSignedInUser(id, emailValue, nameValue) {
  console.log('editing currently signin user...');

  return function(dispatch) {
    axios.patch(`/api/auth/profile/${id}/`,
      {
        "email": emailValue,
        "name": nameValue
      }
    )
      .then((response) => {
        dispatch({
          type: EDIT_SIGNED_IN_USER,
          payload: false
        });
        dispatch(fetchSignedInUser());
      })
      .catch((response) => {
        dispatch({
          type: EDIT_SIGNED_IN_USER,
          payload: true
        });
      })
  }
}

export function changePassword(id, currentPassword, newPasswordValue) {
  console.log('changing password...');

  return function(dispatch) {
    axios.patch(`/api/auth/profile/${id}/`,
      {
        "current_password": currentPassword,
        "password": newPasswordValue,
      }
    )
      .then((response) => {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: true,
          errorData: error.response.data
        });
      });
  }
}

export function resetPassword(emailAddress) {
  console.log('resetting password...');

  return function(dispatch) {
    axios.post(`/api/auth/reset-password/request/`,
      {
        "email": emailAddress,
        "portal": 'ops',
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then((response) => {
        dispatch({
          type: RESET_PASSWORD,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD,
          payload: true
        });
      });
  }
}

export function passwordSent(newPassword, resetToken) {
  console.log('password sent...');

  return function(dispatch) {
    axios.post(`/api/auth/reset-password/`,
      {
        "resetToken": resetToken,
        "newPassword": newPassword,
        "portal": 'ops',
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then((response) => {
        dispatch({
          type: PASSWORD_SENT,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: PASSWORD_SENT,
          payload: true
        });
      });
  }
}

export function clearAuthErrors() {
  console.log('clearing auth errors...');
  return {
    type: CLEAR_AUTH_ERRORS
  }
}
