export const cleanTrackingNumbers = (trackingNumbers, type='array') => {
  const result = [...new Set(trackingNumbers.split(/[ ,\n]+/))].filter(item => !!item)
  if(type === 'array')
    return result

  if(type === 'string')
    return result.join()
}

export const getDateTimeArray = (string) => {
  const dateTime = new Date(string)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  // const date = `${months[dateTime.getMonth()]} ${dateTime.getDate()}, ${dateTime.getFullYear()}`
  const date = `${dateTime.getDate()} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}`
  const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()
  return [date, time]
}

export const cleanFieldName = field => {
  let cleanedKey = field;
  if (field.includes("(")) {
    cleanedKey = field.substring(0, field.indexOf("(")).trim();
  }
  if (/[\s]+/g.test(field)) {
    cleanedKey = field.substring(0, field.indexOf(" ")).trim();
  }
  return cleanedKey;
};

export function checkIsAdmin(user) {
  if (process.env.REACT_APP_IS_PRODUCTION) {
    return user.id === 3 && user.email === 'admin@janio.asia'
  } else if (process.env.REACT_APP_IS_STAGING || process.env.REACT_APP_IS_LOCAL) {
    return user.id === 421 && user.email === 'admin@email.com'
  }
}

export const titleize = (str) => {
  if (typeof str !== 'string') {
		throw new TypeError('Expected a string');
	}

	return str.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export const capitalizeUnderscore = (string) => {
  if (!string) {
    return ""
  }
  const removeUndercore = string.split('_').join(' ')
  return titleize(removeUndercore)
}

export const debounceFunc = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}