import React from 'react'
import { connect } from 'react-redux'
import { getObjectValueByPath } from '../../../utils/ObjectUtils'
import store from '../../../store'
import { checkIsAdmin } from '../../../utils'

export const hasPermission = permissionNames => {
  const featuresObj = store.getState().auth.featuresObj || {}
  return getObjectValueByPath(featuresObj, permissionNames)
}

const FeatureRequired = props => {
  let { disable, hide, feature, user, children } = props

  const isAdmin = checkIsAdmin(user)
  if (isAdmin) {
    return children
  }

  const matchAll = !(props.matchAll === undefined)

  if (!disable && !hide) {
    hide = true
  }

  const disabledProps = {
    disabled: true, greyed: "true",
    title: "Please request for permission from your admin to access this feature :)"
  }

  // props.features is from redux
  if (!props.features || !props.features.length) {
    if (!!hide) return ''
    if (!!disable) {
      return React.cloneElement(props.children, disabledProps)  // with disable
    }
  }

  let hasPermission
  if (Array.isArray(feature)) {
    const permissions = feature.map(f => getObjectValueByPath(props.featuresObj, f))

    if (matchAll) {
      hasPermission = !permissions.some(b => b === 0)
    } else {
      hasPermission = permissions.some(b => b === 1)
    }
  } else {
    hasPermission = getObjectValueByPath(props.featuresObj, feature)
  }

  if (!hasPermission) {
    if (!!hide) return ''
    if (!!disable) {
      return React.cloneElement(props.children, disabledProps) // with disable
    }
  } else {
    return children
  }
}

const mapState = ({ auth }) => ({
  features: auth.features,
  featuresObj: auth.featuresObj,
  user: auth.user || {}
})

export default connect(mapState)(FeatureRequired)