import React, { useContext } from "react";
import { Input, DatePicker } from "antd";
import moment from "moment";

import { ViewOrdersContext } from "../../ViewOrders.context";
import SelectHubs from "../../../../common/SelectHub";
const { RangePicker } = DatePicker;

function HMSFilter() {
  const { filters, handleChangeFilter, handleChangeFiltersValues } = useContext(ViewOrdersContext);
  // const updateFilters = React.useCallback(debounceFunc(handleChangeFiltersValues, 500), [])
  const handleTextChange = (field, value) => {
    handleChangeFiltersValues({ [field]: value });
  };

  const handleDateRangeChange = (dates, fieldName) => {
    const date_from = dates[0]?.format("YYYY-MM-DD");
    const date_to = dates[1]?.format("YYYY-MM-DD");

    const dateFromField = `${fieldName}_from`;
    const dateToField = `${fieldName}_to`;
    handleChangeFiltersValues({
      [dateFromField]: date_from,
      [dateToField]: date_to,
    });
  };

  return (
    <div className="mt-4">
      <h5>HMS Filters</h5>
      <div className="row">
        <div className="col-md-3">
          <div>
            <p className="mb-0">Hub</p>
            {/* <Select style={{ width: '100%' }} /> */}
            <SelectHubs onChange={(v) => handleChangeFilter("hub_id", v) && console.log("hub_id", v)} value={filters.hub_id} />
          </div>
        </div>
        <div className="col-md-3">
          <div>
            <p className="mb-0">Group ID</p>
            <Input placeholder="Enter Hub Group ID" defaultValue={filters.hub_group_id} onChange={(e) => handleTextChange("hub_group_id", e.target.value)} />
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <p className="mb-0">Scan Date</p>
            <RangePicker
              name="pickupDate"
              className="w-100"
              onChange={(dates) => handleDateRangeChange(dates, "hms_scan")}
              format="DD/MM/YY"
              ranges={{
                Today: [moment().startOf("day"), moment().endOf("day")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              value={[filters.hms_scan_from ? moment(filters.hms_scan_from) : null, filters.hms_scan_to ? moment(filters.hms_scan_to) : null]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HMSFilter;
