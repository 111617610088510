import React from 'react'

function ReturnIcon() {
  return (
    <img style={{
      height: 16,
      width: 16,
      verticalAlign: 'middle',
      marginRight: 2,
    }} src="https://res.cloudinary.com/janio/image/upload/v1585795153/return-icon.svg" alt="return" />
  )
}

export default ReturnIcon
