import React from "react";
import { createFilter } from "react-select";
import Select from "./common/OptimzedSelect/OptimizedSelect";

const AgentApplicationDropdown = React.memo(({ onChange, mode = "single", value, agentApplications, agentApplicationsLoading, ...props }) => {
  const renderOptions = () => {
    if (agentApplications) {
      let options = [{ agent_application_id: "", agent_application_name: "All agent applications" }];
      return options.concat(agentApplications.map((app) => ({ agent_application_id: app.agent_application_id, agent_application_name: app.agent_application_name })));
    }
  };

  return (
    <Select
      isMulti={mode === "multiple"}
      getOptionLabel={(option) => option.agent_application_name}
      getOptionValue={(option) => option.agent_application_id}
      placeholder="Select agent applications"
      clearable
      onChange={onChange}
      options={renderOptions()}
      maxMenuHeight={100}
      menuHeight={100}
      debounceInterval={200}
      filterOption={createFilter({ ignoreAccents: false })}
      rowCount={agentApplications?.length}
      value={value}
      isLoading={agentApplicationsLoading}
      isDisabled={agentApplicationsLoading || props.isDisabled}
      width="100%"
    />
  );
});

export default AgentApplicationDropdown;
