import "antd/lib/style/core/index.less";
import "normalize.css/normalize.css";
import React, { lazy, Suspense } from "react";
import Favicon from "react-favicon";
import { Provider } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryParamProvider } from "use-query-params";
import { fetchSignedInUser, fetchUserFeatures } from "../actions/commonActions/authActions";
import { AUTH_USER } from "../actions/types";
import App from "../components/app";
import DoRedirect from "../components/common/doRedirect/DoRedirect";
import Loading from "../components/Loading";
import img from "../images/janio-favicon.png";
import store from "../store";
import "../style/bootstrap.global.scss";
import "../style/style.scss";
import { history } from "../utils/historyUtils";
import { agentRoutes } from "./agentRoutes/agentRoutes";

const Signin = lazy(() => import(/* webpackChunkName: 'Signin' */ "../components/common/auth/Signin"));
const PasswordSent = lazy(() => import(/* webpackChunkName: 'PasswordSent' */ "../components/common/resetPassword/PasswordSent"));
const ResetPasswordSuccess = lazy(() => import(/* webpackChunkName: 'ResetPasswordSuccess' */ "../components/common/resetPassword/ResetPasswordSuccess"));
const ResetPassword = lazy(() => import(/* webpackChunkName: 'ResetPassword' */ "../components/common/resetPassword/ResetPassword"));

const token = localStorage.getItem("token");
if (token) {
  store.dispatch({ type: AUTH_USER });
  store.dispatch(fetchSignedInUser());
  store.dispatch(fetchUserFeatures());
}

export const routes = (
  <Provider store={store}>
    <RecoilRoot>
      <DoRedirect>
        <Favicon url={img} loading="lazy" />
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App>
              <Suspense fallback={<Loading />}>
                <Switch>
                  {agentRoutes}
                  <Route path="/password-sent/:resetToken" component={PasswordSent} />
                  <Route path="/reset-password/success" component={ResetPasswordSuccess} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/signin" component={Signin} />
                  <Route path="/" component={() => <Redirect to="/agent" />} />
                </Switch>
              </Suspense>
            </App>
          </QueryParamProvider>
        </Router>
      </DoRedirect>
    </RecoilRoot>
  </Provider>
);
