import {
  DELETE_ORDER_BY_UPLOAD_BATCH_NO,
  CLEAR_DELETE_ORDER_BY_UPLOAD_BATCH_NO_ERROR
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch(action.type) {
    case DELETE_ORDER_BY_UPLOAD_BATCH_NO:
      return {
        ...state,
        error: action.payload,
        message: action.message,
        status: action.status,
        lastUpdated: moment()
      }
    case CLEAR_DELETE_ORDER_BY_UPLOAD_BATCH_NO_ERROR:
      return { ...state, error: null, message: null }
    default:
      return state;
  }
}
