import {
  FETCH_ORDERS_BY_FILTER,
  FETCH_ORDERS_BY_FILTER_LOADING,
  DELETE_ORDER,
  CHANGE_AGENT_ORDERS_STATUSES,
  SUBMIT_ORDERS,
  RE_SUBMIT_TO_EXTERNAL_SERVICE,
  RE_SUBMIT_TO_EXTERNAL_SERVICE_ERROR,
  EDIT_ORDER,
  FETCH_ORDER_DETAILS,
  FETCH_SERVICE_DETAILS,
  ORDERS_ACTION_ERROR,
  CLEAR_ORDERS_ACTION_ERROR,
  FETCH_ORDER_PARTNER_UPDATES,
  FETCH_ORDER_REMITTANCE_DATA,
  CLEAR_ORDERS_DATA
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_ORDERS_BY_FILTER_LOADING:
      return {
        ...state,
        ordersFilterLoading: true
      }
    case FETCH_ORDERS_BY_FILTER:
      return {
        ...state,
        orders: action.payload.results,
        totalOrders: action.payload.count,
        totalPages: Math.ceil(action.payload.count/action.pageSize),
        lastUpdated: moment(),
        queryId: action.queryId,
        ordersFilterLoading: false
       };
    case DELETE_ORDER:
      return { ...state, error: action.payload, message: "Order(s) successfully deleted." };
    case CHANGE_AGENT_ORDERS_STATUSES:
      return { ...state, error: action.payload, message: "Order(s) status successfully changed." }
    case SUBMIT_ORDERS:
      return { ...state, error: action.payload, message: "Order(s) successfully submitted." }
    case RE_SUBMIT_TO_EXTERNAL_SERVICE:
      return { ...state, error: action.payload, message: "Order(s) successfully submitted to external services." }
    case RE_SUBMIT_TO_EXTERNAL_SERVICE_ERROR:
      return { ...state, error: action.payload, message: "An error has occured, please try again."}
    case EDIT_ORDER:
      return { ...state, error: action.payload, errorData: action.errorData }
    case FETCH_ORDER_DETAILS:
      return { ...state, orderDetails: action.payload }
    case FETCH_SERVICE_DETAILS:
       return { ...state, serviceDetails: action.payload }
    case ORDERS_ACTION_ERROR:
      return { ...state, error: action.payload, message: "An error has occured, please try again." }
    case CLEAR_ORDERS_ACTION_ERROR:
      return { ...state, error: null, errorData: null, message: null }
    case FETCH_ORDER_PARTNER_UPDATES:
       return {...state, partnerUpdates: action.payload}
    case FETCH_ORDER_REMITTANCE_DATA:
      return {...state, remittanceData: action.payload }
    case CLEAR_ORDERS_DATA:
      return {...state, orders: undefined}
    default:
      return state;
  }
}
