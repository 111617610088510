import React from "react";
import AgentDashboard from "../AgentDashboard";
import ViewOrders from "./components/ViewOrders";
import { ViewOrdersContextProvider } from "./ViewOrders.context";

function ViewOrdersPage() {
  return (
    <AgentDashboard>
      <ViewOrdersContextProvider>
        <ViewOrders />
      </ViewOrdersContextProvider>
    </AgentDashboard>
  );
}

export default ViewOrdersPage;
