import React, { useContext } from "react";
import { Select } from "antd";
import { ViewOrdersContext } from "../../ViewOrders.context";
import { useCountries } from "../../../../../hooks/useCountries";

const { Option } = Select;

function CountryFilter() {
  const { filters, handleChangeFilter } = useContext(ViewOrdersContext);
  const { data: countries, loading: countriesLoading } = useCountries();

  return (
    <div className="row mt-3">
      <div className="col-md-3">
        <div>
          <p className="mb-0">Pickup Country</p>
          <SelectCountry
            value={filters.pickup_country}
            countries={countries}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={countriesLoading}
            onChange={(value) => handleChangeFilter("pickup_country", value)}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div>
          <p className="mb-0">Consignee Country</p>
          <SelectCountry
            value={filters.consignee_country}
            getPopupContainer={(trigger) => trigger.parentNode}
            countries={countries}
            disabled={countriesLoading}
            loading={countriesLoading}
            onChange={(value) => handleChangeFilter("consignee_country", value)}
          />
        </div>
      </div>
    </div>
  );
}

const SelectCountry = React.memo(({ countries = [], ...props }) => {
  return (
    <Select style={{ width: "100%" }} showSearch placeholder="Select Country" allowClear {...props} getPopupContainer={(trigger) => trigger.parentNode}>
      {countries.map((country) => {
        return (
          <Option key={country.country_id} value={country.country_name}>
            {country.country_name}
          </Option>
        );
      })}
    </Select>
  );
});

export default CountryFilter;
