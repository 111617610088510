import React, { useContext, useState } from "react"
import { Button, Radio } from "antd"
// import { useFormik } from "formik"
// import { useQuery } from "react-query"
// import * as yup from "yup"

// import { ResolveOrderExceptionAPI } from "../api"
import { OrderEscalationContext } from "../OrderEscalation.context"
import { ESCALATION_TYPES, steps } from "../constants"

// const { Option } = Select

const ChooseEscalationMethod = () => {
  const {
    handleCloseModal,
    setCurrentStep,
    setSelectedEscalationType
  } = useContext(OrderEscalationContext)
  const [escalationType, setEscalationType] = useState()

  const handleNext = () => {
    setSelectedEscalationType(escalationType)
    setCurrentStep(steps.confirmation)
  }

  return (
    <div>
      <h6 className="font-weight bold">Which exceptions should be escalated to the merchant</h6>
      <div className="mt-4">
        <p>
          The selected orders have multiple exception types. Please select the exception type that you are escalating to th merchant.
        </p>
        <div style={{ marginTop: 8 }}>
          <Radio.Group onChange={e => setEscalationType(e.target.value)} value={escalationType}>
            <Radio value={ESCALATION_TYPES.RTS_FIRSTMILE}>
              RTS First mile collection failures
            </Radio>
            <Radio value={ESCALATION_TYPES.LASTMILE} style={{ marginTop: 8 }}>
              Forward order Last mile delivery failures
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()}>Cancel</Button>
          <Button type="primary" onClick={handleNext} disabled={!escalationType}>Next</Button>
        </div>
      </div>
    </div>
  )
}

export default ChooseEscalationMethod