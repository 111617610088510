import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { routes } from "./routes/routes";
import "./config/axiosConfig";

// import registerServiceWorker from './registerServiceWorker';
if (process.env.REACT_APP_IS_STAGING) {
  Sentry.init({ dsn: "https://8b7b2c6540d14ba1b2066e9c4c5b7c89@sentry.io/1532259" });
}

ReactDOM.render(routes, document.querySelector("#root"));
// registerServiceWorker();
