import React from "react"
import { Select } from "antd"
import { useQuery } from "react-query"
import { OrderEscalationAPI } from "../api"
import { escalationStatuses } from "../constants"
import { capitalizeUnderscore } from "../../../../utils"

const { Option } = Select

export default function EscaltionStatusOptions({ onChange, value, ...rest }) {
  const { data, isLoading } = useQuery(
    "escalation-statuses",
    OrderEscalationAPI.getEscalationStatuses,
    {
      staleTime: Infinity,
      refetchInterval: false
    }
  )

  return (
    <Select
      mode="multiple"
      style={{ width: 400 }}
      onChange={onChange}
      value={value}
      disabled={isLoading}
      loading={isLoading}
      {...rest}
    >
      {!isLoading && !!data
        ? data.map(status => {
          const statusLabel = escalationStatuses?.[status]?.title || capitalizeUnderscore(status)
          return (
            <Option key={status} value={status}>{statusLabel}</Option>
          )
          })
        : []
      }
    </Select>
  )
}
