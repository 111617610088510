import { useState } from 'react'
import axios from 'axios'
import { useQuery } from "react-query"

const URL = '/api/tracker/private-statuses/'

const usePrivateStatuses = () => {
  const [privateStatuses, setPrivateStatuses] = useState([])
  const token = localStorage.getItem('token')

  const {isLoading} = useQuery(
    "private-statuses",
    async () => {
      const r = await axios.get(URL, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      return r.data
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      onSuccess: (data) => {
        setPrivateStatuses(data)
      },
      onError: (err) => {
        console.log(err)
      }
    }
  )

  return [privateStatuses, isLoading]
}

export default usePrivateStatuses