import {
  ADD_AGENT_APPLICATIONS,
  CLEAR_AGENT_APPLICATIONS_ERRORS,
  CLEAR_PAGINATED_AGENT_APPLICATION_DATA,
  EDIT_AGENT_APPLICATIONS,
  EDIT_AGENT_APPLICATIONS_SECRET_KEY,
  EDIT_PAGINATED_AGENT_APPLICATION_ERROR,
  FETCH_AGENT_APPLICATIONS,
  FETCH_AGENT_APPLICATION_BY_ID,
  FETCH_PAGINATED_AGENT_APPLICATIONS,
  FETCH_PAGINATED_AGENT_APPLICATION_BY_ID,
  ADD_SUB_ACCOUNTS_AGENT_APPLICATION,
  ADD_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR,
  CLEAR_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR,
} from "../../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_AGENT_APPLICATIONS:
      return { ...state, error: action.payload };
    case EDIT_AGENT_APPLICATIONS_SECRET_KEY:
      return { ...state, editSecreyKeyError: action.payload };
    case EDIT_AGENT_APPLICATIONS:
      return { ...state, error: action.payload };
    case FETCH_AGENT_APPLICATIONS:
      return { ...state, agentApplications: action.payload };
    case FETCH_AGENT_APPLICATION_BY_ID:
      return { ...state, agentApplication: action.payload };
    case CLEAR_AGENT_APPLICATIONS_ERRORS:
      return { ...state, error: null };
    case FETCH_PAGINATED_AGENT_APPLICATIONS:
      return { ...state, paginatedAgentApps: action.payload };
    case FETCH_PAGINATED_AGENT_APPLICATION_BY_ID:
      return { ...state, paginatedAgentApp: action.payload };
    case EDIT_PAGINATED_AGENT_APPLICATION_ERROR:
      return { ...state, error: action.payload.error };
    case CLEAR_PAGINATED_AGENT_APPLICATION_DATA:
      return { ...state, paginatedAgentApp: null };
    case ADD_SUB_ACCOUNTS_AGENT_APPLICATION:
      return { ...state, subAccountMessage: action.payload, subAccountError: false };
    case ADD_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR:
      return { ...state, subAccountError: action.payload, subAccountMessage: false };
    case CLEAR_SUB_ACCOUNTS_AGENT_APPLICATION_ERROR:
      return { ...state, subAccountError: action.payload, subAccountMessage: false };
    default:
      return state;
  }
}
