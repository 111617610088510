import React, { useContext } from "react"
import { OrderEscalationContext } from "../OrderEscalation.context"
import { Button } from "antd"

const EscalationSuccess = () => {
  const {
    handleCloseModal,
    submitResponse,
    handleEscalateSuccess
  } = useContext(OrderEscalationContext)

  const handleOK = () => {
    handleCloseModal()
    if (!!submitResponse?.escalated_orders && handleEscalateSuccess) {
      setTimeout(() => {
        handleEscalateSuccess()
      }, 550)
    }
  }
  const numberOrders = (submitResponse?.escalated_orders || 0) === 1
    ? "1 order"
    : `${submitResponse?.escalated_orders} orders`

  return (
    <div>
      <h6>Escalation to merchant successful</h6>
      <div className="mt-4">
        <p>The escalation to merhcant for {numberOrders} has been made successfully.</p>
      </div>

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" type="primary" onClick={handleOK}>OK</Button>
        </div>
      </div>
    </div>
  )
}

export default EscalationSuccess
