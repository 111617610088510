import {
  GET_NOTES_HEADER,
  ADD_NOTES,
  FETCH_NOTES_BY_FILTER,
  FETCH_NOTE_BY_TRACKING_NUMBER,
  FETCH_NOTES_REPORT,
  DELETE_NOTES,
  CLEAR_NOTES_ERRORS
} from '../../actions/types';
import moment from 'moment';

export default function(state = {}, action) {
  switch (action.type) {
    case GET_NOTES_HEADER:
      return {
        ...state,
        notesHeader: action.payload,
      };
    case ADD_NOTES:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        lastUpdated: moment()
      };
    case FETCH_NOTES_BY_FILTER:
      return {
        ...state,
        notes: action.payload.results,
        totalNotes: action.payload.count,
        totalPages: Math.ceil(action.payload.count / 50),
        lastUpdated: moment()
      };
    case FETCH_NOTE_BY_TRACKING_NUMBER:
      return {
        ...state,
        noteDetails: action.payload,
      };
    case FETCH_NOTES_REPORT:
      return {
        ...state,
        notesReport: action.payload,
        emailsMessage: action.emailsMessage,
        lastUpdated: moment()
      };
    case DELETE_NOTES:
      return {
        ...state,
        error: action.payload,
        message: 'Delete notes success!'
      };
    case CLEAR_NOTES_ERRORS:
      return { ...state, error: null, errorData: null, message: null, notesReport: [], emailsMessage: null };
    default:
      return state;
  }
}
