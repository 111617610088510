export const createObjPath = (path, value, root) => {
  var segments = path.split('.'),
    cursor = root || window,
    segment,
    i;

  for (i = 0; i < segments.length - 1; ++i) {
    segment = segments[i];
    cursor = cursor[segment] = cursor[segment] || {};
  }

  return cursor[segments[i]] = value;
}

export const getObjectValueByPath = (obj, path) => {
  var paths = path.split('.')
    , current = obj
    , i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return 0;
    } else {
      current = current[paths[i]];
    }
  }
  return current
}