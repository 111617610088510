import React, { useState } from 'react'
import ConfirmationModal from './ConfirmationModal'

const DEAFAULT_OPTIONS = {
  isRequired: true,
  onConfirm: text => text,
  afterClose: null,

  title: "Leave a note",
  okText: "Proceed",
  okTextLoading: "Processing...",
  cancelText: "Cancel"
}

export const ConfirmContext = React.createContext()
const ConfirmContextProvider = props => {
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState(DEAFAULT_OPTIONS)

  const confirmReason = options => {
    setOptions({...DEAFAULT_OPTIONS, ...options})
    setVisible(true)
  }

  return (
    <React.Fragment>
      <ConfirmContext.Provider
        value={{
          confirmReason
        }}
        >
          {props.children}
      </ConfirmContext.Provider>
      <ConfirmationModal
        options={options}
        visible={visible}
        setVisible={setVisible} />
    </React.Fragment>
  )
}

// const InputPromt = ({ isLoading, isRequired, inputRef }) => {
//   const [text, setText] = useState("")
//   return (
//     <Input.TextArea
//       disabled={isLoading}
//       placeholder="Leave a note so that others will have context on the change you are making now..."
//       onChange={e => {
//         setText(e.target.value)
//         inputRef.current = e.target.value
//       }}
//       value={text}
//       required={isRequired} />
//   )
// }


export default ConfirmContextProvider
