import React, { useContext } from "react"
import OrderEscalationContextProvider, { OrderEscalationContext } from './OrderEscalation.context'
import { componentStep } from "./constants"
import { useQuery } from "react-query"
import { OrderEscalationAPI } from "./api"

const OrderEscalation = ({ orderData, handleCloseModal, singleException, handleEscalateSuccess }) => {
  const { data, isLoading } = useQuery(
    'escalation-types',
    OrderEscalationAPI.fetchEscalationTypes,
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
    }
  )
  if (isLoading) {
    return <h6>Loading...</h6>
  }

  return (
    <OrderEscalationContextProvider
      orderData={orderData}
      handleCloseModal={handleCloseModal}
      singleException={singleException}
      handleEscalateSuccess={handleEscalateSuccess}
      escalationTypes={data?.data || {}}>
      <EscalateStep />
    </OrderEscalationContextProvider>
  )
}

const EscalateStep = () => {
  const {
    currentStep
  } = useContext(OrderEscalationContext)
  const Component = componentStep[currentStep]
  return <Component />
}

export default OrderEscalation
