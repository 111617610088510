import React, { useState } from 'react'
import { Modal, Input } from 'antd'

function ConfirmationModal(props) {
  const [isLoading, setLoading] = useState(false)
  const {
    visible, setVisible,
    options
  } = props
  const {
    isRequired,
    okText,
    okTextLoading,
    cancelText,
    title,
    onConfirm,
    afterClose
  } = options

  const [text, setText] = useState("")

  return (
    <Modal
        visible={visible}
        okText={isLoading ? okTextLoading : okText}
        cancelText={cancelText}
        title={title}
        afterClose={() => {
          setText("")
          if (afterClose) {
            afterClose()
          }
        }}
        onOk={async () => {
          setLoading(true)
          await onConfirm(text)
          setLoading(false)
          setVisible(false)
        }}
        onCancel={() => {
          setText("")
          setVisible(false)
        }}
        closable={!isLoading}
        maskClosable={false}
        okButtonProps={{
          disabled: (isRequired && (!text || text === '')) || isLoading
        }}
        cancelButtonProps={{
          disabled: isLoading
        }}
        >
        <div>
          <Input.TextArea
            disabled={isLoading}
            placeholder="Leave a note so that others will have context on the change you are making now..."
            onChange={e => {
              setText(e.target.value)
            }}
            value={text}
            required={isRequired} />
        </div>
      </Modal>
  )
}

export default ConfirmationModal