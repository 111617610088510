import Confirmation from "./components/Confirmation"
import LeaveNote from "./components/LeaveNote"
import Success from "./components/EscalationSuccess"
import ChooseEscalationMethod from "./components/ChooseEscalationMethod"
import UnableEscalate from "./components/UnableEscalate"

export const steps = {
  confirmation: "confirmation",
  chooseEscalationMethod: "chooseEscalationMethod",
  leaveNote: "leaveNote",
  success: "success",
  unableEscalate: 'unableEscalate'
}

export const componentStep = {
  confirmation: Confirmation,
  chooseEscalationMethod: ChooseEscalationMethod,
  leaveNote: LeaveNote,
  success: Success,
  unableEscalate: UnableEscalate
}

export const escalationStatuses = {
  LASTMILE_FIRST_MERCHANT_EMAIL_SENT: {
    value: "LASTMILE_FIRST_MERCHANT_EMAIL_SENT",
    title: "1/2 emails sent",
    bgColor: "#EABC43"
  },
  LASTMILE_SECOND_MERCHANT_EMAIL_SENT: {
    value: "LASTMILE_SECOND_MERCHANT_EMAIL_SENT",
    title: "2/2 emails sent",
    bgColor: "#EABC43"
  },
  ESCALATION_CREATED: {
    value: "ESCALATION_CREATED",
    title: "Triggered",
    bgColor: "#E5E5E5"
  },
  LASTMILE_RTV_COMPLETED: {
    value: "LASTMILE_RTV_COMPLETED",
    title: "Auto-RTV'ed",
    bgColor: "#7BD435"
  }
}

export const ESCALATION_TYPES = {
  LASTMILE: 'LASTMILE',
  RTS_FIRSTMILE: 'RTS_FIRSTMILE'
}