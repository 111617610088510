import { useState } from 'react'
import axios from 'axios'
import { useQuery } from "react-query"

const URL = '/api/hms/hubs/'

const useHubs = () => {
  const [hubs, setHubs] = useState([])
  const token = localStorage.getItem('token')

  const {isLoading} = useQuery("hms-hubs",
    async () => {
      const r = await axios.get(URL, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      return r.data
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      onSuccess: (data) => {
        setHubs(data)
      },
      onError: (err) => {
        console.log(err)
      }
    }
  )

  return [hubs, isLoading]
}

export default useHubs