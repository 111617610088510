import React, { useContext } from "react"
import { Select, Button } from "antd"
import { useQuery } from "react-query"
import { ResolveOrderExceptionAPI } from "../api"
import { useFormik } from "formik"
import * as yup from "yup"
import { ResolveOrderExceptionContext } from "../ResolveOrderException.context"
import { steps } from "../constants"

const { Option } = Select

const ChooseExceptions = () => {
  const {
    handleCloseModal,
    saveData, setCurrentStep,
    orderDataExceptions
  } = useContext(ResolveOrderExceptionContext)

  const { data: exceptions, isLoading: exceptionLoading } = useQuery(
    "exceptions",
    ResolveOrderExceptionAPI.getExceptions,
    {
      staleTime: Infinity,
      refetchInterval: false
    }
  )

  const formik = useFormik({
    initialValues: {
      exceptions: []
    },
    validationSchema: yup.object().shape({
      exceptions: yup.array().of(yup.string()).required("This field is required.")
    }),
    onSubmit: async (values) => {
      saveData(values)
      setCurrentStep(steps.leaveNote)
    }
  })

  const exceptionOptions = !exceptionLoading
    ? exceptions.map(e => {
        let enabled = orderDataExceptions.includes(e) || e === "All Exceptions"
        return <Option key={e} value={e} disabled={!enabled}>{e}</Option>
      })
    : []

  return (
    <form onSubmit={formik.handleSubmit}>
      <h6>Which exception are you resolving?</h6>

      <div className="mt-4">
        <p>Only exceptions which have been selected will be resolved.</p>

        <div className="w-100">
          <Select
            mode="multiple"
            placeholder={exceptionLoading? "Loading...":"Select exceptions"}
            style={{ width: "100%" }}
            loading={exceptionLoading}
            disabled={exceptionLoading}
            value={formik.values.exceptions}
            onChange={value => {
              formik.setFieldValue("exceptions", value)
            }}>
              {exceptionOptions}
          </Select>
          {
            formik.errors.exceptions &&
            <span className="text-danger">{formik.errors.exceptions}</span>
          }
        </div>
      </div>

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <Button className="mr-2" onClick={() => handleCloseModal()} disabled={formik.isSubmitting}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting? "Processing...":"Proceed"}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ChooseExceptions