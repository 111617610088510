import axios from 'axios'

export const ResolveOrderExceptionAPI = {
  getReolutionTypes: async () => {
    const response = await axios.get("/api/data/resolution-types/")
    return response.data
  },
  getExceptions: async () => {
    const response = await axios.get("/api/data/exceptions/")
    return response.data
  },
  disposeException: async ({
    order_ids, reason
  }) => {
    const url = "/api/order/dispose-exceptions/"
    const data = {
      order_ids: order_ids,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
  rtvException: async ({
    order_ids, reason
  }) => {
    const url = "/api/order/rtv-exceptions/"
    const data = {
      order_ids: order_ids,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
  resolveException: async ({
    order_ids, exceptions, reason,
  }) => {
    const url = "/api/order/resolve-exceptions/"
    const data = {
      order_ids: order_ids,
      exceptions: exceptions,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
  validateResolutionType: async ({
    order_ids, resolution_type
  }) => {
    const url = "/api/order/validate-exception-resolution/"
    const requestBody = {
      resolution_type,
      order_ids
    }
    try {
      const response = await axios.post(url, requestBody)
      return response.data
    } catch (err) {
      const response = {
        isError: true,
        error: err?.response?.data
      }
      return response
    }
  },
  redeliver: async ({
    reason, redeliver_exceptions
  }) => {
    const url = "/api/order/redeliver-exceptions/"
    const data = {
      order_activity_reason: reason,
      redeliver_exceptions,
    }
    try {
      const response = await axios.post(url ,data)
      return response.data
    } catch (err) {
      const response = {
        isError: true,
        error: err?.response?.data
      }
      return response
    }
  },
  recollectNewTN: async ({ reason, recollection_exceptions }) => {
    const url = "/api/order/recollect-new-rts-exceptions/ "
    const data = {
      order_activity_reason: reason,
      recollection_exceptions,
    }
    try {
      const response = await axios.post(url ,data)
      return response.data
    } catch (err) {
      const response = {
        isError: true,
        error: err?.response?.data
      }
      return response
    }
  },
  recollectExistingTN: async ({
    order_ids, reason
  }) => {
    const url = "/api/order/recollect-existing-rts-exceptions/"
    const data = {
      order_ids: order_ids,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
  cancelRTS: async ({
    order_ids, reason
  }) => {
    const url = "/api/order/cancel-rts-exceptions/"
    const data = {
      order_ids: order_ids,
      order_activity_reason: reason,
    }
    const response = await axios.post(url, data)
    return response.data
  },
}
